import React from "react"
import { QueryClientProvider, QueryClient } from "react-query"
import AppRoutes from "./routes"
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})
function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <AppRoutes />
        </QueryClientProvider>
    )
}

export default App
