import { Col, Row, Skeleton } from "antd"
import React, { useEffect, useState } from "react"
import CategoryProductCard from "./CategoryProductCard"
import { getSearchParamsValues } from "../utilities"
import { useSearchParams } from "react-router-dom"
import { ICategoryView } from "@domain/Categories"
import { useProducts } from "@hook/query/category/product.hook"
import CheckingComponentInViewPortWrapper from "./ComponentInViewportWrapper"
import ProductApi from "@api/Product"
import { IProductListViewCard } from "@domain/Product"
import { InfinityScroll } from "@component/InfiniteScroll"

interface ProductListProps {
    blockTitle: string | React.ReactNode
    rootItem: ICategoryView
    isLastNode?: boolean
}

function ProductList(props: ProductListProps) {
    const { blockTitle, rootItem, isLastNode } = props

    const [searchParams, setSearchParams] = useSearchParams()
    const cateLevel = getSearchParamsValues(searchParams).cateLevel
    const [filter, setFilter] = useState<any>({ cateId: rootItem.code })

    const [loading, setLoading] = React.useState(false)
    const [hasMode, setHasMode] = React.useState(true)
    const [page, setPage] = React.useState(1)
    const [products, setProducts] = React.useState<IProductListViewCard[]>([])

    const fetchData = async () => {
        setLoading(true)
        const response: any = await ProductApi.getProductList({
            page: page,
            size: 10,
        })
        const data = response.data
        if (response.data.length === 0) setHasMode(false)
        else setPage(page + 1), setProducts([...products, ...data])
        setLoading(false)

    }

    useEffect(() => {
        const cateCode = getSearchParamsValues(searchParams).code
        if (cateCode) {
            setFilter({
                cateId: cateCode,
            })
        }
    }, [searchParams])

    const handleSelectCategory = () => {
        const currentP = {...getSearchParamsValues(searchParams)}
        const temp:any = {...currentP}
        if(!currentP.parent) {
            temp.parent = currentP.code
        }
        else if(decodeURIComponent(currentP.parent).split('&&').length > 1) {
            // console.log('chay vao day')
        } else {
            temp.parent = encodeURIComponent(decodeURIComponent(currentP.parent) + '&&' + decodeURIComponent(currentP.code))
        }
        temp.code = encodeURIComponent(rootItem.code);
        temp.cateLevel = rootItem.level;
        setSearchParams({
            ...temp
        })
    }

    const productQuery: any = useProducts(filter)

    return (
        <React.Fragment>
            <CheckingComponentInViewPortWrapper
                // titleBlock={rootItem.name}
                // inViewCallback={() => setFilter({ cateId: rootItem.code })}
                >
                <section className="product-list ml-5 bg-white">
                    <Skeleton
                        loading={productQuery.isLoading}
                        active
                        paragraph={{ rows: 5 }}>
                        <div className="mb-[24px] flex justify-between">
                            <span className="text-lg font-semibold">{blockTitle || "Category name"}</span>
                            <span
                                className="text-[#B1B1B1] text-sm cursor-pointer"
                                onClick={() => handleSelectCategory()}>
                                {(!cateLevel || (cateLevel && cateLevel <= 2)) && (
                                    <span>
                                        Xem tất cả <i className="fa-regular fa-angle-right"></i>
                                    </span>
                                )}
                            </span>
                        </div>
                        <Row gutter={[18, 32]}>
                            {productQuery.data &&
                                productQuery.data.length > 0 &&
                                !isLastNode &&
                                productQuery.data?.map((product: any, index: number) => {
                                    if (index <= 7)
                                        return (
                                            <Col
                                                key={product.id}
                                                xs={24}
                                                lg={6}>
                                                <CategoryProductCard item={product} />
                                            </Col>
                                        )
                                })}
                            {isLastNode &&
                                products.map((product: IProductListViewCard) => {
                                    return (
                                        <Col
                                            key={product.id}
                                            xs={24}
                                            lg={6}>
                                            <CategoryProductCard item={product} />
                                        </Col>
                                    )
                            })}
                            {
                                isLastNode && <InfinityScroll loading={loading} fetchData={fetchData} hasMode={hasMode} />
                            }
                            
                        </Row>
                    </Skeleton>
                </section>
            </CheckingComponentInViewPortWrapper>
        </React.Fragment>
    )
}

export default ProductList
