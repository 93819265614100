import React from "react"
import { Checkbox } from "antd"

export const TopInfo = ({
    totalSku,
    isCheckAll,
    onUpdateSelect,
    totalSkuSelect,
}: {
    isCheckAll: boolean
    totalSkuSelect: number
    totalSku: number
    onUpdateSelect: ({
        merchantId,
        skuId,
        productId,
        isCheck,
        isAll,
    }: {
        merchantId?: string | undefined
        skuId?: string | undefined
        productId?: string | undefined
        isCheck: boolean
        isAll?: boolean | undefined
    }) => void
}) => {
    return (
        <div className=" flex flex-col w-[1200px] bg-white mx-auto rounded px-5 py-3 gap-4 sticky top-0 z-[1000] shadow-sm">
            <div className=" flex flex-row justify-between">
                <div className=" text-primary text-lg">Tổng ({totalSku}/200)</div>
            </div>
            <div className=" flex flex-row justify-between">
                <div className=" flex flex-row items-center">
                    <Checkbox
                        indeterminate={totalSkuSelect > 0 && !isCheckAll}
                        checked={isCheckAll}
                        onClick={() => onUpdateSelect({ isAll: true, isCheck: !isCheckAll })}
                        className=" text-textPrimary text-[13px] pb-[2px]">
                        Chọn tất cả
                    </Checkbox>
                    <div className=" text-textPrimary text-[13px] ml-[10px]">Tên sản phẩm</div>
                </div>
                <div className=" flex flex-row items-center gap-2 ">
                    <div className="text-[13px] text-textPrimary flex flex-row items-center mr-[190px]">Số lượng</div>
                    <div className="text-[13px] text-textPrimary flex flex-row items-center mr-[76px] ">Đơn giá</div>
                    <div className="text-[13px] text-textPrimary flex flex-row items-center  mr-[49px]  ">Giá đã giảm</div>
                    <div className="text-[13px] text-textPrimary flex flex-row items-center  justify-end ">Tổng Giá（VNĐ)</div>
                </div>
            </div>
        </div>
    )
}
