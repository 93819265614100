import { Checkbox } from "antd"
import React from "react"
import { ItemProduct } from "./ItemProduct"
import { IMerchant } from "@domain/Cart"
export const ItemMerchant = ({ merchant, onUpdateSelect }: {
    merchant: IMerchant, onUpdateSelect: ({ merchantId, skuId, productId, isCheck, isAll }: {
        merchantId?: string | undefined;
        skuId?: string | undefined;
        productId?: string | undefined;
        isCheck: boolean;
        isAll?: boolean | undefined;
    }) => void
}) => {
    let totalSkuSelect = 0
    let totalSku = 0

    for (let index = 0; index < merchant.products.length; index++) {
        const product = merchant.products[index];
        for (let index = 0; index < product.skus.length; index++) {
            const sku = product.skus[index]
            if (sku.checked) {
                totalSkuSelect = totalSkuSelect + 1
            }
            totalSku = totalSku + 1

        }
    }

    const isCheckAll = totalSkuSelect === totalSku
    return (


        <div className=" bg-white rounded  pt-3 flex flex-col gap-3  shadow-sm">
            <div className=" flex flex-row  justify-between items-center px-5 ">
                <Checkbox className=" font-normal text-sm" indeterminate={totalSkuSelect > 0 && !isCheckAll} checked={isCheckAll} onClick={() => onUpdateSelect({ merchantId: merchant.id, isCheck: !isCheckAll })}>{merchant.supplier}</Checkbox>
            </div>
            <div className="flex flex-col gap-6 w-full">
                {merchant.products.map((e, index) => {
                    return (
                        <ItemProduct
                            end={index === merchant.products.length - 1}
                            onUpdateSelect={onUpdateSelect}
                            key={e.id}
                            product={e}
                        />
                    )
                })}
            </div>
        </div>
    )
}
