import { ICategoryView } from "@domain/Categories"
import numeral from "numeral"

export const categoryImagesFake = [
    "https://img.alicdn.com/tfs/TB1oy6wCFzqK1RjSZFoXXbfcXXa-130-130.png",
    "https://img.alicdn.com/tfs/TB1oy6wCFzqK1RjSZFoXXbfcXXa-130-130.png",
    "https://img.alicdn.com/tfs/TB1lT6uCHrpK1RjSZTEXXcWAVXa-130-130.png",
    "https://img.alicdn.com/tfs/TB1eajwCIbpK1RjSZFyXXX_qFXa-130-130.png",
    "https://img.alicdn.com/tfs/TB1rTPCCIfpK1RjSZFOXXa6nFXa-130-130.png",
    "https://img.alicdn.com/tfs/TB1oY6yCQPoK1RjSZKbXXX1IXXa-130-130.png",
    "https://img.alicdn.com/tfs/TB1eJHtCNTpK1RjSZR0XXbEwXXa-130-130.png",
    "https://img.alicdn.com/tfs/TB1oy6wCFzqK1RjSZFoXXbfcXXa-130-130.png",
    "https://img.alicdn.com/tfs/TB1lFVQG1H2gK0jSZJnXXaT1FXa-216-216.jpg",
    "https://img.alicdn.com/imgextra/i4/O1CN01sU4ZnR1YD2NfD7IoO_!!6000000003024-0-tps-240-240.jpg",
    "https://img.alicdn.com/imgextra/i3/O1CN01PsWKlD215MbRfVWvP_!!6000000006933-0-tps-240-240.jpg",
    "https://img.alicdn.com/tfs/TB1i7boCSzqK1RjSZFHXXb3CpXa-130-130.png",
    "https://gw.alicdn.com/tfs/TB1.YhwixTpK1RjSZFGXXcHqFXa-130-130.jpg",
    "https://gw.alicdn.com/tfs/TB1Nq_NCHvpK1RjSZFqXXcXUVXa-130-130.png",
    "https://img.alicdn.com/tfs/TB1S5zncVY7gK0jSZKzXXaikpXa-240-240.jpg",
    "https://img.alicdn.com/tfs/TB1RTLKh3HqK1RjSZFgXXa7JXXa-130-130.png",
    "https://img.alicdn.com/tfs/TB1PZgwMXT7gK0jSZFpXXaTkpXa-216-216.jpg",
    "https://img.alicdn.com/imgextra/i3/O1CN01hUYdSh1cBRXZCgjyU_!!6000000003562-0-tps-240-240.jpg",
]

export const getRandomNumber = (n: number) => {
    return Math.floor(Math.random() * (n + 1))
}

export const getSearchParamsValues = (searchParams: URLSearchParams) => {
    const temp: any = {}
    searchParams.forEach((value, key) => {
        // console.log(key,value)
        temp[key] = value
    })
    return temp
}

export const flattenTree = (tree: any[]) => {
    const result: any[] = []

    function flatten(node: any) {
        result.push({ ...node })

        if (node.childs) {
            for (const child of node.childs) {
                flatten(child)
            }
        }
    }

    flatten(tree)

    return result
}
export const moneyFormatterByCurrency = (value: number, currency?: string) => {
    const formatTemp = "0[.]000"
    if (currency === "CNY") {
        return [numeral(value).format(formatTemp), " ¥"]
    } else {
        return [numeral(value).format(formatTemp), " đ"]
    }
}

export const get_url_extension = (url: any) => {
    if (url) return url.split(/[#?]/)[0].split(".").pop().trim()
}

export const moveToFirst = (arr: ICategoryView[], code: string) => {
    const target = arr.find((cate) => cate.code === code)
    if (target)
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].code === code) {
                arr.splice(i, 1)
                arr.unshift(target)
                break
            }
        }
}

export const transformUniqueArr = (inputArr: any[]) => {
    const uniqueArr = []
    const seen = new Set()

    for (const item of inputArr) {
        const itemString = JSON.stringify(item)
        if (!seen.has(itemString)) {
            seen.add(itemString)
            uniqueArr.push(item)
        }
    }
    return uniqueArr
}

export const objectSortedByPropertyValueLength = (inputObject: any) => {
    const sortedObject = Object.keys(inputObject)
        .sort((a, b) => inputObject[a].length - inputObject[b].length)
        .reduce((acc: any, key) => ((acc[key] = inputObject[key]), acc), {})
    return sortedObject
}

// Hàm kiểm tra xem một đối tượng có tồn tại trong mảng hay không
export const objectExistsInArray = (objInput:any, arr:any) => {
    for (const obj of arr) {
        if (arraysEqual(obj.variantProperties, objInput.variants)) {
            return true;
        }
    }
    return false;
}

// Hàm so sánh hai mảng thuộc tính variantProperties
export const arraysEqual = (arr1:any, arr2:any) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
            return false;
        }
    }
    return true;
}

export const groupObjectsByFirstVariant = (objects: any) => {
    const groupedObjects:any = {};
  
    objects.forEach((object:any) => {
      const firstVariant = object.variants[0];
      const variantKey = firstVariant.value; // Sử dụng giá trị của phần tử đầu tiên làm key
  
      if (!groupedObjects[variantKey]) {
        groupedObjects[variantKey] = [];
      }
  
      groupedObjects[variantKey].push(object);
    });
  
    return groupedObjects;
  }
  

  export const findSalePriceByQuantity = (data:any, quantity: number) =>  {
    for (const item of data) {
      const { minQuantity, maxQuantity, salePrice } = item;
      
      if ((minQuantity === null || quantity >= minQuantity) &&
          (maxQuantity === null || quantity <= maxQuantity)) {
        return salePrice;
      } 
    }
    return null;
  }

//   export const loginByNewWindowTab = (url: string) => {
 // let checkConnect: any = undefined;
            // let windowReferrer:any
            // const openConnectTab = (url: any) => {
            //     const windowFeatures = "width=600,height=450,scrollbars=yes,location=no";
            //     windowReferrer = window.open(url, '_blank', windowFeatures);
            //     return windowReferrer;
            // }
            // if(loginBaseUrl) {
            //     const url = loginBaseUrl + '?redirect_uri=' + window.location.origin + '/authentication';
            //     openConnectTab(url);
            //     checkConnect = setInterval(() => {
            //         if (!windowReferrer.closed || !windowReferrer) return;
            //         clearInterval(checkConnect);
            //         window.location.reload();
            //     }, 500);
            // }
//   }
