import { Any } from "@react-spring/web"
import apiClient from "@util/ApiClient"

export default class RegisterApi {
    // static getTimezones(): Promise<{ data: ITimezone[] }> {
    //     return apiClient.get(`time-zones`)
    // }

    // static getAuthUserInfo(): Promise<{ data: IUserInfo }> {
    //     return apiClient.get(`auth/user/info`)
    // }

    // static updateUserInfo(body: { zoneInfo: string }): Promise<any> {
    //     return apiClient.patch(`users`, body)
    // }
    static register(data: any): Promise<{ data: Any }> {
        return apiClient.post(`staffs/register`, data)
    }

}
