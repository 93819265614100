import { IProduct } from "@domain/Product";
import { Button, Col, Input, Row } from "antd";
import lodash from "lodash";
import React, { useRef, useState } from "react";

interface SelectQuantityComponentProps {
    product?: IProduct | undefined
    sku: any
    onChange?: (value?: any) => void
    value?: any
}

function SelectQuantityComponent(props: SelectQuantityComponentProps) {
    const {  onChange, sku,value } = props;
    const [, setErrorQuantity] = useState<string>();
    // const [quantity, setQuantity] = useState<number>(sku?.quantity);
    //@ts-ignore
    const refInputQuantity = useRef<Input>(null);
     const btnClassName = `_btn-update-quantity`
    

    const onUpdateQuantifyByBatchSize = (plus: boolean) => {
        let quantity = lodash.cloneDeep(sku?.quantity);
        if (plus) {
            if(quantity + parseInt(sku?.batchSize) > sku.stock) {
                return
            } else {
                quantity = quantity + parseInt(sku?.batchSize);
            }
        } 
        else{
            quantity = +quantity - sku.batchSize;
            // console.log(quantity)
            if(quantity<=0) quantity=0;
        }
        onChange && onChange(quantity)
    }

    const onChangeQuantityBlur = (e: any) => {
        if(e.target.value > sku.stock) {
            onChange && onChange(sku.stock)
        }
    }

    const onChangeQuantity = (raw: any) => {
        let value = raw.replace(/[^0-9]/g, '');
        if(value > 0 || value) {
            value = parseInt(value)
        } else value = 0
        // if(+value < 1 || +value > sku?.stock) return
        // setQuantity(value);
        checkQuantityError(value);
        onChange && onChange(value)
    }

    const checkQuantityError = (value:any) => {
        // eslint-disable-next-line prefer-const
        let temp = parseInt(value);
        if (!value || (sku?.minQuantity && value < sku?.minQuantity)) setErrorQuantity(``);
        else if (sku?.stock && temp > sku?.stock) setErrorQuantity(``);
        else if (sku?.batchSize && temp % sku?.batchSize !== 0) setErrorQuantity(``);
        // else if (sku?.limitQuantity && temp > sku?.limitQuantity) setErrorQuantity(``);
        else setErrorQuantity('');
    }

    return (
        <React.Fragment>
            <Row className={"flex w-full sku-input-number"}>
                <Button
                    ghost
                    className={btnClassName}
                    onClick={() => onUpdateQuantifyByBatchSize(false)}>
                        <span className={"txt-color-gray2"}>
                            <i className="fas fa-minus text-[12px]"></i>
                        </span>
                    </Button>
                <Col span={12}>
                    <Input
                        ref={refInputQuantity}
                        size="large"
                        style={{ textAlign: "center" }}
                        className={"txt-color-gray2"}
                        value={value}
                        onChange={(e) => onChangeQuantity(e.target.value)}
                        onBlur={(e) => onChangeQuantityBlur(e)}
                        onKeyDown={(e) => {
                            // detect ENTER
                            if (e.keyCode === 13 && refInputQuantity && refInputQuantity.current) {
                                refInputQuantity.current.blur();
                            }
                        }}
                    />
                </Col>
                <Button
                    ghost
                    className={btnClassName}
                    onClick={() => onUpdateQuantifyByBatchSize(true)}>
                        <span className={"txt-color-gray2"}>
                            <i className="fas fa-plus text-[12px]"></i>
                        </span>
                    </Button>
            </Row>
        </React.Fragment>
    )
}

export default SelectQuantityComponent
