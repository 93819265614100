import CartApi from "@api/Cart"
import ProductApi from "@api/Product"
import appConfig from "@config/app"
import { IProduct } from "@domain/Product"
import { findSalePriceByQuantity, groupObjectsByFirstVariant, objectSortedByPropertyValueLength, transformUniqueArr } from "@screen/CategoriesPage/utilities"
import { numberFormatter } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Badge, Button, Image, Modal, notification } from "antd"
import lodash from "lodash"
import React, { useEffect, useMemo, useRef, useState } from "react"
import ActionButton from "./ActionButton"
import SelectQuantityComponent from "./SelectQuantityComponent"

import AddCartSuccess from "../../../resources/images/imgs/amico.png"
import AstronaultCat from "../../../resources/images/imgs/cuate.png"

interface VariantsBlockProps {
    product: IProduct | undefined
}

function VariantsBlock(props: VariantsBlockProps) {
    const { product } = props
    const [numberOfLastVariantToDisplay, setnumberOfLastVariantToDisplay] = useState<number | any>(3)
    const [selectedSkus, setSelectedSkus] = useState<any[]>([])
    const [variantsObject, setVariantsObject] = useState<any>()
    const [skuForShow, setSkuForShow] = useState<any>()
    const [skusHasQuantity, setSkuHasQuantity] = useState<any>([])
    const [addToCartLoading, setAddToCartLoading] = useState(false)
    const [openAddSuccessModal, setOpenAddSuccessModal] = useState(false)
    const [totalSkuInCartPreview, setTotalSkuInCartPreview] = useState<any>()
    const [openModalError,setOpenModalError] = useState(false)

    const userLogged = SecurityService.isLogged()
    const variantBlockRef = useRef<any>()

    useEffect(() => {
        if (localStorage.getItem("urlRedirectLogin")) {
            localStorage.removeItem("urlRedirectLogin")
        }
    }, [])

    useEffect(() => {
        const arrays: any = []
        if (product && product.skus)
            product.skus.forEach((sku) => {
                if (sku.variantProperties)
                    sku.variantProperties.forEach((variant) => {
                        arrays.push(variant)
                    })
            })
        const groupedData: any = {}
        arrays.forEach((item: any) => {
            const { name } = item
            if (!groupedData[name]) {
                groupedData[name] = []
            }
            groupedData[name].push(item)
        })
        const transformedObject: any = {}
        for (const key in groupedData) {
            const valuesArray = groupedData[key]
            transformedObject[key] = transformUniqueArr(valuesArray)
        }
        const sortedObject = objectSortedByPropertyValueLength(transformedObject)

        setVariantsObject(sortedObject)
    }, [product])

    useEffect(() => {
        if (variantsObject && Object.keys(variantsObject).length > 0) {
            let tempSkuList: any = []
            const tempSku: any = {
                oid: undefined,
                quantity: 0,
                variants: [],
            }
            Object.keys(variantsObject).forEach((variantKey, index) => {
                tempSku.variants[index] = variantsObject[variantKey][0]
                tempSkuList.push(tempSku)
            })
            tempSkuList = tempSkuList.slice(0, 1)
            setSelectedSkus(tempSkuList)
        }
    }, [variantsObject])

    useEffect(() => {
        let lastVariant
        for (const key in variantsObject) {
            // eslint-disable-next-line no-prototype-builtins
            if (variantsObject.hasOwnProperty(key)) {
                lastVariant = variantsObject[key]
            }
        }
        let newTempSKUs: any = []
        lastVariant?.map((element: any) => {
            const newTempSku = {
                ...selectedSkus[0],
                variants: [...selectedSkus[0].variants],
            }
            newTempSku.variants.splice(newTempSku.variants.length - 1, 1, element)
            // console.log('ew',newTempSku)
            newTempSKUs.push(newTempSku)
        })

        if (product && product.skus) {
            const presentedSku = [...product.skus]

            const isObjectInArray = (objectD: any, objectsArray: any) => {
                return objectsArray.some((object: any) => {
                    return object.variantProperties.every((variant: any) => {
                        return objectD.variants.some((dVariant: any) => {
                            return (
                                variant.name.replace(/\s+/g, "") === dVariant.name.replace(/\s+/g, "") &&
                                variant.value.replace(/\s+/g, "") === dVariant.value.replace(/\s+/g, "")
                            )
                        })
                    })
                })
            }

            const findObjectInArray = (objectD: any, objectsArray: any) => {
                for (const object of objectsArray) {
                    if (
                        object.variantProperties.every((variant: any) => {
                            return objectD.variants.some((dVariant: any) => {
                                return (
                                    variant.name.replace(/\s+/g, "") === dVariant.name.replace(/\s+/g, "") &&
                                    variant.value.replace(/\s+/g, "") === dVariant.value.replace(/\s+/g, "")
                                )
                            })
                        })
                    ) {
                        return object
                    }
                }
                return null
            }

            newTempSKUs = newTempSKUs.map((displaySku: any) => {
                if (isObjectInArray(displaySku, presentedSku)) {
                    const existedSku = findObjectInArray(displaySku, presentedSku)
                    return {
                        ...displaySku,
                        oid: existedSku?.oid,
                        stock: existedSku?.stock,
                        batchSize: existedSku?.batchSize,
                        quantity: mappingQuantity(existedSku?.oid) || 0,
                        salePrice: existedSku?.salePrice,
                    }
                } else {
                    return { ...displaySku }
                }
            })
        }
        setSkuForShow(newTempSKUs)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSkus, product, skusHasQuantity])

    const showOtherPropertiesValue = useMemo(() => {
        const pricePolicies = product?.pricePolicies
        if (pricePolicies) {
            let totalQuantity = 0
            let totalPaid = 0
            skusHasQuantity.forEach((ss: any) => {
                totalQuantity += ss.quantity
            })
            const salePriceDisplayed = findSalePriceByQuantity(pricePolicies, totalQuantity) || pricePolicies[0].salePrice
            if (salePriceDisplayed !== null) {
                totalPaid = totalQuantity * salePriceDisplayed
            }
            console.log(salePriceDisplayed, totalQuantity, totalPaid)
            return {
                salePriceDisplayed,
                totalQuantity,
                totalPaid,
            }
        }
    }, [skusHasQuantity, product])

    const handleChangeSkuQuantity = (value: number, vIndex: number) => {
        const temp1 = [...skuForShow]
        let currentSkusHasQuantity = [...skusHasQuantity]
        // if (value > temp1[vIndex].stock) return

        const sc = {
            ...temp1[vIndex],
            quantity: value,
        }

        const idxToSplice = lodash.findIndex(currentSkusHasQuantity, (o) => o.oid === sc.oid)
        if (idxToSplice !== -1) {
            currentSkusHasQuantity.splice(idxToSplice, 1, sc)
        } else {
            currentSkusHasQuantity.push(sc)
        }
        currentSkusHasQuantity = currentSkusHasQuantity.filter((sku)=> sku.quantity > 0)
        setSkuHasQuantity(currentSkusHasQuantity)
    }

    const handleRenderProductVariants = () => {
        if (variantsObject)
            return (
                <div className="col-span-3 flex flex-col gap-3">
                    {Object.keys(variantsObject).map((key, indexP, arrayP) => {
                        // console.log(groupedData[key])
                        return (
                            <div
                                key={key}
                                className="grid grid-cols-9 gap-1 items-start mb-[24px]">
                                <span className="text-[#B1B1B1] col-span-1 py-2 capitalize">{key}</span>
                                <span className={`col-span-8 grid grid-cols-4 ${indexP < arrayP.length - 1 ? "gap-3" : ""}`}>
                                    {variantsObject[key].map((variant: any, vIndex: number, variantArr: any) => {
                                        if (indexP < arrayP.length - 1) {
                                            return (
                                                <div
                                                    className="text-center flex w-ful "
                                                    onClick={() => {
                                                        handleSelectVariant(variant, indexP)
                                                    }}
                                                    key={variant.name + variant.value}>
                                                    <Badge
                                                        overflowCount={9999}
                                                        count={handleRenderTotalCount(variant.value)}
                                                        className={`col-span-2 variant-item py-3 w-full ${
                                                            selectedSkus.length > 0 &&
                                                            selectedSkus[0].variants &&
                                                            selectedSkus[0].variants.length > 0 &&
                                                            selectedSkus[0].variants.find((v: any) => compileToUniqueId(v) === compileToUniqueId(variant))
                                                                ? "variant-item_selected"
                                                                : ""
                                                        } `}>
                                                        <span className="white-space-wrap w-full capitalize">{variant.value}</span>
                                                    </Badge>
                                                </div>
                                            )
                                        } else {
                                            if (vIndex < numberOfLastVariantToDisplay)
                                                return (
                                                    <div
                                                        className={`col-span-8 grid grid-cols-4 items-center ${
                                                            skuForShow && skuForShow[vIndex]?.stock ? "" : "variant-item_disable-masked"
                                                        } ${vIndex < variantArr.length - 1 ? "pb-[32px]" : ""}`}
                                                        key={variant.name + variant.value}>
                                                        <span className="col-span-1">{variant.value}</span>
                                                        <span className="text-right px-2">
                                                            {showOtherPropertiesValue?.salePriceDisplayed
                                                                ? numberFormatter(showOtherPropertiesValue?.salePriceDisplayed).replace(/,/g, ".") + "đ"
                                                                : ""}
                                                            {/* {skuForShow[vIndex]?.salePrice ? numberFormatter(skuForShow[vIndex]?.salePrice) + "đ" : ""} */}
                                                        </span>
                                                        <span className="col-span-1 text-start text-[#B1B1B1] px-2">
                                                            {skuForShow && skuForShow[vIndex]?.stock
                                                                ? numberFormatter(skuForShow[vIndex]?.stock).replace(/,/g, ".") + " có sẵn"
                                                                : "Không có sẵn"}
                                                        </span>
                                                        <span className="col-span-1 flex">
                                                            <SelectQuantityComponent
                                                                value={skuForShow[vIndex]?.quantity}
                                                                sku={skuForShow && skuForShow[vIndex]}
                                                                onChange={(value) => handleChangeSkuQuantity(value, vIndex)}
                                                            />
                                                        </span>
                                                    </div>
                                                )
                                        }
                                    })}
                                </span>
                            </div>
                        )
                    })}
                </div>
            )
    }

    const handleRenderTotalCount = (firstVariantValue: string) => {
        const temp = groupObjectsByFirstVariant(skusHasQuantity)
        let totalCount = 0
        temp[firstVariantValue]?.forEach((t: any) => {
            totalCount += t.quantity
        })
        return totalCount
    }

    const handleSelectVariant = (inputVariant: any, indexVariant: number) => {
        const ssTemp = [...selectedSkus]
        if (ssTemp && ssTemp.length > 0) {
            ssTemp.forEach((sku: any) => {
                const skuTemp = {
                    ...sku,
                }
                if (skuTemp.variants && Array.isArray(skuTemp.variants)) {
                    skuTemp.variants.forEach((vr: any, idx: number) => {
                        if (idx === indexVariant)
                            if (
                                vr.name === inputVariant.name
                                // vr.name + vr.value === inputVariant.name + inputVariant.value
                            ) {
                                // console.log("đã chọn thuộc tính này rồi, khác giá trị thôi")
                                skuTemp.variants.splice(indexVariant, 1, inputVariant)
                            } else {
                                // console.log("chưa có thuộc tính này, để thêm vào")
                                skuTemp.variants.splice(indexVariant, 0, inputVariant)
                                ssTemp.push(skuTemp)
                            }
                        else {
                            // console.log("variant index !== idx", idx, indexVariant)
                            skuTemp.variants.splice(indexVariant, 1, inputVariant)
                        }
                    })
                } else {
                    console.log("chưa có thuộc tính nào cả")
                    skuTemp.variant.splice(indexVariant, 0, {
                        ...inputVariant,
                    })
                }
                // ssTemp.push(skuTemp)
            })
        } else {
            ssTemp.push({
                variants: [{ ...inputVariant }],
                quantity: 0,
                oid: undefined,
            })
        }
        // console.log("ssTemp after", ssTemp)
        setSelectedSkus(ssTemp)
    }

    const mappingQuantity = (oid: string) => {
        const alreadyQuantity = skusHasQuantity.find((u: any) => u.oid === oid)
        if (alreadyQuantity) return alreadyQuantity.quantity
    }

    const handleAddToCart = () => {
        if (!userLogged) {
            localStorage.setItem("urlRedirectLogin", window.location.pathname)
            setTimeout(() => {
                window.location.href = appConfig.loginUrl
                    ? appConfig.loginUrl.split("?")[0] + "?redirect_uri=" + window.location.origin + "/authentication"
                    : ""
            }, 500)
            return
        }
        setAddToCartLoading(true)
        const payload = {
            itemId: product?.id,
            skus: [],
        }
        if (skusHasQuantity) {
            payload.skus = skusHasQuantity.map((s: any) => {
                return {
                    skuId: s.oid,
                    quantity: s.quantity,
                }
            })
        }
        ProductApi.adđToCart(payload)
            .then(() => {
                setSkuHasQuantity([])
            })
            .then(() => {
                CartApi.getMerchants().then((res) => {
                    console.log(res)
                    const total = res.data[0].totalSku
                    setTotalSkuInCartPreview(total)
                })
                setOpenAddSuccessModal(true)
            })
            .catch((error: any) => {
                if (error.response)
                    if (error.response.data.title === "invalid_quantity_of_product") {
                        notification["error"]({
                            message: "Số lượng mua chưa tối thiểu là" + error.response?.data?.detail?.split(/'/)[1],
                        })
                    }
                    else if(error.response.data.title === "too_manny_sku_in_cart") {
                        setOpenModalError(true)
                    }
                else notification["error"]({
                    message: error.response?.data?.title,
                    description: error.response?.data?.detail,
                })
            })
            .finally(() => setAddToCartLoading(false))
    }

    // console.log(variantBlockRef)

    return (
        <React.Fragment>
            <section>
                <div
                    className="variant px-4 mt-5"
                    ref={variantBlockRef}>
                    {handleRenderProductVariants()}
                </div>

                <div className="skus px-4 h-auto">
                    {product?.skus && product.skus.length > 3 && (
                        <div className="flex w-full">
                            <span className="border-t w-5/6"></span>
                            <span
                                className="px-2 pb-1 border-l border-b border-r w-1/6 flex justify-center text-[#B1B1B1] cursor-pointer"
                                onClick={() => {
                                    if (numberOfLastVariantToDisplay <= 3) {
                                        setnumberOfLastVariantToDisplay(product?.skus?.length)
                                        if (variantBlockRef.current && variantBlockRef.current.lastChild) {
                                            const lastChild = variantBlockRef.current.lastChild
                                            lastChild.scrollIntoView({ behavior: "smooth" })
                                        }
                                    } else setnumberOfLastVariantToDisplay(3)
                                }}>
                                {numberOfLastVariantToDisplay <= 3 ? "Xem thêm" : "Thu gọn"}{" "}
                            </span>
                            <span className="border-t w-1/6"></span>
                        </div>
                    )}
                </div>

                {showOtherPropertiesValue?.totalQuantity && showOtherPropertiesValue?.totalQuantity > 0 ? (
                    <div className="total-count px-4 h-auto mt-3">
                        <div className="">
                            <span className="text-[#B1B1B1] ">Tổng cộng {showOtherPropertiesValue?.totalQuantity} sản phẩm</span>
                            {
                                <span className="text-[#B1B1B1] ml-3">
                                    Thành tiền:{" "}
                                    <span className="text-[#F9471B] text-[24px] ml-2">
                                        {numberFormatter(showOtherPropertiesValue?.totalPaid).replace(/,/g, ".")}đ
                                    </span>
                                </span>
                            }
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <ActionButton
                    addToCartLoading={addToCartLoading}
                    handleAddToCart={handleAddToCart}
                    showOtherPropertiesValue={showOtherPropertiesValue}
                    numberOfLastVariantToDisplay={numberOfLastVariantToDisplay}
                />
            </section>

            <Modal
                open={openAddSuccessModal}
                title={null}
                footer={null}
                onCancel={() => setOpenAddSuccessModal(false)}
                destroyOnClose
                centered
                maskClosable
                closable={false}
                className="_add-cart-success-modal rounded-lg">
                <div className="flex  flex-col items-center justify-center gap-4 mb-4">
                    <div>
                        <Image
                            src={AddCartSuccess}
                            preview={false}
                        />
                    </div>
                    <div className="flex justify-center flex-col items-center">
                        <div className="text-[#F9471B] font-semmibold text-[24px] mb-3">Thêm sản phẩm vào giỏ hàng thành công!</div>
                        <div className="text-[#7D7E7E] text-[16px]">
                            Tổng số loại sản phẩm trong giỏ hiện tại đang là <span className="text-[#F9471B]">{totalSkuInCartPreview || 0}</span>
                        </div>
                    </div>
                    <div className="flex gap-3 modal_succes_buttons">
                        <Button
                            size="large"
                            type="primary"
                            onClick={() => setOpenAddSuccessModal(false)}>
                            Tiếp tục mua hàng
                        </Button>

                        <Button
                            type="default"
                            size="large">
                            <a href={"/cart"}>Xem giỏ hàng</a>
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={openModalError}
                title={null}
                // closable={false}
                footer={null}
                maskClosable={true}
                onCancel={()=> {setOpenModalError(false)}}
                centered
            >
                <div className="flex flex-col items-center justify-center px-[40px]">
                    <img src={AstronaultCat} style={{width: 200, height: 190}}/>
                    <span className="text-[24px] font-medium text-[#F9471B] mt-[32px] mb-[26px]">
                        Giỏ hàng đã đầy
                    </span>
                    <span className="text-[16px] text-[#7D7E7E] text-center">
                        Xin lỗi, giỏ hàng của bạn đã đạt đến giới hạn tối đa của 200 sản phẩm. Bạn cần xem xét và chỉnh sửa giỏ hàng của mình trước khi tiếp
                        tục.
                    </span>

                    <Button type="primary" className="mt-[40px] bg-[#F9471B]" onClick={()=> setOpenModalError(false)}>
                        Xác nhận
                    </Button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default VariantsBlock

const compileToUniqueId = (inputObj: any) => {
    if (inputObj) return (inputObj.name + inputObj.value).replace(/\s/g, "")
}
