import { RightOutlined } from "@ant-design/icons"
import { ICategory } from "@domain/Home"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Subject } from "rxjs"
export const subMenu = new Subject()
export const timeout = { time: 0 } as any
interface ItemMenuProps {
    category: ICategory
}

export const ItemMenu = ({ category }: ItemMenuProps) => {
    const [isHovered, setIsHovered] = useState(false)
    const handleMouseEnter = () => {
        clearTimeout(timeout.time)
        subMenu.next({ category })
    }
    const navigate = useNavigate()

    useEffect(() => {
        const c = category
        const sub = subMenu.subscribe(({ category }: any) => {
            setIsHovered(category?.id == c?.id)
        })

        return () => {
            sub.unsubscribe()
        }
    }, [category])
    const handleMouseLeave = () => {
        timeout.time = setTimeout(() => {
            subMenu.next({})
            setIsHovered(false)
        }, 100)
    }

    const handleClickMenuItem = (code: string) => {
        const temp = encodeURIComponent(code)
        navigate(`categories?code=${temp}&cateLevel=1`)
    }

    return (
        <div
            id={category.id}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="flex flex-row items-center gap-3 relative cursor-pointer"
            onClick={() => handleClickMenuItem(category.code)}>
            <img
                className="w-4 h-4 object-contain"
                alt=""
                src={
                    !isHovered
                        ? require(`src/resources/images/home/icon_menu/UP_${category.code.toUpperCase()}.png`)
                        : require(`src/resources/images/home/icon_menu/UP_${category.code.toUpperCase()}_ACTIVE.png`)
                }
            />
            <p className={classNames("text-sm  flex-1  line-clamp-1 my-auto", isHovered ? "text-primary font-normal" : "text-textPrimary font-light")}>
                {category.name}
            </p>
            <RightOutlined
                style={{ fontSize: "15px", color: "#F9471B" }}
                className=" hover:text-primary"
                rev={undefined}
            />
        </div>
    )
}
