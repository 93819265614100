import appConfig from "@config/app"
import { IImage, IUserInfo } from "@domain/Common"
import apiClient from "@util/ApiClient"

export default class CommonApi {
    // static getTimezones(): Promise<{ data: ITimezone[] }> {
    //     return apiClient.get(`time-zones`)
    // }

    static getAuthUserInfo(): Promise<{ data: IUserInfo }> {
        return apiClient.get(appConfig.apiUrlDota + `profile`)
    }

    static updateUserInfo(body: { zoneInfo: string }): Promise<any> {
        return apiClient.patch(`users`, body)
    }
    static uploadImage(formData: any): Promise<{ data: IImage[] }> {
        return apiClient.post(`upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    static getM1Token () {
        return apiClient.get(`/access-token`)
    }

}
