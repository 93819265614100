import React from "react"
import { ItemProduct } from "./ItemProduct"
import { IMerchant } from "@domain/Cart"
import numeral from "numeral"
import { CaretDownOutlined } from "@ant-design/icons"
export const ItemMerchant = ({ merchant }: { merchant: IMerchant }) => {
    let totalSku = 0
    let totalPrice = 0
    let totalSkuQuantity = 0

    for (let index = 0; index < merchant.products.length; index++) {
        const product = merchant.products[index]
        for (let index = 0; index < product.skus.length; index++) {
            const sku = product.skus[index]
            totalPrice = totalPrice + sku.price * sku.quantity
            totalSkuQuantity = totalSkuQuantity + sku.quantity

            totalSku = totalSku + 1
        }
    }

    return (
        <div className=" bg-white rounded flex flex-col gap-3  shadow-sm relative pb-6">
            <div className=" flex flex-row bg-[#E2EDF8] text-xs text-textPrimary h-[40px]  justify-between items-center px-5 ">Shop：{merchant.supplier}</div>
            <div className="flex flex-col  w-full p-3">
                <div className=" flex  flex-row bg-[#F9FBFF] border-[#E2EDF8] border-t border-l border-r h-[40px]  px-3">
                    <div className="text-xs text-textPrimary my-auto flex-1">Thông tin sản phẩm</div>
                    <div className="text-xs text-textPrimary my-auto mr-[92px]">Đơn giá</div>
                    <div className="text-xs text-textPrimary my-auto mr-[177px]">Số lượng</div>
                    <div className="text-xs text-textPrimary my-auto mr-[146px]">Giá giảm</div>
                    <div className="text-xs text-textPrimary my-auto mr-[60px]">Số tiền</div>
                    <div className="text-xs text-textPrimary my-auto mr-[7px]">Phí vận chuyển</div>
                </div>
                <div className="flex flex-row bg-[#F9FBFF]">
                    <div className="flex flex-col flex-1">
                        {merchant.products.map((e) => {
                            return (
                                <ItemProduct
                                    key={e.id}
                                    product={e}
                                />
                            )
                        })}
                    </div>
                    <div className=" flex flex-col  w-[123px]  border-r border-t bg border-[#E2EDF8] gap-1  items-center  justify-center h-full">
                        <div className="relative w-full">
                            <div className="text-sm justify-end flex flex-row w-full  pr-[20px]">
                                {numeral(totalSkuQuantity * 5000)
                                    .format("0,0")
                                    .replace(/,/g, ".")}
                                đ
                            </div>
                            <div className=" absolute flex-row flex h-fit  gap-1  justify-end mx-auto w-full items-center  mt-1  pr-5 ">
                                <div className="text-textPrimary font-light text-xs ">Chi tiết</div>
                                <CaretDownOutlined />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-t bg   bg-[#F9FBFF] border-[#E2EDF8]  border-l border-r border-b p-3 pr-[20px] pb-[36px] flex flex-row justify-between">
                    <div className=" flex flex-row items-center text-xs text-textPrimary">
                        Ghi chú cho người bán：
                        <input
                            className="w-[373px] px-[9px] focus:border-primary  hover:border-primary focus:outline-none focus:ring-0 focus:ring-offset-0 border border-[#E5E5E5] bg-white h-[28px]"
                            placeholder="Bạn có thể cho người bán biết yêu cầu đặc biệt của bạn"
                        />
                    </div>
                    <div>
                        <div className="flex flex-row  items-center gap-3">
                            <div className=" flex flex-col items-end  gap-3">
                                <div className="text-xs text-textPrimary">Tiền hàng：</div>
                                <div className="text-xs text-textPrimary">Tổng phí vận chuyển：</div>
                            </div>
                            <div className=" flex flex-col justify-end gap-2 items-end min-w-[70px]">
                                <div className="flex-1 text-sm text-primary font-medium">{numeral(totalPrice).format("0,0").replace(/,/g, ".")}đ</div>
                                <div className="flex-1 text-sm text-primary font-medium">
                                    {" "}
                                    {numeral(totalSkuQuantity * 5000)
                                        .format("0,0")
                                        .replace(/,/g, ".")}
                                    đ
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img
                className=" absolute bottom-[-4px] w-[1200px] h-[10px]"
                src={require("src/resources/images/imgs/pseudo.png")}
            />
        </div>
    )
}
