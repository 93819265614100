import React, { useEffect, useState } from "react"

import querystring from "query-string"

import lodash from "lodash"
import axios from "axios"

import appConfig from "@config/app"
import loadingGif from "@resources/images/imgs/loading.png"
import errorPng from "@resources/images/imgs/error.png"

import localStore from "@util/LocalStore"

export const Authentication = () => {
    const locationHash = window.location.hash.replace(/#/g, "?") || "";
    const params: any = querystring.parse(locationHash)
    const [isNotPermission, setIsNotPermission] = useState(false)
    const [msg, setMsg] = useState("Đang kiểm tra xác thực, vui lòng đợi")
    console.log('====================================');
    console.log("locationHash", params);
    console.log('====================================');
    // reset loginSession
    localStore.removeItem("loginSession")
    useEffect(() => {
        document.title = "Xác thực"
        console.log('====================================');
        console.log(params["access_token"]);
        console.log('====================================');
        if (params["access_token"]) {
            setMsg("Đang tiến hành đăng nhập vui lòng chờ trong giây lát....")
        } else {
            setIsNotPermission(true)
            setMsg("Không thể đăng nhập")
        }

        const fetchData = async () => {
            try {
                await axios({
                    url: appConfig.apiUrl + 'access-token',
                    // url: appConfig.apiUrl + 'staffs/m2/token',
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + params["access_token"],
                        "X-Tenant": appConfig.tenant ?? "",
                    },
                }).then((res)=> {
                    localStore.setJson('m1_session',res.data)
                }).catch(()=> {
                    localStore.setJson('m1_sessoin',undefined)
                })

                await axios({
                    url: appConfig.apiUrlDota + "profile",
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + params["access_token"],
                        "X-Tenant": appConfig.tenant ?? "",
                    },
                })
                    .then((response) => {
                        localStore.setJson("loginSession", {
                            accessToken: params["access_token"],
                        })
                        localStore.setJson("loggedUser", response.data)
                        localStore.setItem("expiresAt", params["expire_at"])
                        setTimeout(() => {
                            if (localStore.getItem("urlRedirectLogin")) {
        
                                window.location.href = localStore.getItem("urlRedirectLogin") ?? "/"
                                localStore.removeItem("urlRedirectLogin")
        
                            } else {
                                window.location.href = "/"
        
                            }
        
                        }, 1000)
                    })
                    .catch((error) => {
                        localStore.removeItem("loginSession")
                        localStore.removeItem("loggedUser")
                        localStore.removeItem("permissions")
                        localStore.removeItem("timezone")
                        setIsNotPermission(true)
                        setMsg(lodash.get(error, "response.data.message", ""))
                    })
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchData();

        // const redirectBackUrl = localStore.getItem("redirectBackUrl") ? localStore.getItem("redirectBackUrl") : btoa("/")
        
        // eslint-disable-next-line
    }, [locationHash])

    console.log(isNotPermission)

    return (
        <div className={"h-full flex flex-col justify-center"}>
            {!isNotPermission ? (
                <div className={"text-center margin-auto"}>
                    <div className="flex flex-row justify-center mb-8">
                        <img
                            className="w-[200px] h-[200px]"
                            src={loadingGif}
                            alt={""}
                        />
                    </div>
                    <p className="splash-text txt-color-black2 mgt25">{msg}</p>
                </div>
            ) : (
                <div className={"text-center margin-auto "}>
                    <div className="flex flex-row justify-center mb-8">
                        <img
                            className="w-[300px] h-[300px]"
                            src={errorPng}
                            alt={""}
                        />
                    </div>
                    <span className="txt-center mt-6">
                        <span className="splash-text txt-color-black2 mgt20">Không thể xác thực</span>
                        <span className={"ml-2"}>
                            <a
                                href="/"
                                className="splash-text txt-color-blue2 mg-r-20">
                                <span className="text-[#F9471B] underline underline-offset-3">Thử lại</span>
                            </a>
                        </span>
                    </span>
                </div>
            )}
        </div>
    )
}
