import { DownOutlined, UpOutlined } from "@ant-design/icons"
import React, { useState } from "react"
import { ItemProduct } from "./ItemProduct"
import { ICategory } from "@domain/Home"
import { useQuery } from "react-query"
import Home from "@api/Home"
import { useNavigate } from "react-router-dom"

interface IProducts {
    category?: ICategory
    type: number
}

export const Products = ({ category, type }: IProducts) => {
    const [showMore, setShowMore] = useState(false)
    const { data } = useQuery("getProductsHome" + category?.code, () => Home.getProductsCode(category?.code ?? "", 0, 5, type))
    const navigate = useNavigate();

    const getLastItems = ({ category }: { category?: ICategory }) => {
        let result: ICategory[] = []
        if (!category?.childs) return []
        for (let index = 0; index < category?.childs?.length; index++) {
            const item = category?.childs[index]
            if (!item.childs) {
                result.push(item)
            } else if (Array.isArray(item.childs)) {
                result = result.concat(getLastItems({ category: item }))
            }
        }

        return result
    }
    const handleClickMenuItem = (code: string, parent?: any) => {
        const temp = encodeURIComponent(code)
        if (!parent) navigate(`/categories?code=${temp}&cateLevel=1`)
        else {
            const pCode = encodeURIComponent(parent)
            navigate(`/categories?code=${temp}&parent=${pCode}&cateLevel=3`)
        }
    }
    const dataCategories = getLastItems({ category: category })

    const handleClickViewAll = (code: string) => {
        const temp = encodeURIComponent(code)
        navigate(`/categories?code=${temp}&cateLevel=1`)
    }

    return (
        <div className=" w-[1200px] mx-auto bg-white rounded-lg p-6 mb-6 ">
            <div className="flex flex-row gap-3 h-">
                <p className="text-xl text-textPrimary font-medium">{category?.name}</p>
                <p className="text-sm text-disable mt-[6px] flex-1">Dựa theo thói quen của bạn chúng tôi đã đề xuất những sản phẩm dưới đây</p>
                <p
                    className="text-sm text-primary cursor-pointer"
                    onClick={() => category?.code && handleClickViewAll(category.code)}>
                    Xem tất cả
                </p>
            </div>
            <div className="flex  flex-row justify-between items-start gap-10">
                <div className=" flex flex-wrap gap-x-8 gap-y-3">
                    {showMore
                        ? dataCategories.map((e) => {
                            return (
                                <div
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    onClick={() => handleClickMenuItem(e.code, category!.code + '&&' + e.parent)}
                                    className=" text-sm text-textPrimary hover:text-primary cursor-pointer"
                                    key={e.id}>
                                    {e.name}
                                </div>
                            )
                        })
                        : [...dataCategories].splice(0, 8).map((e) => {
                            return (
                                <div
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    onClick={() => handleClickMenuItem(e.code, category!.code + '&&' + e.parent)}
                                    className=" text-sm text-textPrimary hover:text-primary cursor-pointer"
                                    key={e.id}>
                                    {e.name}
                                </div>
                            )
                        })}
                </div>
                {dataCategories.length > 8 && (
                    <div
                        onClick={() => {
                            setShowMore(!showMore)
                        }}
                        className={"flex flex-row items-center gap-[6px]  cursor-pointer group"}>
                        <div className=" group-hover:text-primary text-[#666666] text-sm font-normal truncate">{showMore ? "Thu gọn" : "Thêm"}</div>
                        {showMore ? (
                            <UpOutlined
                                className="text-xs text-[#666] group-hover:text-primary"
                                rev={undefined}
                            />
                        ) : (
                            <DownOutlined
                                className="text-xs  text-[#666] group-hover:text-primary"
                                rev={undefined}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className=" flex flex-wrap gap-[19px] mt-6">
                {data?.data?.map((e) => {
                    return (
                        <ItemProduct
                            key={e.id}
                            data={e}
                        />
                    )
                })}
            </div>
        </div>
    )
}
