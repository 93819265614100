import AddressApi from "@api/Address"
import CountryPhoneInput from "@component/PhoneInput"
import { AddressCreateCommand } from "@domain/Address"
import { Button, Checkbox, Form, Input, Select, notification } from "antd"
import React, { useEffect, useRef, useState } from "react"

interface CreateAddressFormProps {
    callback?: () => void
    editItem?: any
    createTemporaryAddress?: (values: any) => void
    isCreateTemporary?: boolean
    temporaryAdd?: any
    addresses: any
    scrollToTop:()=> void;
}

function CreateAddressForm(props: CreateAddressFormProps) {
    const { callback, editItem, createTemporaryAddress, isCreateTemporary, addresses, temporaryAdd, scrollToTop } = props
    const [formData] = Form.useForm<AddressCreateCommand>()
    const [locations, setLocations] = useState<any[]>()
    const [pickedLocationCodes, setPickedLocationCode] = useState<string[]>()
    const [districts, setDictricts] = useState<any[]>()
    const [wards, setWards] = useState<any[]>()
    const [loading, setLoading] = useState(false)
    const [loadingLocation, setLoadingLocation] = useState(true)
    const firstInputRef = useRef<any>(null)
    const phoneNumb = Form.useWatch("phone", formData)

    useEffect(() => {
        if (!editItem) {
            formData.resetFields()
            formData.setFieldsValue({
                country: "vn",
                addressName: "vn",
                phone: {
                    emoji: "🇻🇳",
                    phoneCode: 84,
                    code: 84,
                    short: "VN",
                },
            })
        } else if (editItem) {
            const tempEditItem = { ...editItem }
            // console.log(tempEditItem)
            const getAllParentsAndPositions: any = (item: any, parents: any = []) => {
                if (item?.parent) {
                    parents.unshift({
                        name: item?.parent.name,
                        position: item?.parent.position,
                        code: item?.parent.code,
                    })
                    return getAllParentsAndPositions(item?.parent, parents)
                }
                return parents
            }
            const parentsAndPositions = getAllParentsAndPositions(tempEditItem.location)
            parentsAndPositions.forEach((p: any) => {
                if (p.position === 1) {
                    tempEditItem["province"] = p.code
                }
                if (p.position === 2) {
                    tempEditItem["district"] = p.code
                }
            })
            if (parentsAndPositions.length > 1) {
                tempEditItem["ward"] = editItem?.location?.code || editItem.ward
            } else {
                tempEditItem["district"] = editItem?.location?.code || editItem.district
            }

            if (parentsAndPositions.length === 1) {
                const t = parentsAndPositions.map((p: any) => p.code)
                t.push(editItem.location.code)
                setPickedLocationCode(t)
            } else {
                if (editItem.location && editItem.location.parent) setPickedLocationCode(parentsAndPositions.map((p: any) => p.code))
                else setPickedLocationCode([editItem.province, editItem.district, editItem.ward])
            }

            formData.setFieldsValue({
                ...tempEditItem,
                country: "vn",
                phone: {
                    emoji: "🇻🇳",
                    phoneCode: 84,
                    code: 84,
                    short: "VN",
                    phone: tempEditItem.phone?.substring(2),
                },
            })

            firstInputRef && firstInputRef?.current?.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    const fetchLocations = async (parent?: number | string, type?: any) => {
        const response = await AddressApi.getLocation({
            size: 1000,
            page: 0,
            type: type || "PROVINCE",
            parent,
        })
        let temp = response.data?.filter((d: any) => !d.parent)
        // console.log(temp)
        temp = temp.map((t: any) => ({
            ...t,
            label: t.name,
            value: t.code,
        }))
        if (!pickedLocationCodes) setLocations(temp)

        if (parent) {
            if (type === "DISTRICT") {
                setDictricts(response.data)
            } else if (type === "WARD") {
                setWards(response.data)
            }
        }
        setLoadingLocation(false)
    }

    useEffect(() => {
        fetchLocations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (pickedLocationCodes) {
            // console.log(pickedLocationCodes)
            if (pickedLocationCodes[0]) {
                fetchLocations(pickedLocationCodes[0], "DISTRICT")
            }
            if (pickedLocationCodes[1]) {
                fetchLocations(pickedLocationCodes[1], "WARD")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickedLocationCodes])

    const handleClickLocation = (position: number, value: any) => {
        const temp = pickedLocationCodes ? [...pickedLocationCodes] : []
        if (temp.length > 0) {
            temp.splice(position, 1, value)
        } else temp.push(value)
        setPickedLocationCode(temp)
        if (position < 1) {
            // console.log(position)
            formData.setFieldsValue({
                ...formData.getFieldsValue(true),
                district: undefined,
                ward: undefined,
            })
        } else if (position < 2) {
            formData.setFieldsValue({
                ...formData.getFieldsValue(true),
                ward: undefined,
            })
        }
    }

    const handleFinishForm = (values: any) => {
        // console.log(values)
        formData
            .validateFields()
            .then(() => {
                // console.log(values)
                if (!editItem) {
                    if (!isCreateTemporary) handleCreateAddress("CREATE", values)
                    else handleCreateAddress("TEMPORARY", values)
                } else if (editItem) {
                    if (addresses.find((e: any) => e.id === editItem.id)) handleCreateAddress("EDIT", values)
                    else {
                        handleCreateAddress("TEMPORARY", values)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleCreateAddress = (mode: "CREATE" | "EDIT" | "TEMPORARY", values: any) => {
        setLoading(true)
        const temp: any = { ...values }

        temp.phone = values.phone["code"] + values.phone["phone"]

        if (temp.ward) temp.location = temp.ward
        else if (temp.district) temp.location = temp.district

        delete temp.district
        delete temp.province
        delete temp.ward

        if (mode === "CREATE") {
            AddressApi.createAddress(temp)
                .then(() => {
                    notification["success"]({
                        message: "Tạo địa chỉ nhận hàng thành công",
                    })
                    formData.resetFields()
                    callback && callback()
                })
                .catch((err) => {
                    notification["error"]({
                        message: err.response?.data?.title,
                        description: err.response?.data?.detail,
                    })
                })
                .finally(() => setLoading(false))
        } else if (mode === "TEMPORARY") {
            // console.log(values)
            const tempAdd = {
                ...values,
                phone: temp.phone,
                location: temp.location,
                temporaryDisplay: wards?.find((w) => w.code === values.ward)?.display || districts?.find((d) => d.code === values.district)?.display,
            }
            // console.log('temp',temporaryAdd)
            createTemporaryAddress && createTemporaryAddress(tempAdd)

            notification["success"]({
                message: temporaryAdd ? "Sửa địa chỉ nhận hàng tạm thời thành công" : "Tạo địa chỉ nhận hàng tạm thời thành công",
            })
            formData.resetFields()
            callback && callback()
        } else {
            AddressApi.editAddress(editItem.id, temp)
                .then(() => {
                    notification["success"]({
                        message: "Sửa địa chỉ nhận hàng thành công",
                    })
                    formData.resetFields()
                    callback && callback()
                })
                .catch((err) => {
                    notification["error"]({
                        message: err.response?.data?.title,
                        description: err.response?.data?.detail,
                    })
                })
                .finally(() => setLoading(false))
        }
    }

    const checkPhone = () => {
        const regex1 = /(0[3|5|7|8|9])+([0-9]{8})\b/g
        const regex2 = /([3|5|7|8|9])+([0-9]{8})\b/g
        const regexTemp = /^0/
        const startsWithZero = regexTemp.test(phoneNumb.phone)
        if (startsWithZero) {
            return regex1.test(phoneNumb.phone)
        } else return regex2.test(phoneNumb.phone)
    }

    return (
        <React.Fragment>
            <div className="flex flex-col">
                {isCreateTemporary && (
                    <div className="text-[#999999] text-[12px] pl-[26px] pt-[9px] pb-4">Địa chỉ tạm thời chỉ được sử dụng cho đơn hàng này và sẽ không được lưu.</div>
                )}
                <div className={`w-[420px] ${editItem ? "mt-8" : ""}`}>
                    <Form
                        onFinish={handleFinishForm}
                        form={formData}
                        className="_create-address-form"
                        labelCol={{ span: 8 }}
                        labelAlign="right"
                        colon={true}>
                        <Form.Item
                            name={"fullname"}
                            label={"Người nhận hàng"}
                            rules={[{ required: true, message: "Tên người nhận không được bỏ trống" }]}>
                            <Input
                                ref={firstInputRef}
                                size="large"
                                placeholder="Tên người nhận hàng"
                                autoFocus
                            />
                        </Form.Item>

                        <Form.Item
                            name={"country"}
                            label={"Quốc gia"}
                            rules={[{ required: true, message: "Tên quốc gia không được để trống" }]}>
                            <Select
                                options={[{ label: "Việt Nam", value: "vn" }]}
                                size="large"
                                placeholder={"Chọn quốc gia "}
                            />
                        </Form.Item>

                        <Form.Item
                            name={"province"}
                            label={"Tỉnh/Thành phố"}
                            rules={[{ required: true, message: "Tỉnh/Thành phố không được để trống" }]}>
                            <Select
                                className="w-full"
                                options={locations}
                                onChange={(value) => handleClickLocation(0, value)}
                                placeholder={"Chọn tỉnh/thành phố"}
                                size="large"
                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
                                showSearch
                                loading={loadingLocation}
                            />
                        </Form.Item>

                        <Form.Item shouldUpdate>
                            {() => (
                                <Form.Item
                                    labelCol={{ span: 8 }}
                                    name={"district"}
                                    label={"Quận/Huyện"}
                                    rules={[{ required: true, message: "Quận/Huyện không được bỏ trống" }]}>
                                    <Select
                                        disabled={!pickedLocationCodes || (pickedLocationCodes && pickedLocationCodes.length === 0)}
                                        options={districts?.map((d: any) => ({
                                            label: d.name,
                                            value: d.code,
                                        }))}
                                        onChange={(value) => handleClickLocation(1, value)}
                                        placeholder={"Chọn quận/huyện"}
                                        size="large"
                                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                        }
                                        showSearch
                                        loading={loadingLocation}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>

                        <Form.Item shouldUpdate>
                            {() => (
                                <Form.Item
                                    labelCol={{ span: 8 }}
                                    name={"ward"}
                                    label={"Phường/Xã"}>
                                    <Select
                                        disabled={!pickedLocationCodes || (pickedLocationCodes && !pickedLocationCodes[1])}
                                        options={wards?.map((d: any) => ({
                                            label: d.name,
                                            value: d.code,
                                        }))}
                                        placeholder={"Chọn phường/xã"}
                                        size="large"
                                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                        }
                                        showSearch
                                        loading={loadingLocation}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>

                        <Form.Item
                            name={"detail"}
                            label={"Địa chỉ cụ thể"}
                            rules={[{ required: true, message: "Địa chỉ cụ thể không được để trống" }]}>
                            <Input
                                size="large"
                                placeholder="Địa chỉ cụ thể"
                            />
                        </Form.Item>

                        <Form.Item
                            shouldUpdate
                            name="phone"
                            label="Số điện thoại"
                            rules={[
                                { required: true, message: "Số điện thoại là bắt buộc" },
                                () => ({
                                    validator() {
                                        if (checkPhone()) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error("Số điện thoại không đúng định dạng"))
                                    },
                                }),
                            ]}>
                            <CountryPhoneInput
                                disabled={loading}
                                className=""
                                inline
                                // value={phoneData}
                            />
                        </Form.Item>
                        {
                            <Form.Item
                                name={"defaultAddress"}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16, offset: 8 }}
                                valuePropName="checked"
                                className="!mb-[4px]">
                                <Checkbox disabled={(!addresses.find((a: any) => editItem?.id === a.id) && editItem) || isCreateTemporary}>
                                    <span className="text-[12px]">Đặt làm địa chỉ giao hàng mặc định</span>
                                </Checkbox>
                            </Form.Item>
                        }

                        <Form.Item
                            shouldUpdate
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16, offset: 8 }}>
                            {() => (
                                <div className="flex gap-4">
                                    <Button
                                        loading={loading}
                                        type="primary"
                                        htmlType="submit"
                                        className="_accept-btn">
                                        Xác nhận
                                    </Button>
                                    <button
                                        className="_cancel-btn"
                                        onClick={(e) => {
                                            if (addresses && addresses.length < 1) {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                return
                                            } else {
                                                formData.resetFields()
                                                callback && callback()
                                            }
                                            scrollToTop()
                                        }}>
                                        Huỷ bỏ
                                    </button>
                                </div>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CreateAddressForm
