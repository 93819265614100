import React from "react"
import { ItemProduct } from "./ItemProduct"
import Home from "@api/Home"
import { InfinityScroll } from "@component/InfiniteScroll"
import { IProduct } from "@domain/Product"

export const ProductSuggest = () => {
    const [loading, setLoading] = React.useState(false)
    const [hasMode, setHasMode] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [products, setProducts] = React.useState<IProduct[]>([])

    const fetchData = async () => {
        setLoading(true)
        const response = await Home.getProducts(page, 10, 3)
        const data = response.data
        if (response.data.length === 0) setHasMode(false)
        else setPage(page + 1), setProducts([...products, ...data])
        setLoading(false)
    }

    return (
        <div className=" w-[1200px] mx-auto bg-white rounded-lg p-6 mb-6 ">
            <div className="flex flex-row gap-3 h-">
                <p className="text-xl text-textPrimary font-medium">Đề xuất cho bạn</p>
                <p className="text-sm text-disable mt-[6px] flex-1">Dựa theo thói quen của bạn chúng tôi đã đề xuất những sản phẩm dưới đây</p>
            </div>
            <div className="flex  flex-row justify-between items-start gap-10"></div>
            <div className=" flex flex-wrap gap-[19px] mt-6">
                {products?.map((e) => {
                    return (
                        <ItemProduct
                            key={e.id}
                            data={e}
                        />
                    )
                })}
            </div>
            <InfinityScroll
                loading={loading}
                fetchData={fetchData}
                hasMode={hasMode}
            />
        </div>
    )
}
