import appConfig from "@config/app";
import { IMerchant } from "@domain/Cart";
import apiClient from "@util/ApiClient"


class CartApi {


    static getMerchants(): Promise<{ data: IMerchant[] }> {
        return apiClient.get(`${appConfig.apiUrl}/cart/items?`, {
        })
    }


}

export default CartApi;