import apiClient from "@util/ApiClient"

class ProductApi {
    static getProductList(filter?: any) {
        return apiClient.get(`search`, {
            params: {
                ...filter,
            },
        })
    }

    static getProductDetail(id: string) {
        return apiClient.get(`search/${id}`)
    }

    static adđToCart(body: any) {
        // console.log(appConfig.tenant)
        return apiClient.post(`add_skus`, body)
    }
}


export default ProductApi
