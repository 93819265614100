import { IBanner, ICategory } from "@domain/Home"
import { IProduct } from "@domain/Product"
import apiClient from "@util/ApiClient"

export default class Home {
    // static getTimezones(): Promise<{ data: ITimezone[] }> {
    //     return apiClient.get(`time-zones`)
    // }

    static getCategories(): Promise<{ data: ICategory[] }> {
        return apiClient.get(`categories?hasAll=true`)
    }
    static getProducts(page: number, size: number, type: number): Promise<{ data: IProduct[] }> {
        return apiClient.get(`search`, { params: { page, size, type: type } })
    }
    static getProductsCode(code: string, page: number, size: number, type: number): Promise<{ data: IProduct[] }> {
        return apiClient.get(`search`, { params: { categoryCode: code, page, size, type: type } })
    }
    static getBanners(): Promise<{ data: IBanner[] }> {
        return apiClient.get(`banners`, { params: { type: "home" } })
    }

}
