import { IMerchant } from "@domain/Product"
import { Avatar, Button, Input, Rate, Tag } from "antd"
import React from "react"

import itemDemo from '../../../resources/images/icons/item-demo.png'


interface MerchantInfoProps {
    merchant: IMerchant | undefined
}

function MerchantInfo(props: MerchantInfoProps) {
    const { merchant } = props
    return (
        <React.Fragment>
            <div className="flex items-center merchant-info">
                <Avatar
                    // src={merchant?.avatar}
                    src={"https://cdn4.vectorstock.com/i/1000x1000/31/38/building-business-company-logo-vector-19953138.jpg"}
                    shape="circle"
                    size={64}
                />
                <div className="flex flex-col gap-2 ml-3">
                    <span>{merchant?.company}</span>
                    <span className="flex items-center">
                        <span>
                            <Tag
                                color="purple"
                                className="rounded-md">
                                {merchant?.age} năm
                            </Tag>
                        </span>
                        <span className="flex items-center ml-2 text-[#B1B1B1] text-[12px]">
                            Dịch vụ
                            <span className="ml-[-24px]">
                                <Rate
                                    value={merchant?.overallRating && merchant.overallRating}
                                    disabled
                                    className="rate-preview py-[2px] px-[8px] rounded-lg bg-gray-100 left-0"
                                    style={{ scale: "0.5", color: "red" }}
                                />
                            </span>
                        </span>
                    </span>
                    <span className="text-[12px] text-[#B1B1B1]">
                        Đánh giá
                        <Tag className="ml-2 border-none">
                            <span className="text-[10px]">Dịch vụ {merchant?.serviceRating}</span>
                        </Tag>
                        <Tag className="ml-1 border-none">
                            <span className="text-[10px]">Sản phẩm {merchant?.productRating}</span>
                        </Tag>
                        <Tag className="ml-1 border-none">
                            <span className="text-[10px]">Vận chuyển {merchant?.deliveryRating}</span>
                        </Tag>
                        <Tag className="ml-1 border-none">
                            <span className="text-[10px]">Trung thành {merchant?.loyaltyRate}</span>
                        </Tag>
                    </span>
                </div>

                <div className="ml-[32px] flex gap-2">
                    <img
                        style={{ width: 70, height: 70 }}
                        src={itemDemo}
                    />
                    <img
                        style={{ width: 70, height: 70 }}
                        src={itemDemo}
                    />
                </div>
                <div className="flex flex-col ml-[32px] ">
                    <div className="flex justify-between mb-2 w-[300px]">
                        <span className="mr-4 text-[#F9471B] text-xs">Cửa hàng của chúng tôi</span>
                        <span className="mr-4 text-[#B1B1B1] text-xs">Nguồn</span>
                        <span className="text-[#B1B1B1] text-xs">Nhà máy</span>
                    </div>
                    <Input.Group className="flex">
                        <Input className="w-full _merchant-search-input" 
                            placeholder="Từ khoá"
                            style={{
                                border: '1px solid !important',
                                borderColor: '#F9471B !important',
                            }}
                        />
                        <Button
                            type="primary"
                            className="border-l-[transparent] ml-[-8px] z-10">
                            Tìm
                        </Button>
                    </Input.Group>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MerchantInfo
