import { IProductListViewCard } from "@domain/Product"
import { numberFormatter } from "@util/Common"
import { Image, Tag } from "antd"
import React from "react"
import { get_url_extension } from "../utilities"

function CategoryProductCard({ item }: { item: IProductListViewCard }) {
    return (
        <React.Fragment>
            <div className="_cate-product-card cursor-pointer">
                <div className="absolute z-10 top-5 left-3">
                    <span className="product-badge">{item.brand || 'CHILA'}</span>
                </div>
                {["mp4"].includes(get_url_extension(item.featuredImage)) ? (
                    <div className="h-[210px]">
                        <video
                            className=" w-full h-full object-cover"
                            autoPlay
                            loop
                            muted>
                            <source
                                src={item.featuredImage}
                                type="video/mp4"
                            />
                            Trình duyệt không hỗ trợ
                        </video>
                    </div>
                ) : (
                    <div className="h-[210px] w-full flex justify-center">
                    <Image
                        className="h-full w-full cursor-pointer object-cover"
                        src={item.featuredImage && item.featuredImage}
                        preview={false}
                        onClick={() => {
                            window.open(`/products/${item.name}/${item.id}`)
                        }}
                    />
                    </div>
                )}

                <div className="_cate-product-card_info flex flex-col gap-2 px-1 static z-[2] mt-1">
                    <a
                        className="text-black hover:text-[#F43314] h-[45px] text-ellipsis overflow-hidden"
                        href={`/products/${item.name}/${item.id}`}
                        rel="noreferrer"
                        target="_blank">
                        {item.name}
                    </a>
                    <div>
                        <Tag
                            color=""
                            className="border-[#F43314] text-[#F43314] font-normal">
                            Giảm 20% cho đơn từ 2tr
                        </Tag>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-lg">{numberFormatter(item.salePrice).replace(/,/g,'.')}đ</span>
                        <span className="text-xs text-[#B1B1B1]">VC thẳng</span>
                    </div>
                    <div className="flex justify-between text-xs items-center text-[#B1B1B1]">
                        <span>100 giao dịch /1 tháng</span>
                        <span>{item.merchant && item.merchant.locations && item.merchant.locations[0]?.split(" ")[1]}</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CategoryProductCard
