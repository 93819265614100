import { Authentication } from "@screen/Authentication";
import Cart from "@screen/Cart";
import CartConfirm from "@screen/CartConfirm";
import CategoriesPage from "@screen/CategoriesPage";
import ComponentView from "@screen/ComponentView";
import { Home } from "@screen/Home"
import { Login } from "@screen/Login"
import ProductDetail from "@screen/ProductDetail"
import { Register } from "@screen/Register"
import { SearchPage } from "@screen/Search"
import { SearchImagePage } from "@screen/Search/SearchImage"
import React from "react"
// import { AuthenticateRoute } from "@component/AuthenticateRoute"

export interface IRouteConfig {
    name: string
    path: string
    component: JSX.Element
}

export const routes: Array<IRouteConfig> = [
    // Trường hợp router cần xác thực đăng nhập
    // {
    //     name: "cart",
    //     path: "/",
    //     component: (
    //         <AuthenticateRoute>
    //             <Home />
    //         </AuthenticateRoute>
    //     ),
    // },
    {
        name: "authentication",
        path: "/authentication",
        component: <Authentication />,
    },
    {
        name: "login",
        path: "/login",
        component: <Login />,
    },
    {
        name: "cart",
        path: "/cart",
        component: <Cart />,
    },
    {
        name: "cart-confirm",
        path: "/cart-confirm",
        component: <CartConfirm />,
    },
    {
        name: "register",
        path: "/register",
        component: <Register />,
    },
    {
        name: "home",
        path: "/",
        component: <Home />,
    },
    {
        name: "search",
        path: "/search",
        component: <SearchPage />,
    },
    {
        name: "cart",
        path: "/cart",
        component: <Cart />,
    },
    {
        name: "searchImage",
        path: "/search/image",
        component: <SearchImagePage />,
    },
    {
        name: "login",
        path: "/login",
        component: <Login />,
    },
    {
        name: "categories",
        path: "/categories",
        component: <CategoriesPage />,
    },
    {
        name: "product-detail",
        path: "products/:name/:id",
        component: <ProductDetail />,
    },
    {
        name: 'component-preview',
        path: 'component-view',
        component: <ComponentView />
    }
]
