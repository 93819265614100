import { ICategory } from "@domain/Home"
import React, { useEffect, useRef, useState } from "react"
import { subMenu, timeout } from "./ItemMenu"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
export const MenuShow = () => {
    const [category, setCategory] = useState<ICategory>()
    const [top, setTop] = useState<number>(0)
    const [left, setLeft] = useState<number>(0)
    const [divHeight, setDivHeight] = useState<number>(0)
    const divRef = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const divHeight: any = entry.contentRect.height
                setDivHeight(divHeight)
            }
        })

        if (divRef.current) {
            observer.observe(divRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [category])

    useEffect(() => {
        const sub = subMenu.subscribe(({ category }: any) => {
            if (category) {
                const divElement: any = document.getElementById(category.id)
                const rect = divElement.getBoundingClientRect()
                setCategory(category)
                setTop(rect.top)
                setLeft(rect.left)
            } else {
                setCategory(undefined)
            }
        })

        return () => {
            sub.unsubscribe()
        }
    }, [])
    const getMarginTop = () => {
        const div = document.getElementById("layout")
        const scrollY = div?.scrollTop ?? 0
        if (divHeight > window.innerHeight) {
            console.log("case1")

            return scrollY
        }
        if (scrollY + top + divHeight > window.innerHeight + scrollY) {
            console.log("case2")

            return window.innerHeight - divHeight + scrollY - (top < 50 ? 0 : 25)
        }
        console.log("case3")

        return scrollY + top - (top < 50 ? 0 : 25)
    }

    if (category) {
        return (
            <div
                ref={divRef}
                onMouseEnter={() => {
                    clearTimeout(timeout.time)
                }}
                onMouseLeave={() => {
                    subMenu.next({})
                }}
                style={{ marginTop: getMarginTop(), marginLeft: left + 205 }}
                className={classNames("absolute pr-8  z-[1001] pl-4")}>
                <div className="bg-white border-primary w-[600px]  px-4 py-6 rounded border gap-4 flex flex-col ">
                    {category.childs?.map((e, index) => {
                        return (
                            <div
                                key={index}
                                className="flex flex-col gap-2">
                                <a
                                    className="font-medium text-sm text-textPrimary truncate line-clamp-1 hover:text-primary "
                                    onClick={() => {
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        navigate(`categories?code=${encodeURIComponent(e.code)}&cateLevel=${e.level}&parent=${encodeURIComponent(e.parent!)}`)
                                    }}>
                                    {e.name}
                                </a>
                                <div className="flex  flex-wrap gap-3">
                                    {e.childs?.map((e2, i) => {
                                        return (
                                            <div
                                                className="flex flex-row gap-3"
                                                key={i}>
                                                <a
                                                    className=" font-light text-sm text-textPrimary hover:text-primary  truncate line-clamp-1"
                                                    onClick={() => {
                                                        navigate(
                                                            `categories?code=${encodeURIComponent(e2.code ?? "")}&parent=${encodeURIComponent(
                                                                e.parent + "&&" + e2.parent
                                                            )}&cateLevel=${e2.level}`
                                                        )
                                                    }}>
                                                    {e2.name}
                                                </a>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    return <></>
}
