import { animated, useSpring } from '@react-spring/web';
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';

export const Filter = () => {
    const [priceStart, setPriceStart] = React.useState("");
    const [priceEnd, setPriceEnd] = React.useState("");
    const [quantityBegin, setQuantityBegin] = React.useState("");
    const [focusPriceInput, setFocusPriceInput] = React.useState(false);
    const [focusQuantityInput, setFocusQuantityInput] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setPriceEnd(searchParams.get("priceEnd") || "")
        setPriceStart(searchParams.get("priceStart") || "")
        setQuantityBegin(searchParams.get("quantityBegin") || "")
    }, [searchParams])

    const onFilterByPrice = () => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            priceStart,
            priceEnd
        })
    }
    const onFilterByQuantity = () => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            quantityBegin
        })
    }
    const onFocusPriceInput = () => {
        setTimeout(() => { setFocusPriceInput(true) }, 300)
    }
    const onBlurPriceInput = () => {
        setTimeout(() => { setFocusPriceInput(false) }, 300)
    }

    const onFocusQuantityInput = () => {
        setTimeout(() => { setFocusQuantityInput(true) }, 300)
    }
    const onBlurQuantityInput = () => {
        setTimeout(() => { setFocusQuantityInput(false) }, 300)
    }

    const [styleFilterPrice] = useSpring(
        () => ({
            from: { x: focusPriceInput ? -40 : 0, opacity: 0 },
            to: { x: focusPriceInput ? 10 : -40, opacity: focusPriceInput ? 1 : 0 },
        }),
        [focusPriceInput]
    )
    const [styleFilterQuantity] = useSpring(
        () => ({
            from: { x: focusQuantityInput ? -40 : 0, opacity: 0 },
            to: { x: focusQuantityInput ? 10 : -40, opacity: focusQuantityInput ? 1 : 0 },
        }),
        [focusQuantityInput]
    )

    return <div
        className="bg-[#ffffff] rounded flex flex-col gap-0 items-start justify-start self-stretch shrink-0 relative overflow-hidden"
        style={{
            boxShadow:
                "var(--size-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))",
        }}
    >
        <div className="bg-[#ffffff] flex flex-row gap-0 items-start justify-center shrink-0 h-11 relative">
            <div className="shrink-0 h-11 relative flex flex-row">
                <div className="flex flex-col gap-0 items-start justify-center h-[44px] ">
                    <div className="flex flex-row items-start justify-start shrink-0 relative ">
                        <div className="bg-[#ff7300] border-solid border-[#ff7300] border px-2 pt-[11px] pb-3 pflex flex-col gap-0 items-center justify-start shrink-0 h-10 relative">
                            <div
                                className="text-[#ffffff] text-center relative flex items-center justify-center"
                                style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                            >
                                Tổng hợp{" "}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-0 items-center justify-start mr-2">
                    <div className="bg-[#ffffff] pl-6 flex flex-row gap-0 items-center justify-start shrink-0 relative h-full z-50 ">
                        <input onFocus={onFocusPriceInput} onBlur={onBlurPriceInput} placeholder='Giá thấp nhất' value={priceStart} onChange={(e) => {
                            const floatValues = /[+-]?([0-9]*[.])?[0-9]+/;
                            if (e.target.value.match(floatValues) || e.target.value === '') {
                                setPriceStart(e.target.value)
                                // your function
                            }
                        }} className='w-[100px] text-[#1a1a1a] placeholder:text=[#aaa] text-xs h-[22px] text-center border-[#e8e8e8] border px-2 py-0.5 focus:outline-none' />
                        <div className="bg-[rgba(85,85,85,0.50)] w-1.5 h-px mx-3 my-1.5 z-50"></div>
                        <input onFocus={onFocusPriceInput} onBlur={onBlurPriceInput} placeholder='Giá cao nhất' value={priceEnd}
                            onChange={(e) => {
                                const floatValues = /[+-]?([0-9]*[.])?[0-9]+/;
                                if (e.target.value.match(floatValues) || e.target.value === '') {
                                    setPriceEnd(e.target.value)
                                    // your function
                                }
                            }} className='w-[100px] z-50 text-[#1a1a1a] placeholder:text=[#aaa] text-xs h-[22px] text-center border-[#e8e8e8] border px-[8px] py-[2px] focus:outline-none' />

                    </div>
                    <animated.div
                        onClick={onFilterByPrice}
                        className={"border-primary border text-primary justify-center items-center content-center flex cursor-pointer "}
                        style={{
                            width: focusPriceInput ? 68 : 0,
                            height: 22,
                            borderRadius: 4,
                            zIndex: 1,
                            color: "white",
                            textAlign: "center",
                            fontSize: 10,
                            marginRight: 10,
                            ...styleFilterPrice,
                        }}
                    >
                        <div>
                            Tìm kiếm
                        </div>
                    </animated.div>
                </div>
                <div className="flex flex-row gap-0 items-center justify-start border-[#e8e8e8] border-r pr-2">
                    <div className="bg-white z-50 border-solid pt-[3px] pr-px flex flex-row gap-0 items-center justify-startshrink-0 h-11 relative overflow-hidden">
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                        >
                            Số lượng bán tối thiểu
                        </div>
                        <input onFocus={onFocusQuantityInput} onBlur={onBlurQuantityInput}
                            value={quantityBegin}
                            onChange={(e) => {
                                const re = /^[0-9\b]+$/;
                                if (e.target.value === '' || re.test(e.target.value)) {
                                    setQuantityBegin(e.target.value)
                                }
                            }}
                            placeholder='0'
                            className='w-[100px] ml-3 text-[#1a1a1a] placeholder:text=[#aaa] text-xs h-[22px] text-center border-[#e8e8e8] border px-2 py-0.5 focus:outline-none' />
                        <div
                            className="text-text-color-text-secondary text-left relative flex items-center justify-start ml-2"
                            style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                        >
                            cái
                        </div>

                    </div>
                    <animated.div
                        onClick={onFilterByQuantity}
                        className={"border-primary border text-primary justify-center items-center content-center flex cursor-pointer "}
                        style={{
                            width: focusQuantityInput ? 68 : 0,
                            height: 22,
                            borderRadius: 4,
                            zIndex: 1,
                            color: "white",
                            textAlign: "center",
                            fontSize: 10,
                            marginRight: 10,
                            ...styleFilterQuantity,
                        }}
                    >
                        <div>
                            Tìm kiếm
                        </div>
                    </animated.div>
                </div>
                {/* <div className="pr-3.5 flex flex-row gap-0 items-center justify-start h-11 ">
                    <div className="border-solid border-[#e8e8e8] border-r pt-[3px] pr-px flex flex-row gap-0 items-start justify-start shrink-0 h-11 relative overflow-hidden">
                        <div className="bg-[#ffffff] pt-[7px] pr-3.5 pb-[7px] flex flex-row gap-0 items-start justify-start shrink-0 relative">
                            <div className="pt-[3px] pr-[2.46px] pb-[19px] pl-2.5 flex flex-col gap-0 items-start justify-start self-stretch shrink-0 relative">
                                <div
                                    className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                                    style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                                >
                                    Ngày lên giá{" "}
                                </div>
                            </div>
                            <div className="pl-2.5 flex flex-col gap-0 items-start justify-start shrink-0 w-[74px] relative">
                                <div className="bg-[#f6f6f6] rounded shrink-0 h-4 mt-1 w-16 relative"></div>
                            </div>
                            <div className="pt-[3px] pb-[19px] pl-2.5 flex flex-col gap-0 items-start justify-start self-stretch shrink-0 relative">
                                <div
                                    className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                                    style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                                >
                                    ngày{" "}
                                </div>
                            </div>
                        </div>
                    </div>

                </div> */}

            </div>
        </div>
    </div>
}