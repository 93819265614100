const appConfig = {
	apiUrl: process.env.REACT_APP_API_ROOT,
	apiUrlDota: process.env.REACT_APP_API_DOTA,
	loginUrl: process.env.REACT_APP_LOGIN_ROOT,
	logoutUrl: process.env.REACT_APP_LOGOUT_ROOT,
	tenant: process.env.REACT_APP_X_TENANT,
	apiUrlLuthen: `${process.env.REACT_APP_API_ROOT_LUTHEN}/v2`,
	authenticationUrl: window.location.origin + "/authentication"
}

export default appConfig
