import lodash from "lodash"
import moment from "moment-timezone"


export const formatDate = (d: any, format: string, zoneInfo?: string) => {
    const timezone = zoneInfo || localStorage.getItem("timezone") || "Asia/Ho_Chi_Minh"
    return moment.utc(d).tz(timezone).format(format)
}

export const formatDateShort = (d: string) => {
    return formatDate(d, "DD/MM/YYYY")
}

export const formatDateMedium = (d: string) => {
    return formatDate(d, "DD/MM/YYYY HH:mm")
}

export const formatDateFull = (d: string) => {
    return formatDate(d, "DD/MM/YYYY HH:mm:ss")
}

export const filterWithTimezone = (d: any) => {
    // const timezone = localStorage.getItem("timezone") || "Asia/Ho_Chi_Minh"
    // return moment(d).tz(timezone)
    return moment(d)
}


export const shortenText = (text: string, number: number, suffix?: string) => {
    if (text?.length > number) return `${text.slice(0, number)}...${suffix || ""}`
    return text
}

export const convertFileToBuffer = (file: any) => {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        // Register event listeners
        reader.addEventListener("loadend", (e: any) => resolve(e.target.result))
        reader.addEventListener("error", reject)
        // Read file
        reader.readAsArrayBuffer(file)
    })
}

export const randomIntFromInterval = (min: number, max: number) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}


export const convertNumberToCurrency = (text: string | number) => {
    if (text === "") return ""
    if (text === 0 || text === "0") return 0
    return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export const numberParser = (value: any) => {
    return value?.toString()?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:"'<>?\\]/g, "")
}

export const numberFormatter = (value: any) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export interface Variant {
    [key: string]: [value: string]
}

export const renderVariant = (variant: Variant) => {
    if (lodash.isObject(variant)) {
        const text: Array<string> = []
        lodash.forEach(variant, (value, key) => {
            text.push(`${key}: ${value}`)
        })

        return text.join(", ")
    }
    return ""
}

export const filterOption = (input: any, option: any) => {
    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
}

export const filterSort = (optionA: any, optionB: any) => {
    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
}

export const parseFloatNumber = (value: any) => {
    if (value) {
        return parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2)
    }
    return 0
}

export const randomEmail = ({ domain }: { domain: string }) => {
    const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
    let string = '';
    for (let ii = 0; ii < 15; ii++) {
        string += chars[Math.floor(Math.random() * chars.length)];
    }
    return string + "@" + (domain || "gmail.com");
}