import AddressApi from "@api/Address"
import { AddressView } from "@domain/Address"
import { Skeleton } from "antd"
import React, { useEffect, useState } from "react"
import ListAddress from "./ListAddress"

function AddressComponent() {
    const [addresses, setAddresses] = useState<AddressView[]>()
    const [fetchingAddresses, setFetchingAddresses] = useState(false)

    const fetchAddresses = () => {
        setFetchingAddresses(true)
        AddressApi.getAddresses({
            page: 0,
            size: 10,
            sort: "defaultAddress:desc,createdAt:desc",
        })
            .then((response) => {
                setAddresses(response.data)
            })
            .catch((err) => {
                console.log(err.response?.data?.title)
            })
            .finally(() => setFetchingAddresses(false))
    }

    useEffect(() => {
        fetchAddresses()
    }, [])

    return (
        <React.Fragment>
            <div className="_addresses-box flex">
                <Skeleton
                    paragraph={{ rows: 1 }}
                    active
                    loading={fetchingAddresses}>
                    <div className="flex">
                        <div className="text-[12px] text-[#666666] w-[50px] pt-[10px] mr-[50px]">Địa chỉ:</div>
                        <ListAddress
                            addresses={addresses}
                            getAddresses={fetchAddresses}
                        />
                    </div>
                </Skeleton>
            </div>
        </React.Fragment>
    )
}

export default AddressComponent
