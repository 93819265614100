import { notification } from "antd"
import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import lodash from "lodash"
import localStore from "./LocalStore"

export class M1HttpClient {
    static instance: AxiosInstance

    static getInstance() {
        if (!M1HttpClient.instance) {
            if (localStore.getItem("loginSession")) M1HttpClient.initialize()
        }
        return M1HttpClient.instance
    }

    static initialize() {
        const m1_session = localStore.getJson("m1_session")
        const instance = axios.create({
            baseURL: process.env.REACT_APP_M1_API_URL ? process.env.REACT_APP_M1_API_URL : "",
            headers: {
                Authorization: `Bearer ${m1_session?.accessToken || ''}`,
            },
        })
        // Add a request interceptor
        instance.interceptors.request.use(
            function (config) {
                // Do something before request is sent
                return config
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error)
            }
        )

        instance.interceptors.response.use(
            function (response) {
                return response
            },
            function (error) {
                const err: { status?: number; title?: string; detail?: string } = lodash.get(error, "response", {})
                console.log(error)
                if (err && err.status === 401) {
                    // console.log("401 m1 token")
                } else if (lodash.get(error, "response.status") >= 500) {
                    notification.error({
                        message: "Máy chủ M1 gặp sự cố. Vui lòng thử lại sau",
                        key: "SERVER_INTERNAL_ERROR",
                    })
                }
                return Promise.reject(error)
            }
        )

        M1HttpClient.instance = instance
    }

    static get = (url: string, config: AxiosRequestConfig) => {
        return M1HttpClient.getInstance().get(url, config)
    }

    static put = (url: string, data?: any, config?: AxiosRequestConfig) => {
        return M1HttpClient.getInstance().put(url, data, config)
    }

    static post = (url: string, data: any, config?: AxiosRequestConfig) => {
        return M1HttpClient.getInstance().post(url, data, config)
    }

    static patch = (url: string, data?: any, config?: AxiosRequestConfig) => {
        return M1HttpClient.getInstance().patch(url, data, config)
    }

    static delete = (url: string, config: AxiosRequestConfig) => {
        return M1HttpClient.getInstance().delete(url, config)
    }
}

export const m1HttpClient  = async () => {
    return await M1HttpClient.getInstance();
}
