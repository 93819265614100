import { ICategoryView } from "@domain/Categories"
import React from "react"
import CategoryItem from "./CategoryItem"
import { categoryImagesFake } from "../utilities"

interface CategoryBlockItemsProps {
    catTitle: string | React.ReactNode | undefined
    childrens: ICategoryView[] | undefined
    [key: string]: any,
    childest?: ICategoryView
}

function CategoryBlockItems(props: CategoryBlockItemsProps) {
   const {
        childest, catTitle,childrens
   } = props

   const renderChildrenItem = () => {
        if(childrens) {
            return childrens.map((ele,index)=> {
                return <CategoryItem key={index} item={ele} fakeImg={categoryImagesFake[index]}/>
            })
        } else if(!childrens && childest) {
            return (
                <div className="pb-2">
                    <CategoryItem item={props.childest} />
                </div>
            )
        } else {
            return <div>
                Empty Childs
            </div>
        }
   }

    return (
        <React.Fragment>
            <div className="my-5">
                <div className="text-left font-bold text-sm">
                    {!childest ? catTitle : ''}
                </div>
                <div className="mt-3 grid grid-cols-3 gap-[14px]">
                    {renderChildrenItem()} 
                </div>
            </div>
        </React.Fragment>
    )
}

export default CategoryBlockItems
