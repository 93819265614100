import { IProduct } from "@domain/Product"
import numeral from "numeral"
import React from "react"
export const ItemProduct = ({ data }: { data: IProduct }) => {
    return (
        <a
            onClick={(e) => {
                e.stopPropagation()
            }}
            target="_blank"
            rel="noreferrer"
            href={`products/${encodeURIComponent(data.name)}/${data.id}`}

            className="w-[214px] flex flex-col border border-[#EFF4FB] group hover:border-primary relative cursor-pointer">
            {data.featuredImage.endsWith(".mp4") ? (
                <video
                    width="214"
                    height="214"
                    className=" w-[214px] h-[214px]"
                    autoPlay
                    loop
                    muted>
                    <source
                        src={data.featuredImage}
                        type="video/mp4"
                    />
                    Rất tiếc, trình duyệt của bạn không hỗ trợ thẻ video.
                </video>
            ) : (
                <img
                    alt=""
                    className=" text-sm  text-textPrimary font-normal  w-[214px] h-[214px]"
                    src={data.featuredImage}
                />
            )}

            <div className=" flex flex-col p-2 flex-1">
                <p className="line-clamp-2  cursor-pointer hover:text-primary text-textPrimary">{data.name}</p>
                <div className=" flex  flex-1 flex-row justify-between">
                    {/* <div className=" bg-[#EFF4FB] w-fit px-1 rounded font-normal text-xs text-[#4869AF]">7 ngày trả hàng</div>
                    <div className=" rounded text-white text-sm bg-primary w-fit px-[6px]">HOT</div> */}
                </div>
                <div className="flex flex-row justify-between items-end h-fit mt-4 gap-3">
                    <div className=" flex flex-row items-center gap-[2px]">
                        <div className="text-lg text-primary leading-[18px] ">{numeral(data.salePrice).format("0,0").replace(/,/g, ".")}</div>
                        <div className="text-base text-primary leading-[16px] ">đ</div>
                    </div>

                    <div className=" font-normal text-xs text-disable line-clamp-1">
                        (Bán {data.totalSold < 1000 ? data.totalSold : (data.totalSold / 1000).toFixed(1).replace(".0", "") + "k"} sp)
                    </div>
                </div>
                <div className="flex flex-row justify-between items-end h-fit mt-3 border-t pt-2 border-[#F2F2F2] gap-4">
                    <div className="text-xs text-disable leading-[18px] line-clamp-1">{data.merchant.translatedCompany || data.merchant.company}</div>
                    <div className=" font-normal text-xs text-disable line-clamp-1">{data.merchant.locations[0] ?? ""}</div>
                </div>
            </div>
            <div className=" w-full group-hover:block hidden bottom-0 h-24 bg-gradient-to-b to-white from-[#FFFFFF90] absolute" />
            <button
                onClick={(e) => {
                    e.stopPropagation()
                    window.open(
                        window.location.origin +
                        "/search/image?imageUrls=" +
                        (data.featuredImage.endsWith(".mp4") ? data.productImages[0] : data.featuredImage),
                        "_blank",
                        "noopener,noreferrer"
                    )
                }}
                className="py-[2px] group-hover:block hidden absolute left-0 right-0  mx-auto bottom-14 rounded-xl w-fit px-12  text-white bg-primary">
                SP Tương tự
            </button>
        </a>
    )
}
