import appConfig from "@config/app";
import { ICategoryCriterias } from "@domain/Categories";
import apiClient from "@util/ApiClient";
import axios from "axios";

class CategoriesApi {
    static getAllCategories (filter?: ICategoryCriterias) {
        return axios.get(`${appConfig.apiUrl}/categories`,{
            params: {
                ...filter,
            }
        })
    }

    static getProductList (filter?:any) {
        return axios.get(`${appConfig.apiUrl}/search`,{
            params: {
                ...filter
            }
        })
    }

    static getCategoriyBanners (filter?: any) {
        return apiClient.get(`banners`,{
            params: filter
        })
    }  
}

export default CategoriesApi;