import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { ICategory, IBanner } from "@domain/Home"
import Home from "@api/Home"

interface State {
    categories: ICategory[]
    banners: IBanner[]
}

const initState: State = {
    categories: [],
    banners: [],
}

export const callGetCategories = createAsyncThunk("HOME.GET_CATEGORIES", async (_, thunkApi) => {
    try {
        return await Home.getCategories()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callBanners = createAsyncThunk("HOME.GET_BANNERS", async (_, thunkApi) => {
    try {
        return await Home.getBanners()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const homeReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetCategories.fulfilled, (state, { payload }) => {
            state.categories = payload?.data
        })
    builder
        .addCase(callBanners.fulfilled, (state, { payload }) => {
            state.banners = payload?.data
        })

})
