import CategoriesApi from "@api/Categories"
import { ICategoryCriterias } from "@domain/Categories"
import { UseQueryResult, useQuery } from "react-query"

export default function useCategories(criterias?: ICategoryCriterias, queryConfig?: UseQueryResult) {
    return useQuery({
        queryKey: ["fetch-categories", criterias],
        queryFn: async ({ queryKey }) => {
            //@ts-ignore
            const response: AxiosResponse = await CategoriesApi.getAllCategories(queryKey[1])
            // console.log(response)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: !!criterias,
        ...queryConfig,
    }) as UseQueryResult
}
