import appConfig from '@config/app'
import React from 'react'

export const SuccessRegister = () => {
    return <div className="bg-[#ffffff] h-full flex flex-row justify-center items-center">
        <div className="shrink-0 h-[399.42px] relative mb-20">
            <div className="flex flex-col gap-10 items-center justify-start ">
                <div className="flex flex-col gap-[26px] items-center justify-start shrink-0 relative">
                    <div className="flex flex-col gap-8 items-center justify-start shrink-0 relative">
                        <svg
                            className="shrink-0 relative overflow-visible"
                            style={{}}
                            width="120"
                            height="94"
                            viewBox="0 0 120 94"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M58.295 93.73C73.045 93.73 85.0022 81.7728 85.0022 67.0228C85.0022 52.2729 73.045 40.3157 58.295 40.3157C43.5451 40.3157 31.5879 52.2729 31.5879 67.0228C31.5879 81.7728 43.5451 93.73 58.295 93.73Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M71.4443 55.7502C71.7809 56.0256 66.7924 62.7381 60.3044 70.736L56.3667 75.5102L55.826 76.1631L55.2547 75.5714C50.154 70.3381 46.7162 66.4616 46.961 66.2168C47.2058 65.972 51.0925 69.4302 56.3156 74.5003L55.2139 74.6125L59.07 69.7873C65.5581 61.7384 71.0974 55.4748 71.4443 55.7502Z"
                                fill="white"
                            />
                            <path
                                d="M96.2335 56.2502C95.4378 53.4449 91.7959 53.7203 91.7959 53.7203L91.8877 55.6688C93.9994 55.3015 95.3052 57.7703 95.3052 57.7703L96.2335 56.2502Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M18.8668 62.1465C18.0813 59.3411 14.4292 59.6063 14.4292 59.6063L14.5312 61.565C16.6429 61.1977 17.9487 63.6665 17.9487 63.6665L18.8668 62.1465Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M61.4778 23.3304C65.7113 24.6157 68.3432 19.7395 68.3432 19.7395L65.7725 18.2705C64.5075 21.2595 60.2842 20.9127 60.2842 20.9127L61.4778 23.3304Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M38.0043 28.584C36.0354 28.7982 35.8008 31.2669 35.8008 31.2669L37.1167 31.42C37.1167 29.9714 38.9224 29.3797 38.9224 29.3797L38.0043 28.584Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M119.146 46.6608C119.431 44.7022 117.105 43.8555 117.105 43.8555L116.626 45.0898C118.034 45.4469 118.166 47.3443 118.166 47.3443L119.146 46.6608Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M49.4909 2.80537C49.7766 0.846712 47.4507 0 47.4507 0L46.9712 1.24457C48.379 1.60161 48.5116 3.48886 48.5116 3.48886L49.4909 2.80537Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M0.851099 34.3989C0.504253 37.4593 4.56439 38.2142 4.56439 38.2142L5.29889 36.1739C2.86077 35.9495 2.51391 33.1135 2.51391 33.1135L0.851099 34.3989Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M91.3984 23.2693C93.3774 23.3815 94.0099 20.9842 94.0099 20.9842L92.7347 20.6169C92.5001 22.0451 90.623 22.341 90.623 22.341L91.3984 23.2693Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M102.201 31.3283C101.671 31.477 101.118 31.5288 100.569 31.4813C100.02 31.53 99.4672 31.4781 98.937 31.3283C100.014 31.1106 101.124 31.1106 102.201 31.3283Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M103.936 26.8906C104.14 27.9829 104.14 29.1036 103.936 30.1959C103.718 29.1049 103.718 27.9816 103.936 26.8906Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M105.772 31.2159C106.302 31.0672 106.855 31.0153 107.404 31.0629C107.953 31.0142 108.506 31.0661 109.036 31.2159C107.959 31.4335 106.849 31.4335 105.772 31.2159Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M104.048 35.6638C103.83 34.5694 103.83 33.4428 104.048 32.3484C104.252 33.4441 104.252 34.5681 104.048 35.6638Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M106.68 33.8072C106.619 33.8582 106.303 33.593 105.976 33.2155C105.65 32.8381 105.456 32.4708 105.517 32.4198C105.578 32.3688 105.905 32.6238 106.221 33.0115C106.537 33.3991 106.751 33.695 106.68 33.8072Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M106.527 28.7065C106.588 28.778 106.374 29.0942 106.037 29.4308C105.7 29.7675 105.384 29.9919 105.323 29.9307C105.262 29.8695 105.476 29.5431 105.813 29.2064C106.149 28.8698 106.465 28.7065 106.527 28.7065Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M102.793 30.002C102.732 30.0734 102.426 29.9102 102.11 29.6449C101.794 29.3797 101.59 29.1145 101.641 29.0533C101.692 28.9921 101.998 29.1451 102.314 29.4001C102.63 29.6551 102.844 29.9408 102.793 30.002Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M102.64 32.2259C102.712 32.2769 102.518 32.5931 102.212 32.9298C101.906 33.2664 101.61 33.4807 101.549 33.4195C101.488 33.3582 101.671 33.042 101.977 32.7156C102.283 32.3891 102.579 32.1647 102.64 32.2259Z"
                                fill="#E0E0E0"
                            />
                            <path
                                d="M13.3274 22.2286C12.2502 22.4462 11.1403 22.4462 10.063 22.2286C10.5934 22.0799 11.1463 22.028 11.6952 22.0756C12.244 22.0269 12.7972 22.0787 13.3274 22.2286Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M15.0617 17.791C15.2794 18.8854 15.2794 20.012 15.0617 21.1065C14.844 20.012 14.844 18.8854 15.0617 17.791Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M16.9082 22.1266C17.9855 21.909 19.0954 21.909 20.1726 22.1266C19.0954 22.3442 17.9855 22.3442 16.9082 22.1266Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M15.1735 26.5643C14.9558 25.4733 14.9558 24.35 15.1735 23.259C15.3912 24.35 15.3912 25.4733 15.1735 26.5643Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M17.8159 24.6259C17.7445 24.6871 17.4282 24.4219 17.112 24.0342C16.7957 23.6466 16.5815 23.2997 16.6529 23.2385C16.7243 23.1773 17.0304 23.4527 17.3568 23.8302C17.6833 24.2076 17.8771 24.6259 17.8159 24.6259Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M17.6629 19.6273C17.7241 19.6886 17.4997 20.015 17.1733 20.3516C16.8468 20.6883 16.5204 20.9025 16.4592 20.8413C16.3979 20.7801 16.6122 20.4638 16.9488 20.1272C17.2855 19.7906 17.6017 19.5253 17.6629 19.6273Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M13.9191 20.9127C13.8681 20.9739 13.5621 20.8209 13.2458 20.5557C12.9296 20.2905 12.7154 20.0252 12.7766 19.9538C12.8378 19.8824 13.1336 20.0456 13.4499 20.3109C13.7661 20.5761 13.9803 20.8413 13.9191 20.9127Z"
                                fill="#FF6000"
                            />
                            <path
                                d="M13.7762 23.1264C13.8374 23.1876 13.6436 23.5039 13.3478 23.8405C13.0519 24.1772 12.7459 24.3914 12.6745 24.3302C12.6031 24.269 12.8071 23.9528 13.1029 23.6263C13.3988 23.2999 13.7048 23.0652 13.7762 23.1264Z"
                                fill="#FF6000"
                            />
                        </svg>

                        <div
                            className="text-[#f9471b] text-left relative"
                            style={{ font: "500 24px 'Roboto', sans-serif" }}
                        >
                            Đăng ký Thành Công{" "}
                        </div>
                    </div>
                    <div
                        className="text-text-color-text-secondary-2 text-center relative w-[452px]"
                        style={{ font: "400 17px 'Roboto', sans-serif" }}
                    >
                        Bây giờ bạn đã trở thành một phần của chúng tôi và sẵn sàng trải
                        nghiệm tất cả các tính năng và dịch vụ mà chúng tôi cung cấp.{" "}
                    </div>
                </div>
                <a href={appConfig.loginUrl} rel="noreferrer">
                    <div className="bg-[#ff6000] pt-px pr-[21px] pb-px pl-[21px] flex flex-row gap-0 items-start justify-start shrink-0 h-10 relative">
                        <div
                            className="text-[#ffffff] text-center relative flex items-center justify-center"
                            style={{ font: "400 14px/38px 'Roboto', sans-serif" }}
                        >
                            Đăng nhập ngay{" "}
                        </div>
                    </div>
                </a>

            </div>
        </div>
    </div>
} 