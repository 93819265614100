import React, { useEffect } from "react"
import { Banner } from "./components/Banner"
import { BannerLogin } from "./components/BannerLogin"
import { Menu } from "./components/Menu"
import { Products } from "./components/Products"
import { Services } from "./components/Services"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callBanners, callGetCategories } from "@reducer/home.reducer"
import { useAppSelector } from "@hook/useAppSelector"
import { MenuShow } from "./components/MenuShow"
import { ProductSuggest } from "./components/ProductSuggest"
import DefaultLayout from "@component/Layout/Default"
export const Home = () => {
    const dispatch = useAppDispatch()
    const categories = useAppSelector((state) => state.home.categories)
    useEffect(() => {
        dispatch(callGetCategories())
        dispatch(callBanners())
    }, [dispatch])
    return (
        <div className="relative">
            <DefaultLayout>
                <MenuShow />

                <div className="flex flex-row w-[1200px] mx-auto gap-4 mb-6 mt-6">
                    <Menu />
                    <Banner />
                    <BannerLogin />
                </div>
                {[...categories].splice(0, 2).map((e, index) => {
                    return (
                        <Products
                            key={e.code}
                            category={e}
                            type={index + 1}
                        />
                    )
                })}
                <ProductSuggest />
                <Services />
            </DefaultLayout>
        </div>
    )
}
