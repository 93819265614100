import bot from "@resources/images/flag/bot.png"
import React, { useEffect } from "react"
import { Questions } from "./Questions"
import localStore from "@util/LocalStore"
import { useNavigate } from "react-router-dom"
import appConfig from "@config/app"

export const BannerLogin = () => {
    const [isLogin, setIsLogin] = React.useState(false)
    useEffect(() => {
        const loggedUser = localStore.getJson("loginSession");
        if (loggedUser) {
            setIsLogin(true)
        }
    }, [])
    const navigate = useNavigate()

    return (
        <div className="flex flex-col flex-1 gap-4">
            <div className="flex h-[128px] flex-col gap-[10px] border border-primary rounded-lg p-[18px] bg-[#F0E5DA]">
                <div className=" flex flex-row gap-3">
                    <img
                        alt="bot"
                        className=" w-[54px] h-[54px]"
                        src={bot}
                    />
                    <div className=" flex flex-col gap-2 ">
                        <div className="text-sm  text-textPrimary font-normal">Sáng ra em gửi lời chào!!</div>
                        <div className="text-sm rounded-[10px] bg-[#FFEBE7] w-fit  text-primary  px-2">1688</div>
                    </div>
                </div>
                {!isLogin && (
                    <div className=" flex flex-row gap-3">
                        <button
                            onClick={() => {
                                window.location.href = appConfig.loginUrl ?? "";
                            }}
                            className="flex-1 h-[28px] text-sm font-normal rounded  text-white bg-primary">Đăng nhập</button>
                        <button onClick={() => {
                            navigate("/register");
                        }} className="flex-1 h-[28px] text-sm font-normal rounded  border border-primary text-primary bg-white">Đăng ký</button>
                    </div>
                )}

            </div>
            <img
                alt=""
                className=" w-full  h-[96px] object-cover rounded "
                src="https://img.alicdn.com/imgextra/i4/O1CN01i6ojRX22ZQP3h8GQx_!!6000000007134-2-tps-600-256.png_.webp"
            />
            <Questions />
        </div>
    )
}
