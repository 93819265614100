import React from "react"
import numeral from "numeral"
import { useNavigate } from "react-router-dom"
export const BottomBar = ({ totalPrice }: { totalPrice: number }) => {
    const navigate = useNavigate()

    return (
        <div className="sticky bottom-0   z-[1000]  mx-auto w-[1200px] shadow-2xl    ">
            <div className=" w-full flex flex-row items-center  justify-between shadow-2xl bg-white pl-8 pr-0 ">
                <div
                    onClick={() => navigate(-1)}
                    className=" text-xs text-textPrimary group hover:text-textPrimary  cursor-pointer flex flex-row gap-2 items-center font-light">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none">
                        <path
                            d="M10.7266 5.5C10.6953 5.84375 10.5078 6.03125 10.1641 6.0625H2.21875L5.33594 9.03906C5.55469 9.28906 5.55469 9.55469 5.33594 9.83594C5.08594 10.0547 4.82031 10.0547 4.53906 9.83594L0.414062 5.89844C0.304688 5.78906 0.25 5.65625 0.25 5.5C0.25 5.34375 0.304688 5.21094 0.414062 5.10156L4.53906 1.16406C4.82031 0.945313 5.08594 0.945313 5.33594 1.16406C5.55469 1.44531 5.55469 1.71094 5.33594 1.96094L2.21875 4.9375H10.1875C10.5156 4.96875 10.6953 5.15625 10.7266 5.5Z"
                            fill="#7D7E7E"
                            className="fill-[#7D7E7E] group-hover:fill-textPrimary "
                        />
                    </svg>
                    Quay lại giỏ hàng
                </div>
                <div className=" flex flex-row items-center h-full">
                    <div className=" text-xs text-secondary2 ml-[22px]">
                        Tổng tiền thanh toán (bao gồm cả phí vận chuyển)：
                        <span className="text-primary  font-medium text-base">{numeral(totalPrice).format("0,0").replace(/,/g, ".")}đ</span>
                    </div>

                    <button className=" ml-8  w-[135px] bg-primary h-[60px] hover:bg-buttonPrimary px-6 py-2 text-base  font-normal text-white">
                        Đặt Hàng
                    </button>
                </div>
            </div>
        </div>
    )
}
