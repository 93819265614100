import { Search } from '@component/Search'
import React from 'react'
interface Props {
    loading?: boolean
    showSearch?: boolean
}

export const SearchHeader = ({ showSearch = true }: Props) => {
    return <div className="bg-[#ffffff] pt-[30px] pr-[360px] pb-[25.61px] pl-[360px] flex flex-row gap-0 items-center justify-center sticky top-0 z-[1000]">
        <div className="flex flex-row gap-[53px] items-end justify-start shrink-0 relative">
            <div className="shrink-0 w-[180px] h-[47px] static cursor-pointer" onClick={() => window.location.href = "/"}>
                <div className="w-[180px] h-[47px] static">
                    <div className="flex flex-row gap-[5px] items-center justify-start absolute left-0 top-0">
                        <div
                            className="text-[#F9471B] text-left relative"
                            style={{ font: "900 40px 'Roboto', sans-serif" }}
                        >
                            LOGO
                        </div>
                        <div className="flex flex-col gap-1 items-start justify-start shrink-0 relative">
                            <div
                                className="text-[#bebebe] text-left relative flex items-center justify-start"
                                style={{ font: "700 12px 'Roboto', sans-serif" }}
                            >
                                lOREM
                            </div>
                            <div
                                className="text-[#bebebe] text-left relative flex items-center justify-start"
                                style={{ font: "700 12px 'Roboto', sans-serif" }}
                            >
                                IPSUM NATO
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showSearch ? <Search /> : <div className='flex flex-row items-center justify-between shrink-0 w-[800px] relative h-full'></div>}

        </div>
    </div>
}