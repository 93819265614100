import React, { PropsWithChildren, useEffect } from "react"
import { Layout } from "antd"

import { useAppSelector } from "@hook/useAppSelector"
import { HeaderTop } from "@component/HeaderTop/HeaderTop"

interface Props extends PropsWithChildren<any> {
    loading?: boolean
    containerLogoStyle?: string
}

export const CardLayout = (props: Props) => {
    // const [collapsed, setCollapsed] = useState(false)
    const title = useAppSelector((state) => state.common.pageTitle)

    useEffect(() => {
        document.title = title
    }, [title])

    return (
        <Layout className={"h-screen overflow-auto relative bg-[#F7F7F7]"}>
            <HeaderTop />
            <div className={props.containerLogoStyle}>
                <div className={" w-[1200px]  mx-auto  flex flex-row  items-center h-[100px]"}>
                    <img
                        onClick={() => (window.location.href = "/")}
                        className=" w-[180px] h-[47px] cursor-pointer"
                        src={require("src/resources/images/flag/logo.png")}
                    />
                </div>
            </div>
            {props.children}
        </Layout>
    )
}

export default CardLayout
