import { ICategoryView } from "@domain/Categories"
import { getSearchParamsValues } from "@screen/CategoriesPage/utilities"
import React from "react"
import { useSearchParams } from "react-router-dom"

function CategoryItem({ item, fakeImg }: { item: ICategoryView | any, fakeImg?: string }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedCategory = getSearchParamsValues(searchParams)["code"]

    const handleSelectCategory = () => {
        const currentP = {...getSearchParamsValues(searchParams)}
        const temp:any = {...currentP}
        if(!currentP.parent) {
            temp.parent = currentP.code
        }
        else if(decodeURIComponent(currentP.parent).split('&&').length > 1) {
            // console.log('chay vao day')
        } else {
            temp.parent = encodeURIComponent(decodeURIComponent(currentP.parent) + '&&' + decodeURIComponent(currentP.code))
        }
        temp.code = encodeURIComponent(item.code);
        temp.cateLevel = item.level;
        setSearchParams({
            ...temp
        })
    }

    return (
        <React.Fragment>
            <div
                className={`flex flex-col items-center gap-2 _cate-item cursor-pointer ${decodeURIComponent(selectedCategory) === item.code ? "_cate-item_selected" : ""}`}
                onClick={() => {
                    handleSelectCategory()
                }}>
                <div className="h-[60px] w-[60px] bg-cover bg-center" style={{backgroundImage: `url(${item?.image || fakeImg})`}}>

                </div>

                <a className="flex justify-center whitespace-normal font-normal text-[#666666] hover:text-[#F43314] text-center cursor-pointer capitalize">
                    {item?.name}
                </a>
            </div>
        </React.Fragment>
    )
}

export default CategoryItem
