import CategoriesApi from "@api/Categories"
import { IBanner } from "@domain/Home"
import { Carousel } from "antd"
import React, { useEffect, useState } from "react"

function CategoryBanners() {
    const [banners,setBanners] = useState<IBanner[] | any>()

    useEffect(() => {
        const fetchCategoryBanners = async() => {
            try {
                const response = await CategoriesApi.getCategoriyBanners({type: 'category'});
                if(response && response.data) setBanners(response.data)
            }
            catch(error) {
                console.log(error)
            }
        }
        fetchCategoryBanners();
    }, [])

    const handleClickBanner = (url:string) => {
        // console.log(url)'
        if(url) window.open(url)
    }

    return (
        <React.Fragment>
            <div className="ml-5 mb-3 category-banner rounded-md">
                <Carousel
                    autoplay
                    swipeToSlide
                    draggable
                    className=""
                    // dots={false}
                    dotPosition="bottom"
                    >
                    {banners?.map((e: any) => {
                        return (<span key={e.id} >
                                <div className={`${e.url ? 'cursor-pointer' : ''} c-image`}
                                     style={{backgroundImage: `url(${e.image})`}} 
                                    onClick={()=> handleClickBanner(e.url)}
                                >
                                </div>
                            </span>
                        )
                    })}
                </Carousel>
            </div>
        </React.Fragment>
    )
}

export default CategoryBanners
