import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import CommonApi from "@api/Common"
import { IUserInfo } from "@domain/Common"

interface State {
    pageTitle: string
    userInfo: IUserInfo
    m1Session: any
}

const initState: State = {
    pageTitle: "Common",
    userInfo: {},
    m1Session: {},
}

export const changePageTitleAction = createAction<string>("CHANGE_PAGE_TITLE")

export const callGetAuthUserInfo = createAsyncThunk("COMMON.GET_USER", async (_, thunkApi) => {
    try {
        return await CommonApi.getAuthUserInfo()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const commonReducer = createReducer(initState, (builder) => {
    builder.addCase(changePageTitleAction, (state, action: PayloadAction<string>) => {
        state.pageTitle = action.payload
    })

    builder
        .addCase(callGetAuthUserInfo.pending, (state) => {
            state.userInfo = {}
        })
        .addCase(callGetAuthUserInfo.fulfilled, (state, { payload }) => {
            state.userInfo = payload?.data
        })
        .addCase(callGetAuthUserInfo.rejected, (state) => {
            state.userInfo = {}
        })
})

export const getM1Session = createAsyncThunk("COMMON.GET_M1_SESSION", async (_, thunkApi) => {
    try {
        return await CommonApi.getM1Token()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const m1SessionReducer = createReducer(initState, (builder) => {
    builder
    .addCase(getM1Session.pending, (state) => {
        state.m1Session = {}
    })
    .addCase(getM1Session.fulfilled, (state, { payload }) => {
        state.m1Session = payload?.data
    })
    .addCase(getM1Session.rejected, (state) => {
        state.m1Session = {}
    })
    
})
