import DefaultLayout from '@component/Layout/Default';
import { Button, Checkbox, Form, FormInstance, Input, Space, Tooltip, message } from 'antd';
import React, { useEffect } from 'react';
import CountryPhoneInput from '@component/PhoneInput';
import { randomEmail } from '@util/Common';
import RegisterApi from '@api/Account';
import appConfig from '@config/app';
import { SuccessRegister } from './components/success';
import localStore from '@util/LocalStore';

const tailLayout = {
    wrapperCol: { offset: 8, span: 24 },
};
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export const Register = () => {
    const formRef = React.useRef<FormInstance>(null);
    const [check, setCheck] = React.useState(false);
    const [checkStatus, setCheckStatus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [phoneData, setPhone] = React.useState<{ phone?: string, code?: number, short?: string }>();
    const [isSuccess, setSuccess] = React.useState(false);
    useEffect(() => {
        document.title = "Đăng Ký Tài Khoản"
        const loggedUser = localStore.getJson("loginSession");
        if (loggedUser) {
            window.location.href = "/";
        }
    }, []);
    useEffect(() => {
        if (checkStatus && check) {
            setCheckStatus(false);
        }
    }, [check, checkStatus]);

    const onFinish = (values: any) => {
        if (!check) {
            setCheckStatus(true);
            return;
        }
        const validPhone = values?.phone.charAt(0) === '0' ? values?.phone.substring(1) : values?.phone;
        const data = {
            ...values,
            email: randomEmail({ domain: 'gobiz.com' }),
            phone: "+" + phoneData?.code + validPhone
        };
        setLoading(true);
        RegisterApi.register(data).then((res) => {
            console.log(res);
            // message.success('Đăng ký thành công');
            // window.location.href = appConfig.loginUrl ?? "";
            setSuccess(true);
        }).catch((err) => {
            console.log("err", err);
            if (err?.response?.data?.title === "duplicate_user") {
                message.error('Tài khoản đã tồn tại, vui lòng thử lại!');
            } else {
                message.error('Đăng ký thất bại!');
            }

        }).then(() => {
            setLoading(false);
        });
        console.log(data);
    };

    const onFinishFailed = (errorInfo: any) => {
        if (!check) {
            setCheckStatus(true);
            return;
        }
        console.log('Failed:', errorInfo);
    };

    return (
        <DefaultLayout showSearch={false} showFooter={false} showTopMenu={false}>
            {isSuccess ? <SuccessRegister /> :
                <div className=' bg-white h-full flex  flex-col justify-center'>

                    <div className='flex justify-center' style={{ marginLeft: -60 }}>
                        <div
                            className="text-text-color-text-primary text-left ml-[60px] mb-[24px]"
                            style={{ font: "500 24px 'Roboto', sans-serif" }}
                        >
                            Đăng ký tài khoản{" "}
                        </div>
                    </div>
                    <div className='flex justify-center pb-[50px]' style={{ marginLeft: -50 }}>
                        <Form
                            {...layout}
                            ref={formRef}
                            name="control-ref"
                            onFinish={onFinish}
                            className=''
                            onFinishFailed={onFinishFailed}
                        >
                            <div className='w-full relative'>
                                <Form.Item name="username" label="Username" rules={[{ required: true, message: "Username là bắt buộc" },
                                { pattern: /^(?!.*[_.]{2})[a-zA-Z0-9]+(?<![_.])$/, message: "Tài khoản chứa ký tự không hợp lệ, vui lòng nhập lại" }, {
                                    min: 6,
                                    message: "Tài khoản phải có ít nhất 6 ký tự",
                                }, {
                                    max: 20,
                                    message: "Tài khoản phải có tối đa 20 ký tự",
                                }]}>
                                    <Space>
                                        <Tooltip
                                            overlayInnerStyle={{
                                                color: "#7d7e7e", font: "400 12px/150% 'Roboto', sans-serif", borderRadius: 8,
                                                paddingLeft: 16, paddingRight: 16, paddingTop: 12, paddingBottom: 12,
                                                lineHeight: "150%"
                                            }}
                                            color="white" trigger="focus" title="6-20 kí tự, chỉ nhập chữ latin và số" placement="right">
                                            <Input
                                                disabled={loading}
                                                autoFocus
                                                className='!w-[300px] h-[40px]' width={300} placeholder='6-20 ký tự chỉ nhập chữ latin và số' />
                                        </Tooltip>
                                    </Space>
                                </Form.Item>
                            </div>

                            <Form.Item name="password" label="Mật khẩu" rules={[{ required: true, message: "Mật khẩu là bắt buộc" },
                            { pattern: /^(?!.*[_.]{2})[a-zA-Z0-9!@#$%^&*_]+$/, message: "Mật khẩu chứa ký tự không hợp lệ, vui lòng nhập lại" },
                            { min: 6, message: "Mật khẩu phải có ít nhất 6 ký tự" },
                            { max: 32, message: "Mật khẩu phải có tối đa 32 ký tự" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || !value.includes(getFieldValue('username'))) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Mật khẩu không được chứa tên đăng nhập!'));
                                },
                            }),

                            ]}>
                                <Space>
                                    <Tooltip color="white"

                                        overlayInnerStyle={{
                                            color: "#7d7e7e", font: "400 12px/150% 'Roboto', sans-serif", borderRadius: 8,
                                            paddingLeft: 16, paddingRight: 16, paddingTop: 12, paddingBottom: 12,
                                            lineHeight: "150%"
                                        }} title="Yêu cầu mật khẩu: 6-32 ký tự, ko chứa tên đăng nhập
                                        Chỉ được phép bao gồm các ký tự, chữ số, ký hiệu đặc biệt (không bao gồm dấu cách)"
                                        trigger="focus"
                                        placement="right">
                                        <Input.Password
                                            disabled={loading}
                                            onChange={(e) => {
                                                if (formRef.current?.getFieldError("confirmPassword")[0] === "Mật khẩu của bạn nhập không khớp!" && e.target.value === formRef.current?.getFieldValue("confirmPassword")) {
                                                    formRef.current?.validateFields(["confirmPassword"])
                                                    return;
                                                }
                                                if (formRef.current?.getFieldError("confirmPassword").length === 0 && e.target.value !== formRef.current?.getFieldValue("confirmPassword") && formRef.current?.getFieldValue("confirmPassword")) {
                                                    formRef.current?.validateFields(["confirmPassword"])
                                                    return;
                                                }
                                            }}
                                            className='!w-[300px] h-[40px]' placeholder='6-32 ký tự, không chứa tên đăng nhập' />
                                    </Tooltip>
                                </Space>

                            </Form.Item>
                            <Form.Item
                                name="confirmPassword" label="Xác nhận mật khẩu" rules={[{ required: true, message: "Xác nhận mật khẩu là bắt buộc" }, ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Mật khẩu của bạn nhập không khớp!'));
                                    },
                                }),]}>
                                <Input.Password
                                    disabled={loading}
                                    className='!w-[300px] h-[40px]' placeholder='Nhập lại mật khẩu' width={300} />
                            </Form.Item>

                            <Form.Item name="phone" label="Số điện thoại" rules={[{ required: true, message: "Số điện thoại là bắt buộc" },
                            { pattern: /([3|5|7|8|9])+([0-9]{8})\b/g, message: "Vui lòng nhập đúng số điện thoại!" }]} >
                                <Space>
                                    <CountryPhoneInput
                                        disabled={loading}
                                        onChange={(value) => {
                                            setPhone(value);
                                        }} className='!w-[300px] !h-[40px]'
                                        inline
                                    />
                                </Space>

                            </Form.Item>

                            <div className='ml-[139px] flex flex-row flex-start pb-[16px]'>
                                <Checkbox checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                <div className='mt-5 ml-2'>
                                    <div className="flex flex-col items-start justify-start shrink-0 relative">
                                        <div
                                            className="text-text-color-text-primary text-left relative flex justify-start"
                                            style={{ font: "400 12px/18px 'Roboto', sans-serif", marginTop: -20 }}
                                        >
                                            Bạn tích vào đây có nghĩa là bạn đồng ý với：{" "}
                                        </div>

                                    </div>
                                    <div
                                        className="flex flex-row gap-0 items-start justify-start shrink-0 relative"
                                        style={{ margin: "0px 0 0 0" }}
                                    >
                                        <div
                                            className="text-left relative flex items-center justify-start"
                                            style={{ font: "400 11px/18px 'Roboto', sans-serif" }}
                                        >
                                            <span
                                                style={{ font: "400 12px/18px 'Roboto', sans-serif" }}>
                                                <a rel="noreferrer"
                                                    style={{ font: "400 12px/18px 'Roboto', sans-serif" }}
                                                    target="_blank" href='http://rule.1688.com/policy/terms.htm?spm=a211eg.10357043.0.0.1a7434dcTbTsa6&tracelog=aliguize_hyjl' className="text-[#F9471B]">Chính sách </a>
                                                <span style={{ font: "400 12px/18px 'Roboto', sans-serif" }}>và </span>
                                                <a rel="noreferrer"
                                                    style={{ font: "400 12px/18px 'Roboto', sans-serif" }}
                                                    target="_blank" href="https://rule.1688.com/policy/privacy.html?spm=a211eg.10357043.0.0.1a7434dcTbTsa6" className=" text-[#F9471B]">Điều khoản chúng tôi</a>
                                            </span>{" "}
                                        </div>
                                    </div>
                                    {checkStatus ? <div className='text-[#ea2a3f]' style={{ font: "400 12px/18px 'Roboto', sans-serif", transform: 'translateX(-10%)' }}>Bạn cần đồng ý với điều khoản</div> : null}
                                </div>

                            </div>

                            <Form.Item {...tailLayout}>
                                <div className='flex flex-row w-[300px]'>
                                    <Button disabled={loading} type="primary" htmlType="submit">
                                        Đăng ký
                                    </Button>
                                    <div
                                        className="text-left relative flex items-center justify-start ml-2"
                                        style={{ font: "400 12px/18px 'Roboto', sans-serif" }}
                                    >
                                        <span>
                                            <span className="text-text-color-text-primary">
                                                Tôi đã có tài khoản{" "}
                                            </span>
                                            <span onClick={() => {
                                                window.location.href = appConfig.loginUrl ?? "";
                                            }} className="cursor-pointer text-[#F9471B]">
                                                Đăng Nhập Ngay
                                            </span>
                                        </span>{" "}
                                    </div>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            }
        </DefaultLayout >
    );
};
