import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';

export const TextSearch = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (searchParams.get('keyword')) {
            setValue(searchParams.get('keyword') || '')
        }
    }, [searchParams])
    const onChangeValue = (e: { target: { value: string } }) => {
        setValue(e.target.value)

    }
    const onSearch = () => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            keyword: value
        })
    }
    const handleKeyDown = (e: { key: string }) => {
        if (e.key === 'Enter') {
            onSearch()
        }
    }

    const [value, setValue] = React.useState('')
    return <div className='bg-white rounded'>
        <div className="border-solid border-[#f4f4f4] border-b pt-3.5 pr-[944px] pb-[7px] pl-6 flex flex-row gap-[60px] items-start justify-start self-stretch shrink-0 relative">
            <div className="pt-1.5 pb-1.5 flex flex-col gap-0 items-start justify-start shrink-0 relative">
                <div
                    className="text-text-color-text-primary text-left relative flex items-center justify-start"
                    style={{ font: "600 12px/18px 'Roboto', sans-serif" }}
                >
                    Tìm kiếm{" "}
                </div>
            </div>
            <div className="bg-[#ff4000] rounded-[3px] border-solid border-[#ff4000] border p-px flex flex-row gap-0 items-start justify-start shrink-0 relative">
                <div className="bg-[#ffffff] rounded-tl-[3px] rounded-bl-[3px] pt-[5.5px] pr-3 pb-1.5 pl-3 flex flex-col gap-0 items-start justify-start self-stretch flex-1 relative">
                    <div className="flex flex-col gap-0 items-start justify-center shrink-0 w-[228px] h-[16.5px] relative overflow-hidden">
                        <input value={value} onChange={onChangeValue} onKeyDown={handleKeyDown} placeholder='Nhập từ khóa' className='focus:outline-none grow w-[228px] text-xs text-[#222222]' />
                    </div>
                </div>
                <div className="px-4 flex flex-col gap-0 items-center justify-start shrink-0 relative cursor-pointer" onClick={onSearch}>
                    <div
                        className="text-[#ffffff] text-center relative flex items-center justify-center"
                        style={{ font: "400 12px/28px 'Roboto', sans-serif" }}
                    >
                        Tìm kiếm{" "}
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="border-solid border-[#f4f4f4] border-b self-stretch shrink-0 h-[41px] relative">
            <div
                className="text-text-color-text-primary text-left absolute left-6 top-[11.5px] flex items-center justify-start"
                style={{ font: "600 12px/18px 'Roboto', sans-serif" }}
            >
                Thể loại{" "}
            </div>
            <div className="pr-[754px] flex flex-row gap-0 items-start justify-start max-h-[30px] absolute right-[134px] left-[134px] top-[5px] overflow-hidden">
                <div className="pt-1.5 pr-[34px] pb-1.5 pl-2.5 flex flex-col gap-0 items-start justify-start shrink-0 w-[92px] max-w-[110px] relative overflow-hidden">
                    <div
                        className="text-[#ff4000] text-left relative flex items-center justify-start"
                        style={{ font: "600 12px/18px 'Roboto', sans-serif" }}
                    >
                        Áo{" "}
                    </div>
                </div>
                <div className="pt-1.5 pr-[58px] pb-1.5 pl-2.5 gap-0 items-start justify-start shrink-0  relative overflow-hidden">
                    <div
                        className="text-text-color-text-secondary text-left  justify-start"
                        style={{ font: "400 12px/18px 'Roboto', sans-serif" }}
                    >
                        Phụ kiện
                    </div>
                </div>
                <div className="pt-1.5 pr-[34px] pb-1.5 pl-2.5 flex flex-col gap-0 items-start justify-start shrink-0 w-[92px] max-w-[110px] relative overflow-hidden">
                    <div
                        className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                        style={{ font: "400 12px/18px 'Roboto', sans-serif" }}
                    >
                        Giày{" "}
                    </div>
                </div>
                <div className="pt-1.5 pr-[34px] pb-1.5 pl-2.5 flex flex-col gap-0 items-start justify-start shrink-0 w-[92px] max-w-[110px] relative overflow-hidden">
                    <div
                        className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                        style={{ font: "400 12px/18px 'Roboto', sans-serif" }}
                    >
                        Dép{" "}
                    </div>
                </div>
            </div>
        </div> */}
        {/* <div className="self-stretch shrink-0 h-[41px] relative">
            <div
                className="text-text-color-text-primary text-left absolute left-6 top-[11.5px] flex items-center justify-start"
                style={{ font: "600 12px/18px 'Roboto', sans-serif" }}
            >
                Đặc điểm{" "}
            </div>
            <div className="pr-[386px] flex flex-row gap-0 items-start justify-start max-h-[30px] absolute right-[134px] left-[134px] top-[5px] overflow-hidden">
                <div className="pt-1.5 pr-[19px] pb-1.5 pl-2.5 flex flex-col gap-0 items-start justify-start shrink-0 relative overflow-hidden">
                    <div
                        className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                        style={{ font: "400 12px/18px 'Roboto', sans-serif" }}
                    >
                        24 giao hàng{" "}
                    </div>
                </div>
                <div className="pt-1.5 pr-[22px] pb-1.5 pl-2.5 flex flex-col gap-0 items-start justify-start shrink-0 w-[92px] max-w-[110px] relative overflow-hidden">
                    <div
                        className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                        style={{ font: "400 12px/18px 'Roboto', sans-serif" }}
                    >
                        Gói tỉ mỉ{" "}
                    </div>
                </div>
                <div className="pt-1.5 pr-[34px] pb-1.5 pl-2.5 flex flex-col gap-0 items-start justify-start shrink-0 w-[92px] max-w-[110px] relative overflow-hidden">
                    <div
                        className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                        style={{ font: "400 12px/18px 'Roboto', sans-serif" }}
                    >
                        Ảnh HD{" "}
                    </div>
                </div>
                <div className="pt-1.5 pr-[34px] pb-1.5 pl-2.5 flex flex-col gap-0 items-start justify-start shrink-0 relative overflow-hidden">
                    <div
                        className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                        style={{ font: "400 12px/18px 'Roboto', sans-serif" }}
                    >
                        Chứng nhận{" "}
                    </div>
                </div>
            </div>
        </div> */}
    </div>
}