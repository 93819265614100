import { AddressCreateCommand } from "@domain/Address";
import { M1HttpClient } from "@util/M1ApiClient";

const  m1HttpClient = M1HttpClient.getInstance()

class AddressApi {
    static getLocation (criteria: LocationCriteria) {
        const m1HttpClient = M1HttpClient.getInstance();
        return m1HttpClient.get(`/locations`, {
            params: criteria
        })
    }

    static getAddresses (criteria: CommonCriteria) {
        return m1HttpClient.get(`/customer/addresses`, {
            params: criteria
        })
    }

    static createAddress (payload: AddressCreateCommand) {
        return m1HttpClient.post(`/customer/addresses`,payload)
    }

    static editAddress (id: string | number ,payload: AddressCreateCommand) {
        return m1HttpClient.patch(`/customer/addresses/${id}`,payload)
    }
}


export default AddressApi;

export interface CommonCriteria {
    page?: number
    size?: number
    sort?: string
}

export interface LocationCriteria extends CommonCriteria {
    type: "WARD" | "PROVINCE" | "DISTRICT"
    parent?: string | number
}