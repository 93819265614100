import { AddressView } from "@domain/Address"
import { Button, Radio } from "antd"
import React, { useEffect, useRef, useState } from "react"
import CreateAddressForm from "./CreateAddressForm"

interface ListAddressProps {
    addresses: AddressView[] | undefined
    getAddresses: () => void
}

function ListAddress(props: ListAddressProps) {
    const { addresses, getAddresses } = props
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showAddresses, setShowAddresses] = useState(false)
    const [selectedAddress, setSelectedAddress] = useState<AddressView | undefined>()
    const [addressDisplay, setAddressDisplay] = useState<AddressView | undefined>()
    const [createTemporaryAddress, setCreateTemporaryAddress] = useState(false)
    const [temporaryAddress, setTemporaryAddress] = useState<any>()

    // console.log(temporaryAddress)
    const listRef = useRef<any>(null)

    const scrollToTop = () => {
        // console.log(listRef)
        const lastChild = listRef?.current
        lastChild?.scrollIntoView({  behavior: "smooth" })
    }


    useEffect(() => {
        // console.log("temporary", temporaryAddress)
        if (!addresses || (addresses && addresses.length <= 0)) {
            setShowCreateModal(true)
        } else if (addresses) {
            const defaultAddress: any = addresses ? addresses?.find((a: AddressView) => a.defaultAddress) : undefined
            if (defaultAddress) {
                setSelectedAddress(defaultAddress)
                setAddressDisplay(defaultAddress)
            } else {
                setSelectedAddress(addresses[0])
                setAddressDisplay(addresses[0])
            }
        }
        return () => {
            console.log()
        }
    }, [addresses])

    useEffect(()=> {
        // console.log('chay vao day',selectedAddress)
        if(temporaryAddress) {
            setSelectedAddress(temporaryAddress)
            setAddressDisplay(temporaryAddress);
        } else {
            // setSelectedAddress(undefined)
        }
    },[temporaryAddress])

    const handleSelectAddress = (id: string) => {
        setShowCreateModal(false)
        if (addresses) {
            const address = addresses.find((a: AddressView) => a.id === id)
            if (address) {
                setSelectedAddress(address)
            }
        }
    }

    const handleCreateTemporary = (values: any) => {
        // console.log(values)
        const temp = { ...values }
        setAddressDisplay(temp)
        setTemporaryAddress(temp)
        setSelectedAddress(temp)
    }

    return (
        <React.Fragment>
            <div ref={listRef}>
                <div className="flex flex-col">
                    <div className="flex flex-col">
                        {((addressDisplay && !addresses?.find((a: any) => a.id === addressDisplay?.id)) || temporaryAddress) && (
                            <div className="text-[#999999] text-[12px] pt-[4px] pb-2">Địa chỉ tạm thời chỉ được sử dụng cho đơn hàng này và sẽ không được lưu.</div>
                        )}
                        {((addressDisplay && !showAddresses && !createTemporaryAddress) || temporaryAddress) && (
                            <div className="flex text-[12px] w-full mb-[14px] pt-[8px]">
                                <span className="font-medium">
                                    <i className="fa-regular fa-user mr-2 text-[#000000]"></i>
                                    {addressDisplay?.fullname}
                                </span>
                                <span className="ml-[24px]">
                                    <i className="fa-regular fa-location-dot mr-2 text-[#000000]"></i>
                                    {addressDisplay?.detail}
                                </span>
                                <span className="mx-1">{addressDisplay?.location?.display || addressDisplay?.temporaryDisplay}</span>
                                <span className="ml-4">
                                    <i className="fa-regular fa-phone mr-2 text-[#000000]"></i>
                                    {addressDisplay?.phone}
                                </span>
                                {!showCreateModal && selectedAddress?.id === temporaryAddress?.id && (
                                    <span className="ml-[24px] _address-actions">
                                        <span
                                            onClick={() => {
                                                setShowCreateModal(true)
                                                setSelectedAddress(addressDisplay)
                                                if (temporaryAddress) setShowAddresses(false)
                                            }}
                                            className="text-[#F9471B] cursor-pointer">
                                            <i className="fa-regular fa-pen-to-square mr-2"></i>Chỉnh sửa
                                        </span>
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    {!showAddresses && !createTemporaryAddress && addresses && addresses.length > 0 && !showCreateModal && (
                        <div className="flex gap-[14px] text-[12px] text-[#666666] cursor-pointer">
                            <span
                                className="hover:text-[#F9471B]"
                                onClick={() => {
                                    setShowAddresses(true)
                                }}>
                                Thay đổi
                            </span>
                            {addressDisplay && addresses.find((a: any) => a.id === addressDisplay?.id) && (
                                <span
                                    className="hover:text-[#F9471B]"
                                    onClick={() => {
                                        setSelectedAddress(undefined)
                                        setCreateTemporaryAddress(true)
                                    }}>
                                    Sử dụng địa chỉ tạm thời
                                </span>
                            )}
                        </div>
                    )}

                    {showAddresses && (
                        <>
                            <div className="mb-2 mt-[8px]">
                                <span className="text-[#999999] text-[12px]">Danh sách địa chỉ:</span>
                            </div>
                            <Radio.Group
                                value={selectedAddress?.id}
                                options={
                                    addresses &&
                                    addresses.map((add: AddressView) => ({
                                        label: (
                                            <div className="flex text-[12px] w-full ">
                                                <span>{add.fullname}, </span>
                                                <span className="ml-2 mx-1">{add.detail}</span>
                                                <span className="mx-1">{add.location.display}, </span>
                                                <span className="ml-2">{add.phone}</span>

                                                {selectedAddress?.id === add.id && !showCreateModal && (
                                                    <span className="ml-[24px] _address-actions">
                                                        <span
                                                            onClick={() => setShowCreateModal(true)}
                                                            className="text-[#F9471B]">
                                                            <i className="fa-regular fa-pen-to-square mr-2"></i>Chỉnh sửa
                                                        </span>
                                                    </span>
                                                )}
                                            </div>
                                        ),
                                        value: add.id,
                                    }))
                                }
                                className=""
                                onChange={(e) => {
                                    handleSelectAddress(e.target.value)
                                }}
                            />
                            {addresses && addresses.length < 10 && (
                                <Radio
                                    className="text-[12px]"
                                    checked={!selectedAddress}
                                    onChange={() => {
                                        setSelectedAddress(undefined)
                                        setShowCreateModal(true)
                                        setSelectedAddress(undefined)
                                    }}>
                                    Địa chỉ mới
                                </Radio>
                            )}

                            {!showCreateModal && (
                                <div className="flex gap-4 mt-[14px]">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="_accept-btn"
                                        onClick={() => {
                                            if (!addresses?.find((a: any) => a.id === selectedAddress?.id)) {
                                                return
                                            }
                                            setTemporaryAddress(undefined)
                                            setAddressDisplay(selectedAddress)
                                            setShowAddresses(false)
                                            // scrollToTop()
                                        }}>
                                        Xác nhận
                                    </Button>
                                    <button
                                        className="_cancel-btn"
                                        onClick={() => {
                                            setShowAddresses(false)
                                            setSelectedAddress(addressDisplay)
                                            // scrollToTop()
                                        }}>
                                        Huỷ bỏ
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
                {(showCreateModal || createTemporaryAddress) && (
                    <div className={`${(addresses && addresses.length < 0) && !selectedAddress && !createTemporaryAddress ? "mt-[24px]" : ""}`}>
                        <CreateAddressForm
                            editItem={selectedAddress}
                            callback={() => {
                                if (!createTemporaryAddress && !temporaryAddress) {
                                    // console.log('chay vao day')
                                    setSelectedAddress(addressDisplay)
                                    setShowAddresses(false)
                                    setShowCreateModal(false)
                                    getAddresses()
                                } else if (!addresses || addresses.length < 1) {
                                    return
                                } else if (temporaryAddress) {
                                    setSelectedAddress(temporaryAddress)
                                    setAddressDisplay(temporaryAddress)
                                    setShowCreateModal(false)
                                    setCreateTemporaryAddress(false)
                                } else {
                                    setSelectedAddress(addressDisplay)
                                    setShowAddresses(false)
                                    setShowCreateModal(false)
                                    setCreateTemporaryAddress(false)
                                }
                            }}
                            createTemporaryAddress={handleCreateTemporary}
                            isCreateTemporary={createTemporaryAddress}
                            addresses={addresses}
                            temporaryAdd={temporaryAddress}
                            scrollToTop={scrollToTop}
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default ListAddress
