import AddressComponent from '@component/Address'
import React from 'react'

function ComponentView() {
  return (
    <div>
        <AddressComponent />
    </div>
  )
}

export default ComponentView