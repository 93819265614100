import React from "react"
import { IAttribute, IProduct } from "@domain/Cart"
import numeral from "numeral"
export const ItemProduct = ({ product }: { product: IProduct }) => {
    return (
        <>
            {product.skus.map((sku) => {
                return (
                    <div
                        key={sku.id}
                        className=" flex flex-row relative   border-b-0   w-full   border-[#E2EDF8] border items-start">
                        <div className=" flex flex-row items-center gap-3">
                            <img
                                className=" h-[60px] w-[60px] object-cover m-3"
                                src={sku.images || require("src/resources/images/icons/sku-default.png")}
                            />
                        </div>
                        <div className=" flex flex-col w-[200px] py-3 gap-1 ">
                            <div
                                onClick={() => {
                                    window.open(`products/${encodeURIComponent(product.title)}/${product.itemId}`)
                                }}
                                className=" text-xs hover:text-primary text-textPrimary cursor-pointer">
                                {product.title}
                            </div>
                            <div className={"text-secondary flex flex-wrap gap-x-4 gap-y-1"}>
                                {JSON.parse(sku.attributes).map((e: IAttribute) => (
                                    <div
                                        className="text-xs text-secondary2 capitalize"
                                        key={e.value}>
                                        {e.name}: {e.value}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex-1  flex flex-row items-center h-full">
                            <div className=" flex-1 text-sm text-textPrimary justify-end  my-auto flex   truncate">
                                {numeral(sku.msrp).format("0,0").replace(/,/g, ".")}đ
                            </div>
                            <div className=" w-[130px] h-[28px]  my-auto  flex group flex-row border border-delivery ml-[50px]">
                                <button className="flex-1 relative border-r border-delivery  bg-[#F8F8F8] ">
                                    <div className=" absolute left-0 right-0 mx-auto  top-[-2px]">_</div>
                                </button>
                                <input
                                    className=" w-[68px] focus:outline-none focus:ring-0 focus:ring-offset-0  border focus:border-primary border-white text-textPrimary  text-center "
                                    value={sku.quantity}
                                />
                                <button className="flex-1  border-l border-delivery  bg-white hover:text-primary">+</button>
                            </div>
                            <div className="flex-1  text-sm text-textPrimary justify-end flex my-auto ">
                                {numeral(sku.price).format("0,0").replace(/,/g, ".")}đ
                            </div>
                            <div className="flex-1 text-sm text-textPrimary font-medium justify-end  my-auto flex truncate mr-[36px] ">
                                {numeral(sku.price * sku.quantity)
                                    .format("0,0")
                                    .replace(/,/g, ".")}
                                đ
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
