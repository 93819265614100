import React, { PropsWithChildren, useEffect } from "react"
import { Layout } from "antd"

import { useAppSelector } from "@hook/useAppSelector"
import { SearchHeader } from "@component/Header/SearchHeader"
import { HeaderTop } from "@component/HeaderTop/HeaderTop"
import { DefaultFooter } from "@component/Footer/Default"

interface Props extends PropsWithChildren<any> {
	loading?: boolean
	showSearch?: boolean
	showFooter?: boolean
	showTopMenu?: boolean
}

export const DefaultLayout = ({ showTopMenu = true, ...props }: Props) => {
	// const [collapsed, setCollapsed] = useState(false)
	const title = useAppSelector((state) => state.common.pageTitle)

	useEffect(() => {
		document.title = title
	}, [title])

	// const toggle = () => {
	// 	setCollapsed(!collapsed)
	// }

	// const onCollapsed = (collapsed: boolean) => {
	// 	setCollapsed(collapsed)
	// }

	return (
		<Layout
			id="layout"
			className={"h-screen overflow-auto relative bg-[#F7F7F7]"}>
			{
				showTopMenu && <HeaderTop />
			}
			<SearchHeader {...props} />
			{props.children}

			<DefaultFooter {...props} />

		</Layout>
	)
}

export default DefaultLayout
