import { HeaderTop } from "@component/HeaderTop/HeaderTop"
import { ICategoryCriterias, ICategoryView } from "@domain/Categories"

import { DefaultFooter } from "@component/Footer/Default"
import { SearchHeader } from "@component/Header/SearchHeader"
import useCategories from "@hook/query/category/categories.hook.query"
import CategoryBlockItems from "@screen/CategoriesPage/components/CategoryBlockItems"
import { Col, Row, Skeleton } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import CategoryBanners from "./components/CategoryBanners"
import ProductList from "./components/ProductList"
import { flattenTree, getSearchParamsValues, moveToFirst } from "./utilities"

function CategoriesPage() {
    const [searchParams] = useSearchParams()
    const [filter, setFilter] = useState<ICategoryCriterias>()

    useEffect(() => {
        if (searchParams) {
            // eslint-disable-next-line prefer-const
            let current = getSearchParamsValues(searchParams)
            const newTemp: any = {}
            if (!current.code) {
                newTemp["code"] = "dt&pk"
            } else {
                newTemp["code"] = decodeURIComponent(current.code)
            }
            newTemp["hasAll"] = true
            if (+current.cateLevel === 1 || !current.cateLevel) {
                setFilter(newTemp)
            } else if (+current.cateLevel === 2) {
                setFilter({ ...newTemp, code: decodeURIComponent(current.parent).split("&&")[0] })
            } else {
                setFilter({ ...newTemp, code: decodeURIComponent(current.parent).split("&&")[0] })
            }
        }
    }, [searchParams])

    const query: any = useCategories(filter)

    const handleRenderCategory = useMemo(() => {
        const params = getSearchParamsValues(searchParams)
        if (query && query.data)
            switch (+params.cateLevel) {
                default:
                case 1:
                case 2: {
                    const dataTemp = [...query.data[0].childs]
                    moveToFirst(dataTemp, params.code)
                    return (
                        <div>
                            {dataTemp.map((cate: ICategoryView) => {
                                return (
                                    <div key={cate.id}>
                                        <CategoryBlockItems
                                            catTitle={cate.name}
                                            childrens={cate.childs}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )
                }
                case 3: {
                    const dataTemp = [...query.data[0].childs]
                    moveToFirst(dataTemp, decodeURIComponent(params.parent).split("&&")[1])
                    return (
                        <div>
                            {dataTemp.map((cate: ICategoryView) => {
                                return (
                                    <div key={cate.id}>
                                        <CategoryBlockItems
                                            catTitle={cate.name}
                                            childrens={cate.childs}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )
                }
            }
    }, [searchParams, query])

    const renderProductBlock = useMemo(() => {
        if (query && query.data) {
            const params = getSearchParamsValues(searchParams)
            // if (params.code || params.cateSelected) {
            if (+params.cateLevel === 1 || !params.cateLevel) {
                const nodes1 = query.data[0].childs
                if (nodes1)
                    return nodes1.map((ele: ICategoryView, index: number) => {
                        return (
                            <div
                                key={ele.code + index}
                                className="mb-4">
                                <ProductList
                                    blockTitle={<span>{ele.name}</span>}
                                    rootItem={ele}
                                />
                            </div>
                        )
                    })
            }
            if (+params.cateLevel === 2) {
                const nodes2 = query.data[0].childs.find((itemLv2: ICategoryView) => itemLv2.code === params.code).childs
                if (nodes2)
                    return nodes2.map((ele: ICategoryView, index: number) => {
                        return (
                            <div
                                key={ele.code + index}
                                className="mb-4">
                                <ProductList
                                    blockTitle={<span>{ele.name}</span>}
                                    rootItem={ele}
                                />
                            </div>
                        )
                    })
            } else {
                const cate = flattenTree(query.data[0]).find((ele) => ele.code === decodeURIComponent(params.code))
                return (
                    <div className="mb-4">
                        <ProductList
                            blockTitle={<span>{cate?.name}</span>}
                            rootItem={query.data[0]}
                            isLastNode
                        />
                    </div>
                )
            }
        }

        // }
        return
    }, [searchParams, query])

    return (
        <React.Fragment>
            <section className="px-5 py-5 bg-[#ffff] w-full sticky top-0 z-20">
                <HeaderTop />
                <SearchHeader />
            </section>
            <article className="bg-[#F6F6F6] flex justify-center flex-col items-center overflow-hidden">
                <div className="w-[1280px] mx-auto">
                    <section className="categories-page">
                        <Row className="px-8">
                            <Col
                                lg={5}
                                xs={24}>
                                <div className="categories-page_left-section bg-white">
                                    <div className="flex justify-center px-3 py-4 rounded-t-xl bg-black text-white text-md font-semibold">Danh mục</div>
                                    <div className="px-3">
                                        <Skeleton
                                            loading={query.status === "loading"}
                                            active
                                            paragraph={{ rows: 4 }}>
                                            {handleRenderCategory}
                                        </Skeleton>
                                    </div>
                                </div>
                            </Col>

                            <Col
                                lg={19}
                                xs={24}>
                                <div className="categories-page_right-section">
                                    <div className="">
                                        <CategoryBanners />
                                    </div>
                                    {renderProductBlock}
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </article>
            <div className="w-full">
                <DefaultFooter />
            </div>
        </React.Fragment>
    )
}

export default CategoriesPage
