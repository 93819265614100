import React from 'react'
import logonPNG from "@resources/images/icons/logo-shop.png"
import { ISearchItem } from '@domain/Search';
import { convertNumberToCurrency } from '@util/Common';
interface ItemSearchProp {
    data: ISearchItem
}
export const ItemSearch = ({ data }: ItemSearchProp) => {
    const [isShow, setShow] = React.useState(false);
    const [isHover, setHover] = React.useState(false);
    const handleOnMouseOver = () => {
        if (!isShow) setShow(true);
    };

    const onMouseLeave = () => {
        if (isShow && !isHover) setShow(false);
    }
    const containerHover = () => {
        setHover(true);
    }
    const containerLeave = () => {
        setHover(false);
        setShow(false);
    }

    return (
        <div
            onClick={() => {
                window.open(`/products/${encodeURIComponent(data.name as string)}/${data.id}`)
            }}
            onMouseOver={containerHover}
            onMouseLeave={containerLeave}
            className="bg-[#ffffff] flex flex-col items-start justify-start relative border border-bg-Gray hover:border-primary w-[220px]"
            style={{
                boxShadow:
                    "var(--size-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))",
            }}
        >

            <div className="max-w-[224px] max-h-[220px] overflow-hidden" onMouseOver={handleOnMouseOver} onMouseLeave={onMouseLeave} >
                <div className={" inset-0 bg-cover bg-center w-[218px] h-[220px] overflow-hidden z-0 hover:z-50 self-stretch shrink-0 relative hover:scale-110 transition duration-500 cursor-pointer object-cover " + (isShow ? "scale-110" : "")} style={{
                    backgroundImage: `url(${data?.productImages[0] || data?.productImages[1]}`
                }}>
                </div>

            </div>
            <div className="pr-[9px] pl-[9px] pt-3 flex flex-col gap-2.5 items-start justify-start self-stretch shrink-0 relative z-30">
                <div className="pr-[3px] min-h-[40px] flex flex-col gap-[5px] items-start justify-start self-stretch shrink-0 relative overflow-hidden">
                    <div className="flex flex-row gap-[5px] items-start justify-start shrink-0 relative">
                        <img
                            className="shrink-0 w-[18px] h-[18px] relative"
                            src={logonPNG}
                        />
                        <div
                            className="text-text-color-text-primary line-clamp-2 cursor-pointer hover:text-[#F43314] "
                            style={{ font: "400 14px 'Roboto', sans-serif" }}
                            onClick={() => {
                                window.open(`/products/${encodeURIComponent(data.name as string)}/${data.id}`)
                            }}
                        >
                            {data.name}
                        </div>
                    </div>

                </div>
                {/* <div className="flex flex-row gap-1.5 items-start justify-start shrink-0 relative">
                    <div className="bg-[#f9f9f9] rounded-lg pt-1 pr-3 pb-1 pl-3 flex flex-col gap-0 items-start justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                        >
                            Trung bình{" "}
                        </div>
                        <div
                            className="text-text-color-text-disable text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                        >
                            Độ dày{" "}
                        </div>
                    </div>
                    <div className="bg-[#f9f9f9] rounded-lg pt-1 pr-3 pb-1 pl-3 flex flex-col gap-0 items-start justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                        >
                            Đường phố{" "}
                        </div>
                        <div
                            className="text-text-color-text-disable text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                        >
                            Phong cách{" "}
                        </div>
                    </div>
                </div> */}
                <div className="flex flex-row items-center justify-between self-stretch shrink-0 relative">
                    <div className="pt-[3px] pb-[3px] flex flex-row gap-0 items-start justify-start shrink-0 w-[92px] max-w-[220px] relative overflow-hidden">
                        <div className="bg-[#eff4fb] rounded-sm shrink-0 w-[92px] h-[17px] relative">
                            <div
                                className="text-[#4869af] text-left absolute left-1.5 top-px flex items-center justify-start"
                                style={{ font: "400 12px 'Roboto', sans-serif" }}
                            >
                                7 ngày trả hàng{" "}
                            </div>
                        </div>
                    </div>
                    <div
                        className="text-text-color-text-disable text-left relative flex items-center justify-start"
                        style={{ font: "400 12px 'Roboto', sans-serif" }}
                    >
                        {data.totalRates ? `Tỉ lệ mua lại ${data.totalRates}%` : ""}
                    </div>
                </div>
                <div className="flex flex-col gap-1 items-end justify-start self-stretch shrink-0 ">
                    <div className="flex flex-row items-[undefined] justify-between self-stretch shrink-0 relative">
                        <div className="flex flex-row gap-0.5 items-[undefined] justify-start shrink-0 relative">
                            <div
                                className="text-[#f9471b] text-center relative flex items-center justify-center"
                                style={{ font: "400 18px/22px 'Roboto', sans-serif" }}
                            >
                                {convertNumberToCurrency(data.salePrice || 0)}
                            </div>
                            <div
                                className="text-[#f9471b] text-center relative flex items-center justify-center"
                                style={{ font: "400 16px/22px 'Roboto', sans-serif" }}
                            >
                                ₫
                            </div>
                        </div>
                        <div
                            className="text-text-color-text-disable text-left relative flex items-center justify-start"
                            style={{ font: "400 12px 'Roboto', sans-serif" }}
                        >
                            {`(Bán ${data.totalSold} sp)`}
                        </div>
                    </div>
                </div>
                <div className="border-solid border-[#e7e7e7] border-t pt-px flex flex-row items-center self-stretchh-11 relative w-full">
                    <div className="pr-1 flex flex-row gap-0 items-center justify-start  h-[18px] relative">
                        <div className=" border-solid border-[#ff6000] border flex flex-row w-[61px] h-[18px] relative">
                            <img src="https://img.alicdn.com/tfs/TB1xPJdjXT7gK0jSZFpXXaTkpXa-112-112.png" alt="" />
                            <div
                                className=" text-[#ff6000] text-left absolute left-[22px] top-[1.5px] flex items-center justify-start"
                                style={{ font: "400 12px/15px 'Roboto', sans-serif" }}
                            >
                                {data.merchant?.age}năm{" "}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-0 items-start justify-start shrink">
                        <div
                            className="text-text-color-text-secondary-2 line-clamp-1"
                            style={{ font: "400 12px/43px 'Roboto', sans-serif" }}
                        >
                            {data.merchant?.name}
                        </div>
                    </div>
                </div>


            </div>
            <div className={" absolute left-0 bottom-0 w-[218px] bg-opacity-50 z-50 " + (isShow ? "block" : "hidden")} onMouseLeave={onMouseLeave}>
                <div className='flex flex-col justify-end h-full'>
                    <div className="cursor-pointer" style={{
                        backgroundColor: "rgba(255,115,0,0.9)",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        height: 39,
                        lineHeight: 17,

                        color: "#ffffff",
                        fontSize: 12,
                    }}>
                        Sản phẩm tương tự
                    </div>
                    <div className='bg-white py-4' >
                        <div className='flex flex-row justify-center py-2'>
                            <div className="pt-px pb-px flex flex-row gap-3 items-center justify-start shrink-0 relative">
                                <div
                                    className="text-text-color-text-secondary text-left relative flex items-center justify-start"
                                    style={{ font: "500 12px/17px 'Roboto', sans-serif" }}
                                >
                                    Đánh giá{" "}
                                </div>
                                <div className="flex flex-row gap-0.5 items-center justify-start shrink-0 relative">
                                    <img draggable="false"
                                        src="https://img.alicdn.com/imgextra/i4/O1CN013H8Fmx1ZNOx8cVpmH_!!6000000003182-55-tps-130-130.svg" alt="" style={{
                                            height: 14,
                                            backgroundImage: `url(https://img.alicdn.com/imgextra/i4/O1CN017wY4qf1Rnwz9wwYM7_!!6000000002157-55-tps-2-1.svg)`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                        }} />
                                    <img draggable="false"
                                        src="https://img.alicdn.com/imgextra/i4/O1CN013H8Fmx1ZNOx8cVpmH_!!6000000003182-55-tps-130-130.svg" alt="" style={{
                                            height: 14,
                                            backgroundImage: `url(https://img.alicdn.com/imgextra/i4/O1CN017wY4qf1Rnwz9wwYM7_!!6000000002157-55-tps-2-1.svg)`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                        }} />
                                    <img draggable="false"
                                        src="https://img.alicdn.com/imgextra/i4/O1CN013H8Fmx1ZNOx8cVpmH_!!6000000003182-55-tps-130-130.svg" alt="" style={{
                                            height: 14,
                                            backgroundImage: `url(https://img.alicdn.com/imgextra/i4/O1CN017wY4qf1Rnwz9wwYM7_!!6000000002157-55-tps-2-1.svg)`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                        }} />
                                    <img draggable="false"
                                        src="https://img.alicdn.com/imgextra/i4/O1CN013H8Fmx1ZNOx8cVpmH_!!6000000003182-55-tps-130-130.svg" alt="" style={{
                                            height: 14,
                                            backgroundImage: `url(https://img.alicdn.com/imgextra/i4/O1CN017wY4qf1Rnwz9wwYM7_!!6000000002157-55-tps-2-1.svg)`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                        }} />
                                    <img draggable="false"
                                        src="https://img.alicdn.com/imgextra/i4/O1CN013H8Fmx1ZNOx8cVpmH_!!6000000003182-55-tps-130-130.svg" alt="" style={{
                                            height: 14,
                                            backgroundImage: `url(https://img.alicdn.com/imgextra/i4/O1CN017wY4qf1Rnwz9wwYM7_!!6000000002157-55-tps-2-1.svg)`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                        }} />

                                </div>
                            </div>
                        </div>
                        <div className="pr-2.5 pl-2.5 flex flex-col gap-2.5 items-start justify-center self-stretch shrink-0 relative">
                            <div className="pr-[27px] flex flex-row gap-2 items-start justify-start shrink-0 w-[140px] relative">
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 w-[140px] relative">
                                    <div
                                        className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                                    >
                                        Tư vấn mua sắm{" "}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                                    <div
                                        className="text-[#000000] text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/17px 'Tahoma', sans-serif" }}
                                    >
                                        3.5{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="pr-[27px] flex flex-row gap-2 items-start justify-start shrink-0 w-[140px] relative">
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 w-[140px] relative">
                                    <div
                                        className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                                    >
                                        Giải quyết tranh chấp{" "}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                                    <div
                                        className="text-[#000000] text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/17px 'Tahoma', sans-serif" }}
                                    >
                                        3.5{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="pr-[27px] flex flex-row gap-2 items-start justify-start shrink-0 w-[140px] relative">
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 w-[140px] relative">
                                    <div
                                        className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                                    >
                                        Tiếp nhận và cải tiến{" "}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                                    <div
                                        className="text-[#000000] text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/17px 'Tahoma', sans-serif" }}
                                    >
                                        3.5{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="pr-[27px] flex flex-row gap-2 items-start justify-start shrink-0 w-[140px] relative">
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 w-[140px] relative">
                                    <div
                                        className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                                    >
                                        Traỉ nghiệm và chất lượng{" "}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                                    <div
                                        className="text-[#000000] text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/17px 'Tahoma', sans-serif" }}
                                    >
                                        3.5{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="pr-[27px] flex flex-row gap-2 items-start justify-start shrink-0 w-[140px] relative">
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 w-[140px] relative">
                                    <div
                                        className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/17px 'Roboto', sans-serif" }}
                                    >
                                        Tính kịp thời hậu cần{" "}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                                    <div
                                        className="text-[#000000] text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/17px 'Tahoma', sans-serif" }}
                                    >
                                        3.5{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div >
    );
}