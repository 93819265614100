import React, { useEffect } from 'react'
import lineImage from "@resources/images/imgs/line-dot.png"
import { useSearchParams } from 'react-router-dom'
import { Upload, UploadFile, UploadProps, message } from 'antd'
import CommonApi from '@api/Common'
import _ from 'lodash'
export const ImageSelect = () => {
    const [images, setImages] = React.useState<any>([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [fList, setFileList] = React.useState<UploadFile[]>([]);
    const [fileLength, setFileLength] = React.useState(0);
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    // const [uploading, setUploading] = useState(false);
    useEffect(() => {
        if (fList.length > 0 && fList.length === fileLength && fileLength <= (6 - images.length)) {
            const formData = new FormData();
            if (Array.isArray(fList)) {
                for (const file of fList) {
                    //@ts-ignore
                    formData.append('files', new Blob([file]), file.name)
                }
            }
            messageApi.open({
                key,
                type: 'loading',
                content: 'Đang tải ảnh...',
                duration: 0
            });
            CommonApi.uploadImage(formData).then(res => {
                setSearchParams({ imageUrls: images.concat(res.data.map(d => d.imageUrl)).toString() })
                setFileLength(0)
                setFileList([])
                setImages([])
            }).catch(err => {
                const error = err.response?.data?.message || err.message
                console.log("error", error);
                messageApi.error("Có lỗi xảy ra")
            }).finally(() => {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Thành công!',
                    duration: 1,
                });
            })
        }
        if (fileLength + images.length > 6) {
            message.error("Chỉ được tải lên tối đa 6 ảnh")
            setFileList([])
            setFileLength(0)
        }
    }, [fList, fileLength, setSearchParams, images, messageApi])
    useEffect(() => {
        const imgs = searchParams.get("imageUrls")
        if (imgs) {
            const data = imgs.split(",")
            setImages(_.uniq(data))
        }
    }, [searchParams])
    const list = [].concat(images);
    const firstElement = list.shift();
    const props: UploadProps = {
        name: 'files',
        maxCount: 6,
        multiple: true,
        action: undefined,
        fileList: images,
        beforeUpload: (_, fileList) => {
            let check = true;
            fileList.forEach((file: any) => {
                if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif' && file.type !== 'image/jpg') {
                    message.error(`${file.name} không phải là file ảnh`);
                    check = false;
                }
            });
            if (!check) return false;
            setFileLength(fileList.length);
            setFileList(fileList);
            return false;
        },
        showUploadList: false,
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    return <div
        className="flex flex-col gap-0 items-start justify-start h-[162px] relative"

    >
        {contextHolder}
        <div className="pr-[750px] pb-6 flex flex-row gap-0 items-start justify-start shrink-0 h-[162px] relative">
            <div className="pt-7 pr-2.5 flex flex-col gap-0 items-start justify-start shrink-0 w-[119px] h-[137px] relative">
                <div className="bg-[#ffffff] rounded-[2.56px] shrink-0 w-[109px] h-[109px] relative overflow-hidden">
                    <img
                        className="w-[109px] h-[109px] absolute left-0 top-0 overflow-hidden"
                        src={firstElement}
                    />
                    <div className="bg-[#ff4000] w-[76px] h-[22px] absolute right-0 bottom-0">
                        <img
                            className="w-[13px] h-[13px] absolute left-[calc(50%_-_31.5px)] top-[calc(50%_-_5.27px)]"
                            src="span-cut-icon.png"
                        />
                        <div
                            className="text-[#ffffff] text-center absolute left-[calc(50%_-_15.4px)] top-[0.5px] flex items-center justify-center"
                            style={{ font: "400 12px/22px 'Roboto', sans-serif" }}
                        >
                            Cắt ảnh{" "}
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-[47px] pr-2.5 flex flex-row gap-0 items-center justify-center shrink-0 w-[101px] h-[138px] relative">
                <div className="bg-[#ffffff] rounded-[2.09px] border-solid border-[#ff4000] border flex flex-row gap-0 items-center justify-center shrink-0 w-[91px] h-[91px] relative overflow-hidden">
                    <div className="shrink-0 w-[89px] h-[89px] relative overflow-hidden">
                        <img
                            className="w-[89px] h-[89px] absolute "
                            src={firstElement}
                        />
                    </div>
                    {/* <div className="border-solid border-[#ff4000] border-[10px] shrink-0 w-5 h-5 absolute right-px top-px"></div>
                    <div className="shrink-0 w-[9.9px] h-[7.7px] absolute right-[0.55px] top-[3.65px] overflow-hidden">
                        <div className="pt-[0.24px] pr-[0.01px] pb-[0.87px] flex flex-col gap-0 items-start justify-start w-[9.9px] h-[7.7px] absolute left-[calc(50%_-_4.96px)] top-[calc(50%_-_3.85px)] overflow-hidden"></div>
                    </div>
                    <svg
                        className="shrink-0 absolute left-[77.26px] top-[7.05px] overflow-visible"
                        style={{}}
                        width="11"
                        height="7"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.34107 6.45759C3.45163 6.57157 3.60244 6.6377 3.76118 6.64181C3.91992 6.64592 4.07396 6.58769 4.19027 6.47959L9.95647 1.11819C10.0186 1.06495 10.0692 0.999721 10.1055 0.926411C10.1418 0.853102 10.1628 0.773224 10.1674 0.691571C10.1721 0.609918 10.1601 0.528173 10.1324 0.45124C10.1047 0.374307 10.0617 0.303772 10.006 0.243868C9.95029 0.183964 9.88309 0.135925 9.80839 0.102633C9.73369 0.0693417 9.65303 0.0514841 9.57126 0.0501318C9.48948 0.0487796 9.40828 0.0639604 9.33252 0.0947636C9.25676 0.125567 9.188 0.171357 9.13037 0.229388L3.81737 5.20579L1.32257 2.63399C1.26797 2.57326 1.20168 2.52418 1.12767 2.48966C1.05365 2.45515 0.973441 2.43592 0.891826 2.43313C0.810211 2.43034 0.72887 2.44403 0.652671 2.47341C0.576473 2.50278 0.506981 2.54722 0.448355 2.60407C0.389729 2.66091 0.343173 2.72901 0.311472 2.80427C0.279771 2.87952 0.263577 2.96041 0.263859 3.04207C0.26414 3.12373 0.280892 3.2045 0.313111 3.27954C0.34533 3.35458 0.392354 3.42234 0.451371 3.47879L3.34107 6.45759Z"
                            fill="white"
                        />
                    </svg> */}
                </div>
            </div>


            <div className="pt-[23px] pl-[7px] flex flex-row gap-0 items-end justify-start shrink-0  relative">
                <div className="shrink-0  h-[111px] relative overflow-hidden">
                    <img
                        className="w-0.5 h-[110px] absolute left-[-1px] bottom-0 overflow-hidden"
                        src={lineImage}
                    />
                    <div className="flex flex-col gap-1.5 items-start justify-start ">
                        <div className="pt-[3px] pb-[3px] flex flex-row gap-1 items-center justify-start shrink-0 h-[18px] relative ml-5">
                            <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                                <div
                                    className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                                    style={{ font: "400 12px/12px 'Roboto', sans-serif" }}
                                >
                                    Tải lên{" "}
                                </div>
                            </div>
                            <div className="flex flex-row gap-0 items-center justify-start shrink-0 relative">
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                                    <div
                                        className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/12px 'Roboto', sans-serif" }}
                                    >
                                        ({" "}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                                    <div
                                        className="text-[#555555] text-left relative flex items-center justify-start"
                                        style={{ font: "500 12px/12px 'Roboto', sans-serif" }}
                                    >
                                        {images.length}{" "}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                                    <div
                                        className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                                        style={{ font: "400 12px/12px 'Roboto', sans-serif" }}
                                    >
                                        /6){" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row ml-5'>
                            {list.map((item: any, index: number) => {
                                return <div key={index} className=" pr-2.5 flex flex-row gap-0  shrink-0 w-[99px] h-[136px] relative">
                                    <div className="bg-[#ffffff] rounded-[2.09px]  flex flex-row gap-0 items-center justify-center shrink-0 w-[89px] h-[89px] relative overflow-hidden">
                                        <div className="shrink-0 w-[89px] h-[89px] relative overflow-hidden">
                                            <img
                                                className="w-[89px] h-[89px]"
                                                src={item} />
                                        </div>
                                    </div>
                                </div>
                            })}
                            {
                                images.length < 6 && <Upload {...props}>
                                    <div className="bg-[#ffffff] rounded-sm pr-[21.5px] pb-[6.5px] pl-[21.5px] flex flex-col gap-0 items-center justify-start shrink-0 w-[87px] h-[87px] relative">
                                        <div
                                            className="text-text-color-text-secondary text-left absolute left-[calc(50%_-_24.24px)] top-[55.05px] flex items-center justify-start"
                                            style={{ font: "400 11px/16px 'Roboto', sans-serif" }}
                                        >
                                            Thêm ảnh{" "}
                                        </div>
                                        <div className="pr-[0.02px] pl-[0.02px] flex flex-col gap-0 items-center justify-center shrink-0 w-[21px] h-5 absolute left-[calc(50%_-_10.5px)] top-[25.05px] overflow-hidden">
                                            <svg
                                                className="pt-[0.29px] pr-[0.19px] pb-[0.05px] pl-[0.19px] flex flex-col gap-0 items-start justify-start shrink-0 relative overflow-visible"
                                                style={{}}
                                                width="21"
                                                height="21"
                                                viewBox="0 0 21 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clipPath="url(#clip0_280_18825)">
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M10.8476 20.0014C11.3733 20.0014 11.8 19.7424 11.8 19.4224L11.799 10.7462H20.18C20.5152 10.7462 20.7857 10.3205 20.7857 9.79381C20.7857 9.2681 20.5152 8.84143 20.181 8.84143H11.799V0.914765C11.799 0.595718 11.3733 0.33667 10.8467 0.33667C10.321 0.33667 9.89429 0.595718 9.89429 0.914765V8.84334H0.819049C0.485715 8.84334 0.214287 9.26905 0.214287 9.79572C0.214287 10.3214 0.484763 10.7481 0.819049 10.7481H9.89429V19.4243C9.89429 19.7433 10.321 20.0024 10.8467 20.0024L10.8476 20.0014Z"
                                                        fill="#D8D8D8"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_280_18825">
                                                        <rect
                                                            width="20.9524"
                                                            height="20"
                                                            fill="white"
                                                            transform="translate(0.0238113 0.0500488)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </Upload>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}