import { ISearchItem, ISearchImageQuery, ISearchQuery } from "@domain/Search"
import apiClient from "@util/ApiClient"

export default class Search {
    // static getTimezones(): Promise<{ data: ITimezone[] }> {
    //     return apiClient.get(`time-zones`)
    // }
    static searchText(params: ISearchQuery) {
        return apiClient.get<ISearchItem[]>(`search?size=10`, {
            params,
        })
    }
    static searchImage(params: ISearchImageQuery) {
        return apiClient.get<ISearchItem[]>(`search/image?size=10`, {
            params,
        })
    }

}
