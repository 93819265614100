import appConfig from "@config/app";
import { useAppDispatch } from "@hook/useAppDispatch";
import { useAppSelector } from "@hook/useAppSelector";
import { callGetCart } from "@reducer/cart.reducer";
import { callGetAuthUserInfo } from "@reducer/common.reducer";
import localStore from "@util/LocalStore";
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom";

export const HeaderTop = () => {
    const [isLogin, setIsLogin] = React.useState(false)
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const merchants = useAppSelector(state => state.cart.merchants)
    const userInfo = useAppSelector(state => state.common.userInfo)

    useEffect(() => {
        const loggedUser = localStore.getJson("loginSession");
        if (loggedUser) {
            dispatch(callGetCart())
            dispatch(callGetAuthUserInfo())
            setIsLogin(true)
        }
    }, [dispatch])

    return (
        <div className="bg-[#ffffff] border-solid border-greyscale-new-divider border-b pr-[360px] pl-[360px] flex flex-row gap-0 items-start justify-center h-[30px] relative">
            <div className="flex flex-row items-center justify-between shrink-0 w-[1200px] relative">
                <div className="flex flex-row gap-4 items-start justify-start shrink-0 relative">
                    <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/30px 'Roboto', sans-serif" }}>
                            Chính sách{" "}
                        </div>
                        <svg
                            className="shrink-0 relative overflow-visible"
                            style={{}}
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.50692 0.931412C7.54794 0.88966 7.59687 0.856496 7.65084 0.833858C7.70482 0.81122 7.76276 0.799561 7.8213 0.799561C7.87983 0.799561 7.93777 0.81122 7.99175 0.833858C8.04573 0.856496 8.09466 0.88966 8.13568 0.931412C8.21915 1.01634 8.26592 1.13065 8.26592 1.24973C8.26592 1.36881 8.21915 1.48312 8.13568 1.56805L4.5793 5.16881C4.53828 5.21054 4.48936 5.24369 4.4354 5.26632C4.38143 5.28894 4.3235 5.3006 4.26498 5.3006C4.20647 5.3006 4.14853 5.28894 4.09457 5.26632C4.0406 5.24369 3.99168 5.21054 3.95067 5.16881L0.394164 1.56805C0.310691 1.48312 0.263916 1.36881 0.263916 1.24973C0.263916 1.13065 0.310691 1.01634 0.394164 0.931412C0.435185 0.88966 0.484112 0.856496 0.538089 0.833858C0.592066 0.81122 0.650011 0.799561 0.708543 0.799561C0.767075 0.799561 0.825021 0.81122 0.878998 0.833858C0.932975 0.856496 0.981902 0.88966 1.02292 0.931412L4.26498 4.21411L7.50692 0.931412Z"
                                fill="#7D7E7E"
                            />
                        </svg>

                    </div>
                    <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/30px 'Roboto', sans-serif" }}>
                            Giới thiệu{" "}
                        </div>
                        <svg
                            className="shrink-0 relative overflow-visible"
                            style={{}}
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.50692 0.931412C7.54794 0.88966 7.59687 0.856496 7.65084 0.833858C7.70482 0.81122 7.76276 0.799561 7.8213 0.799561C7.87983 0.799561 7.93777 0.81122 7.99175 0.833858C8.04573 0.856496 8.09466 0.88966 8.13568 0.931412C8.21915 1.01634 8.26592 1.13065 8.26592 1.24973C8.26592 1.36881 8.21915 1.48312 8.13568 1.56805L4.5793 5.16881C4.53828 5.21054 4.48936 5.24369 4.4354 5.26632C4.38143 5.28894 4.3235 5.3006 4.26498 5.3006C4.20647 5.3006 4.14853 5.28894 4.09457 5.26632C4.0406 5.24369 3.99168 5.21054 3.95067 5.16881L0.394164 1.56805C0.310691 1.48312 0.263916 1.36881 0.263916 1.24973C0.263916 1.13065 0.310691 1.01634 0.394164 0.931412C0.435185 0.88966 0.484112 0.856496 0.538089 0.833858C0.592066 0.81122 0.650011 0.799561 0.708543 0.799561C0.767075 0.799561 0.825021 0.81122 0.878998 0.833858C0.932975 0.856496 0.981902 0.88966 1.02292 0.931412L4.26498 4.21411L7.50692 0.931412Z"
                                fill="#7D7E7E"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/30px 'Roboto', sans-serif" }}>
                            Lorem{" "}
                        </div>
                        <svg
                            className="shrink-0 relative overflow-visible"
                            style={{}}
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.50692 0.931412C7.54794 0.88966 7.59687 0.856496 7.65084 0.833858C7.70482 0.81122 7.76276 0.799561 7.8213 0.799561C7.87983 0.799561 7.93777 0.81122 7.99175 0.833858C8.04573 0.856496 8.09466 0.88966 8.13568 0.931412C8.21915 1.01634 8.26592 1.13065 8.26592 1.24973C8.26592 1.36881 8.21915 1.48312 8.13568 1.56805L4.5793 5.16881C4.53828 5.21054 4.48936 5.24369 4.4354 5.26632C4.38143 5.28894 4.3235 5.3006 4.26498 5.3006C4.20647 5.3006 4.14853 5.28894 4.09457 5.26632C4.0406 5.24369 3.99168 5.21054 3.95067 5.16881L0.394164 1.56805C0.310691 1.48312 0.263916 1.36881 0.263916 1.24973C0.263916 1.13065 0.310691 1.01634 0.394164 0.931412C0.435185 0.88966 0.484112 0.856496 0.538089 0.833858C0.592066 0.81122 0.650011 0.799561 0.708543 0.799561C0.767075 0.799561 0.825021 0.81122 0.878998 0.833858C0.932975 0.856496 0.981902 0.88966 1.02292 0.931412L4.26498 4.21411L7.50692 0.931412Z"
                                fill="#7D7E7E"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/30px 'Roboto', sans-serif" }}>
                            Lorem{" "}
                        </div>
                        <svg
                            className="shrink-0 relative overflow-visible"
                            style={{}}
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.50692 0.931412C7.54794 0.88966 7.59687 0.856496 7.65084 0.833858C7.70482 0.81122 7.76276 0.799561 7.8213 0.799561C7.87983 0.799561 7.93777 0.81122 7.99175 0.833858C8.04573 0.856496 8.09466 0.88966 8.13568 0.931412C8.21915 1.01634 8.26592 1.13065 8.26592 1.24973C8.26592 1.36881 8.21915 1.48312 8.13568 1.56805L4.5793 5.16881C4.53828 5.21054 4.48936 5.24369 4.4354 5.26632C4.38143 5.28894 4.3235 5.3006 4.26498 5.3006C4.20647 5.3006 4.14853 5.28894 4.09457 5.26632C4.0406 5.24369 3.99168 5.21054 3.95067 5.16881L0.394164 1.56805C0.310691 1.48312 0.263916 1.36881 0.263916 1.24973C0.263916 1.13065 0.310691 1.01634 0.394164 0.931412C0.435185 0.88966 0.484112 0.856496 0.538089 0.833858C0.592066 0.81122 0.650011 0.799561 0.708543 0.799561C0.767075 0.799561 0.825021 0.81122 0.878998 0.833858C0.932975 0.856496 0.981902 0.88966 1.02292 0.931412L4.26498 4.21411L7.50692 0.931412Z"
                                fill="#7D7E7E"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/30px 'Roboto', sans-serif" }}>
                            Lorem{" "}
                        </div>
                        <svg
                            className="shrink-0 relative overflow-visible"
                            style={{}}
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.50692 0.931412C7.54794 0.88966 7.59687 0.856496 7.65084 0.833858C7.70482 0.81122 7.76276 0.799561 7.8213 0.799561C7.87983 0.799561 7.93777 0.81122 7.99175 0.833858C8.04573 0.856496 8.09466 0.88966 8.13568 0.931412C8.21915 1.01634 8.26592 1.13065 8.26592 1.24973C8.26592 1.36881 8.21915 1.48312 8.13568 1.56805L4.5793 5.16881C4.53828 5.21054 4.48936 5.24369 4.4354 5.26632C4.38143 5.28894 4.3235 5.3006 4.26498 5.3006C4.20647 5.3006 4.14853 5.28894 4.09457 5.26632C4.0406 5.24369 3.99168 5.21054 3.95067 5.16881L0.394164 1.56805C0.310691 1.48312 0.263916 1.36881 0.263916 1.24973C0.263916 1.13065 0.310691 1.01634 0.394164 0.931412C0.435185 0.88966 0.484112 0.856496 0.538089 0.833858C0.592066 0.81122 0.650011 0.799561 0.708543 0.799561C0.767075 0.799561 0.825021 0.81122 0.878998 0.833858C0.932975 0.856496 0.981902 0.88966 1.02292 0.931412L4.26498 4.21411L7.50692 0.931412Z"
                                fill="#7D7E7E"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/30px 'Roboto', sans-serif" }}>
                            Lorem{" "}
                        </div>
                        <svg
                            className="shrink-0 relative overflow-visible"
                            style={{}}
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.50692 0.931412C7.54794 0.88966 7.59687 0.856496 7.65084 0.833858C7.70482 0.81122 7.76276 0.799561 7.8213 0.799561C7.87983 0.799561 7.93777 0.81122 7.99175 0.833858C8.04573 0.856496 8.09466 0.88966 8.13568 0.931412C8.21915 1.01634 8.26592 1.13065 8.26592 1.24973C8.26592 1.36881 8.21915 1.48312 8.13568 1.56805L4.5793 5.16881C4.53828 5.21054 4.48936 5.24369 4.4354 5.26632C4.38143 5.28894 4.3235 5.3006 4.26498 5.3006C4.20647 5.3006 4.14853 5.28894 4.09457 5.26632C4.0406 5.24369 3.99168 5.21054 3.95067 5.16881L0.394164 1.56805C0.310691 1.48312 0.263916 1.36881 0.263916 1.24973C0.263916 1.13065 0.310691 1.01634 0.394164 0.931412C0.435185 0.88966 0.484112 0.856496 0.538089 0.833858C0.592066 0.81122 0.650011 0.799561 0.708543 0.799561C0.767075 0.799561 0.825021 0.81122 0.878998 0.833858C0.932975 0.856496 0.981902 0.88966 1.02292 0.931412L4.26498 4.21411L7.50692 0.931412Z"
                                fill="#7D7E7E"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/30px 'Roboto', sans-serif" }}>
                            Lorem{" "}
                        </div>
                        <svg
                            className="shrink-0 relative overflow-visible"
                            style={{}}
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.50692 0.931412C7.54794 0.88966 7.59687 0.856496 7.65084 0.833858C7.70482 0.81122 7.76276 0.799561 7.8213 0.799561C7.87983 0.799561 7.93777 0.81122 7.99175 0.833858C8.04573 0.856496 8.09466 0.88966 8.13568 0.931412C8.21915 1.01634 8.26592 1.13065 8.26592 1.24973C8.26592 1.36881 8.21915 1.48312 8.13568 1.56805L4.5793 5.16881C4.53828 5.21054 4.48936 5.24369 4.4354 5.26632C4.38143 5.28894 4.3235 5.3006 4.26498 5.3006C4.20647 5.3006 4.14853 5.28894 4.09457 5.26632C4.0406 5.24369 3.99168 5.21054 3.95067 5.16881L0.394164 1.56805C0.310691 1.48312 0.263916 1.36881 0.263916 1.24973C0.263916 1.13065 0.310691 1.01634 0.394164 0.931412C0.435185 0.88966 0.484112 0.856496 0.538089 0.833858C0.592066 0.81122 0.650011 0.799561 0.708543 0.799561C0.767075 0.799561 0.825021 0.81122 0.878998 0.833858C0.932975 0.856496 0.981902 0.88966 1.02292 0.931412L4.26498 4.21411L7.50692 0.931412Z"
                                fill="#7D7E7E"
                            />
                        </svg>
                    </div>
                </div>
                <div className="pl-1 flex flex-row gap-4 items-center justify-start shrink-0 relative">
                    {
                        !isLogin && <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start cursor-pointer"
                            onClick={() => {
                                navigate("/register");
                            }}
                            style={{ font: "400 12px/30px 'Roboto', sans-serif" }}
                        >
                            Đăng ký{" "}
                        </div>
                    }
                    {
                        !isLogin && <div
                            onClick={() => {
                                window.location.href = appConfig.loginUrl ?? "";
                            }}
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start cursor-pointer"
                            style={{ font: "400 12px/30px 'Roboto', sans-serif" }}
                        >
                            Đăng nhập{" "}
                        </div>
                    }
                    {isLogin && (
                        <div className="flex  group flex-row gap-2 items-center justify-start shrink-0 relative">
                            <div
                                className="text-primary text-left relative flex items-center justify-start"
                                style={{ font: "400 12px/30px 'Roboto', sans-serif" }}>
                                {userInfo.name}
                            </div>
                            <svg
                                className="shrink-0 relative overflow-visible"
                                style={{}}
                                width="9"
                                height="6"
                                viewBox="0 0 9 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.50692 0.931412C7.54794 0.88966 7.59687 0.856496 7.65084 0.833858C7.70482 0.81122 7.76276 0.799561 7.8213 0.799561C7.87983 0.799561 7.93777 0.81122 7.99175 0.833858C8.04573 0.856496 8.09466 0.88966 8.13568 0.931412C8.21915 1.01634 8.26592 1.13065 8.26592 1.24973C8.26592 1.36881 8.21915 1.48312 8.13568 1.56805L4.5793 5.16881C4.53828 5.21054 4.48936 5.24369 4.4354 5.26632C4.38143 5.28894 4.3235 5.3006 4.26498 5.3006C4.20647 5.3006 4.14853 5.28894 4.09457 5.26632C4.0406 5.24369 3.99168 5.21054 3.95067 5.16881L0.394164 1.56805C0.310691 1.48312 0.263916 1.36881 0.263916 1.24973C0.263916 1.13065 0.310691 1.01634 0.394164 0.931412C0.435185 0.88966 0.484112 0.856496 0.538089 0.833858C0.592066 0.81122 0.650011 0.799561 0.708543 0.799561C0.767075 0.799561 0.825021 0.81122 0.878998 0.833858C0.932975 0.856496 0.981902 0.88966 1.02292 0.931412L4.26498 4.21411L7.50692 0.931412Z"
                                    className="group-hover:fill-primary fill-[#7D7E7E]"
                                />
                            </svg>

                        </div>
                    )}

                    <div onClick={() => {
                        if (isLogin) {
                            navigate("/cart");
                        } else {
                            localStore.setItem("urlRedirectLogin", "/cart")
                            window.location.href = appConfig.loginUrl ?? "";

                        }
                    }} className="flex cursor-pointer flex-row gap-[6px] items-center justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/30px 'Roboto', sans-serif" }}>
                            Giỏ hàng{" "}
                        </div>
                        {merchants[0]?.totalSku > 0 &&
                            <div className=" bg-primary text-[9px] text-white px-[6px] py-[1px]  rounded-2xl">{merchants[0]?.totalSku}</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
