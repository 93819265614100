import React, { useMemo } from 'react'
import { ImageSelect } from './components/ImageSelect';
import { TextSearch } from './components/TextSearch';
import { Filter } from './components/Filter';
import { ItemSearch } from './components/Item';
import { InfinityScroll } from '@component/InfiniteScroll';
import DefaultLayout from '@component/Layout/Default';
import { useSearchParams } from 'react-router-dom';
import { useSearchImageQuery } from '@hook/query/search.hook.query';
import { ISearchItem } from '@domain/Search';
import { randomIntFromInterval } from '@util/Common';

export const SearchImagePage = () => {
    const [searchParams] = useSearchParams()
    const searchQuery = useMemo(
        () => ({
            ...Object.fromEntries(searchParams.entries()),
            type: randomIntFromInterval(1, 4).toString(),
            sort: "createdAt:desc",
        }),
        [searchParams]
    )
    const { data, isFetching, isLoading, fetchNextPage, hasNextPage = false } = useSearchImageQuery(searchQuery)
    const products = data?.pages.reduce((acc, page) => [...acc, ...page.data], [] as any[]) || []
    return <DefaultLayout loading={false}>
        {/* <Search /> */}
        <div className='relative '>
            {
                <div className='flex justify-center'
                    style={{
                        background:
                            "linear-gradient(180deg, rgba(247, 247, 247, 1.00) 0%,rgba(236, 236, 236, 1.00) 100%)",
                    }}
                >
                    <div className='2xl:w-[1390px] xl:w-[1190px] lg:w-[990px] md:w-[720px] w-[480px] '>
                        <ImageSelect />
                    </div>
                </div>
            }
            {
                (searchParams.get("keyword") || products.length > 0) && <div className='bg-[#F4F4F4] flex justify-center'>
                    <div className='2xl:w-[1400px] xl:w-[1190px] lg:w-[990px] md:w-[720px] w-[480px] '>
                        <div className='h-4'></div>
                        <TextSearch />
                    </div>
                </div>
            }
            {
                isLoading && <div className='2xl:w-[1390px] xl:w-[1190px] lg:w-[990px] md:w-[720px] w-[480px] '>
                    <div className='absolute left-0 right-0 top-[300px] z-50 bottom-0 bg-[rgba(0,0,0,0.2)]'>
                        <div className="animate-pulse space-x-4 flex justify-center flex-row mt-20">
                            <div
                                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                                <span
                                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                >Loading...</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='bg-[#F4F4F4] flex justify-center'>
                <div className='2xl:w-[1400px] xl:w-[1190px] lg:w-[990px] md:w-[720px] w-[480px] '>
                    <div className='h-4'></div>
                    <Filter />
                    <div className='h-4'></div>
                </div>
            </div>

            <div className='z-10'>
                {(products.length > 0 && !isLoading) ? <div className='bg-[#F4F4F4] flex justify-center pb-10'>
                    <div className='2xl:w-[1390px] xl:w-[1190px] lg:w-[990px] md:w-[720px] w-[480px] '>
                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5'>
                            {products?.map((item: ISearchItem, index: number) => {
                                return <ItemSearch data={item} key={index} />
                            })}
                        </div>
                        <InfinityScroll loading={isFetching} fetchData={fetchNextPage} hasMode={hasNextPage} />

                    </div>
                </div> : !isFetching && <div className='bg-[#F4F4F4] flex justify-center min-h-[280px] h-4/6'>
                    <div className="flex flex-row gap-10 items-center justify-start relative">
                        <div
                            className="text-text-color-text-disable text-left relative flex items-center justify-start"
                            style={{ font: "100 80px 'Font Awesome 6 Pro', sans-serif" }}
                        >
                            {" "}
                        </div>
                        <div className="flex flex-col gap-3 items-start justify-start shrink-0 relative">
                            <div
                                className="text-text-color-text-secondary text-right relative flex items-center justify-end"
                                style={{ font: "400 16px/15.43px 'Roboto', sans-serif" }}
                            >
                                Không tìm thấy sản phẩm liên quan{" "}
                            </div>
                            <div
                                className="text-text-color-text-primary text-left relative flex items-center justify-start"
                                style={{ font: "500 12px/22px 'Roboto', sans-serif" }}
                            >
                                Bạn có thể:{" "}
                            </div>
                            <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                                <div
                                    className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                                    style={{ font: "400 12px/22px 'Roboto', sans-serif" }}
                                >
                                    1. Rút ngắn hoặc sửa đổi cụm từ tìm kiếm của bạn và tìm kiếm lại{" "}
                                </div>
                                <div
                                    className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                                    style={{ font: "400 12px/22px 'Roboto', sans-serif" }}
                                >
                                    2. Viết ra các yêu cầu mua sắm của bạn và nhanh chóng nhận được báo
                                    giá từ nhiều nhà cung cấp
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </DefaultLayout>
} 