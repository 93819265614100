import { Checkbox } from "antd"
import React from "react"
import { ItemSku } from "./ItemSku"
import { IProduct } from "@domain/Cart"
import classNames from "classnames"
export const ItemProduct = ({ end, product, onUpdateSelect }: {
    end?: boolean
    product: IProduct, onUpdateSelect: ({ merchantId, skuId, productId, isCheck, isAll }: {
        merchantId?: string | undefined;
        skuId?: string | undefined;
        productId?: string | undefined;
        isCheck: boolean;
        isAll?: boolean | undefined;
    }) => void
}) => {
    const isCheckAll = product.skus.length === product.skus.filter(e => e.checked).length
    return (
        <div className={classNames(" flex flex-row gap-6 relative  border-b-delivery  px-5   w-full pb-6  items-start ", !end && "border-b")}>
            <div className=" flex flex-row items-center gap-3">
                <Checkbox indeterminate={product.skus.filter(e => e.checked).length > 0 && !isCheckAll} checked={isCheckAll} onClick={() => onUpdateSelect({ productId: product.id, isCheck: !isCheckAll })} />
                <img
                    className=" h-[70px] w-[70px] object-cover  rounded-lg"
                    src={JSON.parse(product.images).filter((e: string) => !e.endsWith(".mp4"))[0] || require("src/resources/images/icons/product-default.png")}
                />
            </div>
            <div className=" flex  flex-col w-full">
                <div
                    onClick={() => {
                        window.open(`products/${encodeURIComponent(product.title)}/${product.itemId}`)
                    }}
                    className=" text-base hover:text-primary text-textPrimary cursor-pointer">
                    {product.title}
                </div>
                <div className=" flex flex-col gap-5  my-4">
                    {product.skus.map((e) => {
                        return (
                            <ItemSku
                                onUpdateSelect={onUpdateSelect}
                                sku={e}
                                key={e.id}
                            />
                        )
                    })}
                </div>
                <button
                    data-modal-target="defaultModal"
                    data-modal-toggle="defaultModal"
                    className=" hover:border-primary hover:text-primary border border-[#DDD] rounded-3xl  w-fit px-6 py-2 text-xs text-textPrimary">
                    Chọn thêm
                </button>
            </div>
            <button className=" group bg-[#F7F7F7] py-2 px-5 rounded-t w-fit h-fit absolute  right-0 bottom-0">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none">
                    <path
                        d="M14.2188 2.57312H0.6875C0.5 2.57312 0.338542 2.64083 0.203125 2.77625C0.0677083 2.91166 0 3.07572 0 3.26843C0 3.46114 0.0677083 3.6252 0.203125 3.76062C0.338542 3.89604 0.5 3.96375 0.6875 3.96375H1.39062V14.7137C1.39062 14.9325 1.43229 15.1434 1.51562 15.3466C1.59896 15.5497 1.71875 15.7294 1.875 15.8856C2.03125 16.0419 2.20833 16.1617 2.40625 16.245C2.60417 16.3283 2.81771 16.37 3.04688 16.37H11.8594C12.1615 16.37 12.4401 16.2945 12.6953 16.1434C12.9505 15.9924 13.151 15.7919 13.2969 15.5419C13.4427 15.2919 13.5156 15.0158 13.5156 14.7137V3.96375H14.2188C14.4062 3.96375 14.5677 3.89604 14.7031 3.76062C14.8385 3.6252 14.9062 3.46114 14.9062 3.26843C14.9062 3.07572 14.8385 2.91166 14.7031 2.77625C14.5677 2.64083 14.4062 2.57312 14.2188 2.57312ZM12.1406 14.7137C12.1406 14.7867 12.1146 14.8518 12.0625 14.9091C12.0104 14.9663 11.9427 14.995 11.8594 14.995H3.04688C2.96354 14.995 2.89583 14.9663 2.84375 14.9091C2.79167 14.8518 2.76562 14.7867 2.76562 14.7137V3.96375H12.1406V14.7137ZM11.3125 1.6825C11.3021 1.31791 11.1615 1.00802 10.8906 0.752808C10.6198 0.4976 10.3021 0.369995 9.9375 0.369995H5.25C4.86458 0.369995 4.53646 0.505412 4.26562 0.776245C3.99479 1.04708 3.85938 1.37 3.85938 1.745H11.3125V1.6825ZM5.51562 6.16687H5.79688C6.16146 6.16687 6.34375 6.34916 6.34375 6.71375V11.9637C6.34375 12.3283 6.16146 12.5106 5.79688 12.5106H5.51562C5.15104 12.5106 4.96875 12.3283 4.96875 11.9637V6.71375C4.96875 6.34916 5.15104 6.16687 5.51562 6.16687ZM9.10938 6.16687H9.39062C9.75521 6.16687 9.9375 6.34916 9.9375 6.71375V11.9637C9.9375 12.3283 9.75521 12.5106 9.39062 12.5106H9.10938C8.74479 12.5106 8.5625 12.3283 8.5625 11.9637V6.71375C8.5625 6.34916 8.74479 6.16687 9.10938 6.16687Z"
                        fill="none"
                        className=" fill-disable group-hover:fill-primary"
                    />
                </svg>
            </button>
        </div>
    )
}
