import classNames from "classnames";
import React from "react";
import logo from 'src/resources/images/flag/logo.png';
const dataWebs = [
    { label: "Arlene McCoy" },
    { label: "Theresa Webb" },
    { label: "Jerome Bell" },
    { label: "Darlene Robertson" },
    { label: "Arlene McCoy" },
    { label: "Jacob Jones" },
    { label: "Ralph Edwards" },
]
const dataWebImages = [
    { link: "https://fbu.asia/wp-content/themes/ubox-fulfillment/temp/images/logo-fbu.svg" },
    { link: "https://gobiz.vn/wp-content/uploads/2021/10/logo_Gobiz-e1636531853884.png" },
    { link: "https://img.alicdn.com/imgextra/i1/O1CN01dUHefe1TNxqfFY58z_!!6000000002371-55-tps-404-83.svg" },


]
export const Footer = () => {
    return (

        <div className=" bg-white ">
            <div className=" w-[1200px] mx-auto py-14 flex flex-row gap-[84px]">
                <img alt="" src={logo} className=" w-[180px] h-[50px]" />
                <div>
                    <div className=" flex flex-wrap gap-5">{dataWebs.map((e) => {
                        return (
                            <div className={classNames("text-xs text-disable font-normal")} key={e.label}>{e.label}</div>
                        )
                    })}</div>
                    <div className={classNames("text-xs text-disable font-normal mt-[10px] mb-[15px]")} >Amet minim mollit non deserunt ullamco est sit aliqua </div>
                    <div className={classNames("text-xs text-disable font-normal mt-[10px] mb-[15px]")} >© 2010-2023 1688.com  0571-81683755 blxx@list.alibaba-inc.com</div>
                    <div className=" flex flex-wrap gap-2">{dataWebImages.map(e => {
                        return <img alt="" className="h-[40xp] w-[60px] object-contain" src={e.link} key={e.link} />
                    })}</div>

                </div>

            </div>
        </div>
    )
}