import { useAppSelector } from "@hook/useAppSelector"
import { Carousel } from "antd"
import { CarouselRef } from "antd/lib/carousel"
import classNames from "classnames"
import React, { useRef, useState } from "react"

export const Banner = () => {
    const banners = useAppSelector((state) => state.home.banners)
    const [active, setActive] = useState(0)
    const [hover, setHover] = useState(false)
    const ref = useRef<CarouselRef>(null)
    const goTo = (slide: number) => {
        setActive(slide)
        ref.current?.goTo(slide, true)
    }

    return (
        <div className="w-[600px] z-10 relative">
            <Carousel
                ref={ref}
                swipeToSlide
                draggable
                afterChange={(value) => setActive(value)}
                autoplay={!hover}
                dots={false}
                className="rounded-lg overflow-hidden">
                {banners.map((e) => {
                    return (
                        <img
                            alt=""
                            key={e.id}
                            onClick={() => {
                                if (e.url) {
                                    const win = window.open(e.url, "_blank")
                                    win?.focus()
                                }
                            }}
                            className={classNames("h-[358px]  flex-1  w-full object-cover", e.url && "cursor-pointer")}
                            src={e.image}
                        />
                    )
                })}
            </Carousel>
            <div className=" absolute  m-auto w-fit left-0 right-0 bottom-3 flex flex-row z-10">
                {banners.map((e, index) => {
                    return (
                        <div
                            key={e.id}
                            onMouseLeave={() => {
                                setHover(false)
                            }}
                            onMouseEnter={() => {
                                setHover(true)
                                goTo(index)
                            }}
                            className=" p-[6px] cursor-pointer">
                            <div className={classNames(" w-2 h-2 rounded-lg", active == index ? "bg-primary" : " bg-black opacity-25")} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
