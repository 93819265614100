import { get_url_extension } from "@screen/CategoriesPage/utilities"
import React, { useEffect, useState } from "react"
import Slider from "react-slick"

interface ImageSycningSliderProps {
    images: string[] | undefined | any
    className?: string
}

function ImageSycningSlider(props: ImageSycningSliderProps) {
    const { images } = props

    const [nav1, setNav1] = useState<any>()
    const [nav2, setNav2] = useState<any>()

    useEffect(() => {
        const parentContainer:any = document.querySelector(".product-detail-page_body")
        const childContainer:any = document.querySelector("._images-preview")

        // console.log(parentContainer,childContainer)

        window.addEventListener("scroll", () => {
            const parentRect:any = parentContainer?.getBoundingClientRect()
            const childRect:any = childContainer?.getBoundingClientRect()

            // Xác định vị trí cuộn trang
            const scrollY = window.scrollY

            // Xác định giới hạn cuộn
            const maxY = parentRect.height - childRect.height

            // Xác định vị trí mới cho thẻ con
            let newTop = scrollY
            if (newTop < 0) newTop = 0
            if (newTop > maxY) newTop = maxY

            childContainer.style.top = newTop + "px"
        })
    }, [])

    return (
        <React.Fragment>
            <div className={`px-[32px] py-3 sticky top-0 ${props.className ? props.className : ''}`}>
                <Slider
                    className="mainSlider"
                    asNavFor={nav2}
                    ref={(slider1: any) => setNav1(slider1)}
                    nextArrow={<></>}
                    prevArrow={<></>}>
                    {images && images.length > 0
                        ? images.map((url: string, index: number) => {
                              if (!["mp4"].includes(get_url_extension(url))) {
                                  return (
                                      <div
                                          key={index}
                                          className="overflow-hidden flex justify-center">
                                          <div
                                              className="h-[420px] w-[420px] bg-cover bg-no-repeat ml-1"
                                              style={{ backgroundImage: `url(${url})`, backgroundPosition: "center center" }}>
                                              {/* <img
                                              src={url}
                                              style={{ objectFit: "cover", height: "inherit", width: "inherit" }}
                                          /> */}
                                          </div>
                                      </div>
                                  )
                              } else
                                  return (
                                      <div
                                          key={index}
                                          className="overflow-hidden cursor-pointer">
                                          <video
                                              controls
                                              className="h-[420px] w-full"
                                              autoPlay
                                              muted>
                                              <source
                                                  src={url}
                                                  type="video/mp4"
                                              />
                                          </video>
                                      </div>
                                  )
                          })
                        : Array.from({ length: 6 }, (_, index) => index).map((_, index) => <div key={index}>{index + 1}</div>)}
                </Slider>
                <div className="mt-[24px]">
                    <Slider
                        asNavFor={nav1}
                        ref={(slider2: any) => setNav2(slider2)}
                        slidesToShow={6}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        infinite={false}
                        // slidesToScroll={4}
                    >
                        {images && images.length > 0
                            ? images.map((url: string, index: number) => {
                                  if (!["mp4"].includes(get_url_extension(url))) {
                                      return (
                                          <div
                                              key={index}
                                              className="overflow-hidden cursor-pointer pr-1">
                                              <div
                                                  style={{ backgroundImage: `url(${url})` }}
                                                  className="h-[60px] w-full bg-center bg-cover"
                                                  //   onMouseEnter={() => {
                                                  //       nav1.slickGoTo(index)
                                                  //   }}
                                              ></div>
                                          </div>
                                      )
                                  } else
                                      return (
                                          <div
                                              key={index}
                                              className="overflow-hidden cursor-pointer h-[58px] pr-2">
                                              <video
                                                  width="100%"
                                                  className="h-full"
                                                  controls={false}>
                                                  <source
                                                      src={url}
                                                      type="video/mp4"
                                                  />
                                              </video>
                                          </div>
                                      )
                              })
                            : Array.from({ length: 6 }, (_, index) => index).map((_, index) => <div key={index}>{index + 1}</div>)}
                    </Slider>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ImageSycningSlider
