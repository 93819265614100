import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import CartApi from "@api/Cart"
import { IMerchant } from "@domain/Cart"

interface State {
    merchants: IMerchant[]
    loading: boolean
}

const initState: State = {
    merchants: [],
    loading: false,
}



export const callGetCart = createAsyncThunk("CART.CART", async (_, thunkApi) => {
    try {
        return await CartApi.getMerchants()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const cartReducer = createReducer(initState, (builder) => {

    builder

        .addCase(callGetCart.fulfilled, (state, { payload }) => {
            state.merchants = payload?.data
            state.loading = false

        })
    builder
        .addCase(callGetCart.pending, (state) => {
            state.loading = true
        })

})
