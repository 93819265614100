import classNames from "classnames"
import React, { useState } from "react"
const data = [
    { label: "Câu hỏi", data: [{ label: "Làm thế nào để trả hàng?" }, { label: "Làm thế nào để hoàn tiền?" }] },
    { label: "Chính sách", data: [{ label: "Làm thế nào để trả hàng?" }, { label: "Làm thế nào để hoàn tiền?" }] },
    { label: "Đổi trả", data: [{ label: "Làm thế nào để trả hàng?" }, { label: "Làm thế nào để hoàn tiền?" }] },
]
export const Questions = () => {
    const [active, setActive] = useState(0)
    return (
        <div className="flex h-[88px] flex-1 flex-col bg-white p-3 rounded-lg gap-4">
            <div className=" flex flex-row gap-[30px] ">
                {data.map((e, index) => {
                    return (
                        <div
                            onMouseEnter={() => {
                                setActive(index)
                            }}
                            key={index}
                            className=" flex flex-col  gap-[6px]">
                            <div className={classNames("font-medium text-sm", index == active ? "text-primary" : "text-[#666666]")}>{e.label}</div>
                            <div className={classNames("h-[2px] w-[28px] mx-auto", index == active ? "bg-primary" : " bg-white")} />
                        </div>
                    )
                })}
            </div>

            <div className=" flex flex-row gap-[30px] ">
                {data[active].data.map((e, index) => {
                    return (
                        <div
                            key={index}
                            className=" flex flex-col">
                            <div className={"font-normal text-xs text-secondary"}>{e.label}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
