import React from "react"
import numeral from 'numeral';
export const BottomInfo = ({ totalPrice, totalSku, totalSkuQuantity }: {
    totalSku: number,
    totalSkuQuantity: number
    totalPrice: number


}) => {
    return (
        <div className="  bg-[#FEF8E1] border-[#FBE1A3] border py-5  px-8 flex flex-row justify-between items-start">


            <div className=" flex flex-row  gap-6 items-center">
                <div className=" text-sm text-textPrimary font-medium ">Tổng cộng：</div>

                <div className=" text-xs text-textPrimary ">Phân Loại: <span className="text-primary">{totalSku} loại</span></div>
                <div className=" text-xs text-textPrimary ">Sản phẩm: <span className="text-primary" >{totalSkuQuantity} sản phẩm</span></div>

            </div>
            <div>
                <div className="flex flex-row  items-center gap-3">
                    <div className=" flex flex-col items-end  gap-3">
                        <div className="text-xs text-secondary2">Tổng tiền hàng：</div>
                        <div className="text-xs text-secondary2">Giảm giá：</div>
                        <div className="text-xs text-secondary2">Phí vận chuyển nội địa TQ：</div>
                        <div className="text-xs text-secondary2">Tổng tiền tạm tính (bao gồm phí vận chuyển)：</div>
                    </div>
                    <div className=" flex flex-col gap-2 justify-end items-end">
                        <div className="flex-1 text-sm font-medium  text-textPrimary">
                            {numeral(totalPrice)
                                .format("0,0")
                                .replace(/,/g, ".")}đ
                        </div>
                        <div className="flex-1 text-sm font-medium text-textPrimary">
                            {numeral(0)
                                .format("0,0")
                                .replace(/,/g, ".")}đ
                        </div>
                        <div className="flex-1 text-sm font-medium text-textPrimary">  {numeral(totalSkuQuantity * 5000)
                            .format("0,0")
                            .replace(/,/g, ".")}đ</div>
                        <div className="flex-1 text-base  font-bold text-primary">  {numeral(totalSkuQuantity * 5000 + totalPrice)
                            .format("0,0")
                            .replace(/,/g, ".")}đ</div>
                    </div>


                </div>

            </div>

        </div>
    )
}
