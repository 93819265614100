import React, { useEffect } from "react"
import { ItemMerchant } from "./components/ItemMerchant"
import { IMerchant } from "@domain/Cart"
import { BottomInfo } from "./components/BottomInfo"
import { BottomBar } from "./components/BottomBar"
import AddressComponent from "@component/Address"
import SecurityService from "@util/SecurityService"
import localStore from "@util/LocalStore"
import appConfig from "@config/app"
import CardLayout from "@component/Layout/Card"

const CartConfirm = () => {
    const [merchants, setMerchants] = React.useState<IMerchant[]>([])
    useEffect(() => {
        if (localStorage.getItem("merchants")) {
            setMerchants(JSON.parse(localStorage.getItem("merchants") ?? ""))
        }
        const loggedUser = localStore.getJson("loginSession")
        if (!loggedUser) {
            localStore.setItem("urlRedirectLogin", "/cart-confirm")
            window.location.href = appConfig.loginUrl ?? ""
        }
    }, [])

    let totalSkuSelect = 0
    let totalPrice = 0
    let totalSkuQuantitySelect = 0
    let totalMerchantSelect = 0
    for (let index = 0; index < merchants.length; index++) {
        let isSelectMerchant = false
        const merchant = merchants[index]
        for (let index = 0; index < merchant.products.length; index++) {
            const product = merchant.products[index]
            for (let index = 0; index < product.skus.length; index++) {
                const sku = product.skus[index]
                if (sku.checked) {
                    isSelectMerchant = true
                    totalSkuSelect = totalSkuSelect + 1
                    totalPrice = totalPrice + sku.price * sku.quantity
                    totalSkuQuantitySelect = totalSkuQuantitySelect + sku.quantity
                }
            }
        }
        if (isSelectMerchant) {
            totalMerchantSelect = totalMerchantSelect + 1
        }
    }
    console.log("merchants", merchants)

    return (
        <CardLayout containerLogoStyle="bg-white">
            <div className="w-[1200px]  mx-auto  flex flex-col gap-4  flex-1 relative ">
                <div className=" flex mt-5 flex-row bg-[#F9F1DA] p-3 gap-2 rounded-lg">
                    <div
                        className="text-[#f5be23] text-left relative w-[16.31px] h-4 flex items-center justify-start"
                        style={{ font: "400 16px/16px 'Font Awesome 6 Pro', sans-serif" }}>
                        {" "}
                    </div>
                    <div className="text-xs text-textPrimary">
                        Nhắc nhở: Không giống như sàn bán lẻ, 1688 là sàn giao dịch dành cho người mua dịch vụ và người bán buôn. Hãy đảm bảo chú ý đến cam kết
                        dịch vụ mà người bán thể hiện trước khi đặt hàng và liên hệ trước với người bán. Ví dụ: không phải tất cả các sản phẩm đều hỗ trợ dịch
                        vụ hoàn trả không lý do và thông tin cụ thể do người bán hiển thị sẽ Chiếm ưu thế
                    </div>
                </div>
                {SecurityService.isLogged() && (
                    <div className=" bg-white">
                        <AddressComponent />
                    </div>
                )}

                {merchants.map((e) => {
                    return (
                        <ItemMerchant
                            merchant={e}
                            key={e.supplier}
                        />
                    )
                })}
                <BottomInfo
                    totalPrice={totalPrice}
                    totalSku={totalSkuSelect}
                    totalSkuQuantity={totalSkuQuantitySelect}
                />
                <div className="h-[100px]" />
            </div>
            <BottomBar totalPrice={totalPrice + totalSkuQuantitySelect * 5000} />
        </CardLayout>
    )
}
export default CartConfirm
