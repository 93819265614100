import { commonReducer } from "@reducer/common.reducer"
import { homeReducer } from "@reducer/home.reducer"
import { cartReducer } from "./cart.reducer"

const rootReducer = {
    common: commonReducer,
    home: homeReducer,
    cart: cartReducer,
}

export default rootReducer
