import ProductApi from "@api/Product"
import { HeaderTop } from "@component/HeaderTop/HeaderTop"
import ImageSycningSlider from "@component/ImageSyncingSlider/ImageSycningSlider"
import { IProduct } from "@domain/Product"
import { Footer } from "@screen/Home/components/Footer"
import { Col, Divider, Row, Skeleton, Tabs } from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import MerchantInfo from "./components/MerchantInfo"
import ProductInfo from "./components/ProductInfo"
import FakeScreen from '../../resources/images/imgs/div.active-tab-content.png'

import logo from "src/resources/images/flag/logo.png"

function ProductDetail() {
    const [product, setProduct] = useState<IProduct>()
    const [isFetching, setIsFetching] = useState(false)
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            setIsFetching(true)
            const fetchProductDetail = async () => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const response = await ProductApi.getProductDetail(id!)
                // console.log(response.data)
                const product: any = response.data
                setProduct(product)
                setIsFetching(false)
            }
            fetchProductDetail()
        }
    }, [id])

    return (
        <React.Fragment>
            <div className="product-detail-page">
                <HeaderTop />
                <div className="product-detail-page_header flex w-full justify-center">
                    <div className="mx-[24px] my-[24px] flex items-center w-full justify-center">
                        <a
                            className="text-[#F9471B] relative text-right mr-2"
                            style={{ font: "900 40px 'Roboto', sans-serif" }}
                            href="/">
                            <img
                                alt=""
                                src={logo}
                                className=" w-[180px] h-[50px]"
                            />
                        </a>
                        <span className="">
                            <Skeleton
                                active
                                loading={isFetching}>
                                <MerchantInfo merchant={product?.merchant} />
                            </Skeleton>
                        </span>
                    </div>
                </div>
                <Divider />
                <div className="w-full justify-center flex">
                    <div className="product-detail-page_body max-w-[1280px] mx-5 px-[32px]">
                        <Skeleton
                            active
                            loading={isFetching}>
                            <Row>
                                <Col
                                    xs={24}
                                    lg={10}>
                                    <div className="product-images">
                                        <ImageSycningSlider
                                            images={[product?.featuredImage].concat(product?.productImages)}
                                            className={"_images-preview"}
                                        />
                                    </div>
                                </Col>

                                <Col
                                    xs={24}
                                    lg={14}>
                                    <ProductInfo product={product} />
                                </Col>
                            </Row>
                        </Skeleton>
                    </div>
                </div>
            </div>
            {(product?.description || product?.detail) && (
                <div className="flex w-full bg-[#F7F7F7] p-[40px] product-description-tabs justify-center">
                    <Tabs
                        items={[
                            {
                                label: "Thông tin chi tiết",
                                key: "1",
                                children: (
                                    <div className="w-full bg-white flex flex-col justify-center">
                                        <div className="w-full bg-[#F7F7F7] h-[32px] flex justify-center"></div>
                                        <div className="px-[16px] py-[16px] flex flex-col items-center justify-center">
                                            <div
                                                className="w-full flex-col flex items-center"
                                            >
                                                <img src={FakeScreen}/>
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: product?.description || "" }} />
                                            <div className="mt-2"></div>
                                            <div dangerouslySetInnerHTML={{ __html: product?.detail || "" }} />
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            )}

            <div className="w-full">
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default ProductDetail
