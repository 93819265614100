import { Checkbox } from "antd"
import React from "react"
import numeral from 'numeral';
import classNames from "classnames";
export const BottomInfo = ({ totalPrice, totalMerchant, totalSkuSelect, totalMerchantSelect, onSubmit, totalSkuQuantitySelect, isCheckAll, onUpdateSelect }: {
    isCheckAll: boolean
    totalSkuSelect: number,
    totalMerchantSelect: number,
    totalMerchant: number,
    totalPrice: number,
    onSubmit: () => void,
    totalSkuQuantitySelect: number,
    onUpdateSelect: ({ merchantId, skuId, productId, isCheck, isAll }: {
        merchantId?: string | undefined;
        skuId?: string | undefined;
        productId?: string | undefined;
        isCheck: boolean;
        isAll?: boolean | undefined;
    }) => void
}) => {
    return (
        <div className="sticky bottom-0 w-[1200px] mx-auto z-[1000] bg-white shadow-2xl">
            <div className=" w-full flex flex-row items-center justify-between shadow-2xl bg-white p-5">

                <div className=" flex flex-row items-center">
                    <Checkbox indeterminate={totalSkuSelect > 0 && !isCheckAll} checked={isCheckAll} onClick={() => onUpdateSelect({ isAll: true, isCheck: !isCheckAll })} className=" text-xs text-textPrimary top-0   sticky bottom-0 ">Chọn tất cả</Checkbox>
                    <button className=" ml-6 hover:border-primary hover:text-primary border border-[#DDD] rounded-3xl  w-fit px-6 py-2 text-xs text-textPrimary">
                        Xoá bỏ
                    </button>
                    <button className=" ml-[10px] hover:border-primary hover:text-primary border border-[#DDD] rounded-3xl  w-fit px-6 py-2 text-xs text-textPrimary">
                        Thêm vào mục yêu thích
                    </button>
                </div>
                <div className=" flex flex-row items-center">
                    <div className=" text-sm text-secondary ml-[22px]">Shop: {totalMerchantSelect}/{totalMerchant}</div>
                    <div className=" text-sm text-secondary ml-[22px]">Phân Loại: <span className={classNames(totalSkuSelect > 0 && "text-primary")}>{totalSkuSelect}</span></div>
                    <div className=" text-sm text-secondary ml-[22px]">Sản phẩm: <span className={classNames(totalSkuSelect > 0 && "text-primary")} >{totalSkuQuantitySelect}</span></div>
                    <div className=" text-sm text-secondary ml-[22px]">Tổng:</div>
                    <span className=" text-primary text-2xl pb-[6px] ml-3 ">{numeral(totalPrice)
                        .format("0,0")
                        .replace(/,/g, ".")}
                        đ</span>
                    <button onClick={() => onSubmit()} className=" ml-8 bg-primary hover:bg-buttonPrimary rounded-3xl  w-fit px-6 py-2 text-base  font-normal text-white">Mua Hàng</button>
                </div>
            </div>

        </div>
    )
}
