import { MenuOutlined } from "@ant-design/icons"
import React, { useEffect } from "react"
import { ItemMenu } from "./ItemMenu"
import { useAppSelector } from "@hook/useAppSelector"

export const Menu = () => {
    const categorise = useAppSelector((state) => state.home.categories)
    useEffect(() => {
        if (categorise && categorise.length > 0) {
            for (let index = 0; index < categorise.length; index++) {
                const element = categorise[index]
                const img = new Image()
                img.src = element.iconHover ?? ""
                img.onload = () => {
                    console.log("Image loaded")
                    // Tiếp tục xử lý sau khi hình ảnh đã được tải
                }
            }
        }
    }, [categorise])

    return (
        <div
            id="menu"
            className=" bg-white py-4 px-4 w-[222px] h-[358px]  rounded-lg flex flex-col">
            <div className=" flex flex-row gap-3  items-center mb-4 ">
                <MenuOutlined
                    style={{ fontSize: "14px", color: "#1A1A1A" }}
                    rev={undefined}
                />
                <p className="text-base  font-medium my-auto text-textPrimary">Danh Mục</p>
            </div>
            <div className="text-sm text-textPrimary flex flex-col gap-[10px] flex-1  ">
                {[...categorise].splice(0, 10).map((e) => {
                    return (
                        <ItemMenu
                            key={e.code}
                            category={e}
                        />
                    )
                })}
            </div>
        </div>
    )
}
