import { Button } from "antd"
import React, { useEffect } from "react"

interface ActionButtonProps {
    showOtherPropertiesValue: any
    handleAddToCart: () => void
    addToCartLoading: boolean
    numberOfLastVariantToDisplay: number
}

function ActionButton(props: ActionButtonProps) {
    const { showOtherPropertiesValue, handleAddToCart, addToCartLoading } = props

    useEffect(() => {
        const parentContainer: any = document.querySelector(".product-info")
        const childContainer: any = document.querySelector("._pin-bar")

        // console.log(parentContainer,childContainer)

        window.addEventListener("scroll", () => {
            const parentRect: any = parentContainer?.getBoundingClientRect()
            const childRect: any = childContainer?.getBoundingClientRect()

            const scrollY = window.scrollY
            const maxY = parentRect.height - childRect.height

            // console.log(scrollY,maxY)

            let newTop = scrollY
            if (newTop < 0) newTop = 0
            else if (newTop > maxY) newTop = maxY
            else newTop = 0

            if (newTop <= 0 + 150 || newTop >= (maxY - 200) / 2) {
                // childContainer.style.boxShadow = "none"
            } else {
                // childContainer.style.boxShadow = "0px -11px 25px 0px rgba(17, 17, 17, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.05)"
            }
            childContainer.style.top = newTop + "px"
        })
    }, [])

    return (
        <React.Fragment>
            <div className={`_pin-bar`}>
                <div className="action-buttons flex justify-start gap-3 mt-2 mb-3 pl-4">
                    <Button
                        className="cursor-hand"
                        // disabled
                        type="primary"
                        size="large">
                        Mua ngay
                    </Button>
                    <Button
                        disabled={!showOtherPropertiesValue?.totalQuantity || showOtherPropertiesValue.totalQuantity <= 0}
                        type="default"
                        size="large"
                        onClick={handleAddToCart}
                        loading={addToCartLoading}>
                        Thêm giỏ hàng
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ActionButton
