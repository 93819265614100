import Search from "@api/Search"
import { ISearchImageQuery, ISearchQuery } from "@domain/Search"
import { useInfiniteQuery } from "react-query"

export const useSearchTextQuery = (params: ISearchQuery) => {

    return useInfiniteQuery({
        queryKey: ["search.getItemByText", params],
        queryFn: ({ pageParam }) => Search.searchText({ ...params, page: pageParam || "0" }),
        getNextPageParam: (lastPage) => {
            return parseInt(lastPage.headers["x-page-count"]) > parseInt(lastPage.config.params["page"]) ? parseInt(lastPage.config.params.page || "0") + 1 : false
        },

        // getPreviousPageParam: (firstPage, allPages) => firstPage.prevCursor,
    })
}
export const useSearchImageQuery = (params: ISearchImageQuery) => {
    return useInfiniteQuery({
        queryKey: ["search.getItemByImage", params],
        queryFn: ({ pageParam }) => Search.searchImage({ ...params, page: pageParam || "0" }),
        getNextPageParam: (lastPage) => {
            return parseInt(lastPage.headers["x-page-count"]) > parseInt(lastPage.config.params["page"]) ? parseInt(lastPage.config.params.page || "0") + 1 : false
        },

        // getPreviousPageParam: (firstPage, allPages) => firstPage.prevCursor,
    })
}

