import CategoriesApi from "@api/Categories"
import { IProductCriterias } from "@domain/Product"
import { UseQueryResult, useQuery } from "react-query"

export function useProducts(criterias?: IProductCriterias, queryConfig?: UseQueryResult) {
    return useQuery({
        queryKey: ["fetch-products-by-cate", criterias],
        queryFn: async ({ queryKey }) => {
            //@ts-ignore
            const response: AxiosResponse = await CategoriesApi.getProductList(queryKey[1])
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: 1,
        // enabled: !!criterias,
        ...queryConfig,
    }) as UseQueryResult
}