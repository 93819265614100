import service1 from "@resources/images/flag/service1.png"
import service2 from "@resources/images/flag/service2.png"
import service3 from "@resources/images/flag/service3.png"
import service4 from "@resources/images/flag/service4.png"
import service5 from "@resources/images/flag/service5.png"
import React from "react"

const data = [
    {
        label: "Hướng dẫn cho người mới",
        code: "1",
        description: "Hiểu về 1688VN, Kim chỉ nam cho Người dùng mới",
    },
    {
        label: " Dịch vụ Người bán",
        code: "2",
        description: "Kim chỉ nam cho Người bán, Chứng nhận Cửa hàng",
    },
    {
        label: "Phương thức giao dịch",
        code: "3",
        description: "Giao dịch đảm bảo, Giao dịch định kỳ",
    },
    {
        label: "Phương thức thanh toán",
        code: "4",
        description: " Ví điện tử, Thẻ tín dụng",
    },
    {
        label: "CSKH 1688",
        code: "5",
        description: "Chăm sóc hậu mãi, Dịch vụ hậu cần",
    },
]
export const Services = () => {
    const getImageService = (code: string) => {
        switch (code) {
            case "1":
                return service1
            case "2":
                return service2
            case "3":
                return service3
            case "4":
                return service4
            case "5":
                return service5
        }
    }
    return (
        <div className=" flex flex-row justify-between w-[1200px] mx-auto bg-white rounded py-[30px] mb-6 px-[40px] gap-14">
            {data.map((e) => {
                return (
                    <div
                        className=" flex flex-col flex-1"
                        key={e.code}>
                        <img
                            className=" w-12 h-12 mb-4"
                            alt=""
                            src={getImageService(e.code)}
                        />
                        <div className=" text-textPrimary text-sm font-normal">{e.label}</div>
                        <div className=" text-secondary2 text-xs font-normal mt-2">{e.description}</div>
                    </div>
                )
            })}
        </div>
    )
}
