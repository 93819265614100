import { notification } from "antd"

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import lodash from "lodash"

import appConfig from "@config/app"
import localStore from "@util/LocalStore";



class ApiClient {
  config: AxiosRequestConfig

  instance: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.config = config
    this.instance = axios.create()
    this.initialize()
  }

  get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.get<T>(url, config)
  }

  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.put<T>(url, data, config)
  }

  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(url, data, config)
  }

  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.patch<T>(url, data, config)
  }

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.delete<T>(url, config)
  }

  initialize = () => {
    const instance = axios.create()
    console.log("add header")


    instance.defaults.baseURL = this.config.baseURL
    const loginSession = localStore.getJson("loginSession")
    if (loginSession) {
      instance.defaults.headers.common["Authorization"] = "Bearer " + loginSession.accessToken
    }
    instance.defaults.timeout = 30000
    instance.defaults.headers.common["X-tenant"] = appConfig.tenant ?? ""

    instance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (lodash.get(error, "response.status") === 401) {
          localStore.removeItem("loginSession");
          localStore.removeItem("loggedUser");
          localStore.removeItem("expiresAt");

          // notification.error({
          //   message: "Phiên làm việc hết hạn. Vui lòng làm mới trình duyệt",
          //   key: "TOKEN_EXPIRED",
          // })

          window.location.href = appConfig.loginUrl ?? ""
        } else if (lodash.get(error, "response.status") >= 500) {
          notification.error({
            message: "Máy chủ gặp sự cố. Vui lòng thử lại sau",
            key: "server_error",
          })
        }

        return Promise.reject(error)
      }
    )

    this.instance = instance
  }
}

const apiClient = new ApiClient({
  baseURL: appConfig.apiUrl,
})

export default apiClient
