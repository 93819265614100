import { Tooltip, Upload, UploadFile, UploadProps, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useTransition, animated } from '@react-spring/web'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CommonApi from '@api/Common';
const { Dragger } = Upload;
export const Search = () => {
    const [isInput, setIsInput] = React.useState(false);
    const [images, setFileList] = useState<UploadFile[]>([]);
    const [fileLength, setFileLength] = useState(0);
    // const [uploading, setUploading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [value, setValueSearch] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const key = 'updatable';
    useEffect(() => {
        if (location.pathname === '/search/image') {
            setIsInput(false)
        } else {
            if (location.pathname === '/search' && searchParams.get("keyword")) {
                setIsInput(true)
                setValueSearch(searchParams.get("keyword") || "")
            }
        }
    }, [location.pathname, searchParams])

    useEffect(() => {
        if (images.length > 0 && images.length === fileLength && fileLength <= 6) {
            const formData = new FormData();
            if (Array.isArray(images)) {
                for (const file of images) {
                    //@ts-ignore
                    formData.append('files', new Blob([file]), file.name)
                }
            }
            messageApi.open({
                key,
                type: 'loading',
                content: 'Đang tải ảnh...',
                duration: 0
            });
            CommonApi.uploadImage(formData).then(res => {
                if (location.pathname === '/search/image') {
                    setFileLength(0)
                    setFileList([])
                    setSearchParams({ imageUrls: res.data.map(d => d.imageUrl).toString() })
                    // window.location.reload();
                } else {
                    setTimeout(() => {
                        navigate("/search/image?imageUrls=" + res.data.map(d => d.imageUrl).toString())
                    }, 500)
                }

            }).catch(err => {
                console.log("err", err);
                message.error("Có lỗi xảy ra")
            }).finally(() => {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Thành công!',
                    duration: 1,
                });
            })
        }
        if (fileLength > 6) {
            message.error("Chỉ được tải lên tối đa 6 ảnh")
            setFileList([])
            setFileLength(0)
        }
    }, [images, fileLength, setSearchParams, location.pathname, navigate, messageApi])

    const transitions = useTransition(isInput, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        delay: 200
        // leave: { opacity: 0 }
    });
    // const [show] = useSpring(() => ({
    //     from: { opacity: 0 },
    //     to: { opacity: 1 },
    //     delay: 100,
    // }))
    const hide = useTransition(!isInput, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0.5 },
        delay: 300
    });
    const props: UploadProps = {
        name: 'files',
        maxCount: 6,
        multiple: true,
        action: undefined,
        fileList: images,
        beforeUpload: (_, fileList) => {
            let check = true;
            fileList.forEach((file: any) => {
                if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif' && file.type !== 'image/jpg') {
                    message.error(`${file.name} không phải là file ảnh`);
                    check = false;
                }
            });
            if (!check) return false;
            setFileLength(fileList.length);
            setFileList(fileList);
            console.log("vào đây");
            return false;
        },
        showUploadList: false,
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const propsUpload: UploadProps = {
        className: 'border-0 bg-transparent w-[668px]',
        name: 'files',
        maxCount: 6,
        multiple: true,
        action: undefined,
        fileList: images,
        beforeUpload: (_, fileList) => {
            let check = true;
            fileList.forEach((file: any) => {
                if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif' && file.type !== 'image/jpg') {
                    message.error(`${file.name} không phải là file ảnh`);
                    check = false;
                }
            });
            if (!check) return false;
            setFileLength(fileList.length);
            setFileList(fileList);
            console.log("vào đây");
            return false;
        },
        showUploadList: false,
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const onPasteCapture = (event: any) => {
        event.preventDefault();
        const items = event.clipboardData.files
        if (items.length > 0 && items.length <= 6) {
            setValueSearch("")
            const formData = new FormData();
            console.log("items", items, typeof items);
            Object.values(items).forEach((item: any) => {
                console.log("item", item);
                formData.append('files', new Blob([item]), item.name)
            })

            messageApi.open({
                key,
                type: 'loading',
                content: 'Loading...',
                duration: 0
            });

            CommonApi.uploadImage(formData).then(res => {
                if (location.pathname === '/search/image') {
                    setSearchParams({ imageUrls: res.data.map(d => d.imageUrl).toString() })
                    // setTimeout(() => {
                    window.location.reload();
                    // }, 500);
                } else {
                    setTimeout(() => {
                        navigate("/search/image?imageUrls=" + res.data.map(d => d.imageUrl).toString())
                    }, 500)
                }

            })
                .catch(err => {
                    console.log("err", err);
                    message.error("Có lỗi xảy ra")
                })
                .finally(() => {
                    messageApi.open({
                        key,
                        type: 'success',
                        content: 'Loaded!',
                        duration: 1,
                    });
                })
        } else if (items.length > 6) {
            message.error("Chỉ được tải lên tối đa 6 ảnh")

        }

    }

    const handleKeyDown = (event: { key: string; stopPropagation: () => void }) => {
        event.stopPropagation()
        if (event.key === 'Enter') {
            // 👇 Get input value
            if (!value || value == "") return
            if (location.pathname === '/search') {
                if (!searchParams.get("imageUrls")) {
                    setSearchParams({ keyword: value })
                }
            } else {
                navigate("/search?keyword=" + value)
                // setTimeout(() => {
                //     window.open(window.location.origin + "/search?keyword=" + value, '_blank', 'noopener,noreferrer');
                // }, 500)
            }

        }
    };
    const onPaste = (event: any) => {
        event.preventDefault();
        const text = event.clipboardData.getData('Text')
        setValueSearch(text)
    }
    const onSearch = () => {
        if (!value || value === "") {
            setIsInput(true)
            return
        }
        if (location.pathname === '/search') {
            if (!searchParams.get("imageUrls")) {
                setSearchParams({ keyword: value })
            }
        } else {
            navigate("/search?keyword=" + value)
            // setTimeout(() => {
            //     window.open(window.location.origin + "/search?keyword=" + value, '_blank', 'noopener,noreferrer');
            // }, 500)
        }
    }

    return (
        <>
            {contextHolder}
            <div className="flex flex-row items-center justify-between shrink-0 w-[800px] relative h-full">
                <Dragger {...propsUpload} >
                    <div className="bg-[#ffffff] rounded-tl-lg rounded-bl-lg border-solid border-[#F9471B] border pr-6 pl-6 flex flex-row items-center flex-1 h-[46px] relative"
                        onClick={e => {
                            if (!isInput) return
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                        {isInput ? transitions((style) => <animated.div
                            style={style}
                            onClick={e => e.stopPropagation()}
                            className="flex flex-row gap-1.5 items-center justify-start shrink-0 relative grow">

                            <div
                                className="text-[#F9471B] text-left relative flex items-center justify-start "
                                style={{ font: "300 16px 'Font Awesome 6 Pro', sans-serif" }}
                            >
                                
                            </div>
                            <div
                                className="text-text-color-text-primary text-left relative flex items-center justify-start grow"
                                style={{ font: "400 16px 'Roboto', sans-serif" }}

                            >
                                <input autoFocus
                                    onPaste={onPaste}
                                    value={value}
                                    onPasteCapture={onPasteCapture}
                                    onChange={(e) => setValueSearch(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    onFocus={e => e.stopPropagation()}
                                    className='focus:outline-none focus:border-0 text-text-color-text-primary focus:border-transparent pr-2 grow h-[44px]' placeholder='Nhập từ khóa' />
                            </div>
                            {/* <Input placeholder="Tìm kiếm bằng hình ảnh" /> */}
                        </animated.div>) :
                            <Tooltip overlayInnerStyle={{
                                color: "#7d7e7e", font: "400 12px/150% 'Roboto', sans-serif", borderRadius: 8,
                                paddingLeft: 16, paddingRight: 16, paddingTop: 12, paddingBottom: 12,
                                lineHeight: "150%"
                            }}
                                className='flex flex-row gap-1.5 items-center justify-start shrink-0 relative grow h-[46px]'
                                color="white" title="Kéo ảnh vào đây hoặc tải ảnh lên" placement="bottomLeft">
                                <div className="flex flex-row gap-1.5 items-center justify-start shrink-0 relative grow h-[46px] w-full">
                                    {/* <Dragger {...propsUpload}> */}

                                    <div className='flex flex-row gap-1.5 items-center justify-start shrink-0 relative '>
                                        <div
                                            className="text-[#F9471B] text-left relative flex items-center justify-start"
                                            style={{ font: "300 20px 'Font Awesome 6 Pro', sans-serif" }}
                                        >
                                            
                                        </div>
                                        <div
                                            className="text-[#F9471B] text-left relative flex items-center justify-start"
                                            style={{ font: "400 16px 'Roboto', sans-serif" }}
                                        >
                                            Tìm bằng ảnh
                                        </div>
                                    </div>


                                    {/* </Dragger> */}
                                </div> </Tooltip>}
                        <div className={"flex flex-row gap-1.5 items-center justify-end shrink-0 relative text-[#B1B1B1] hover:text-primary " + (isInput ? "" : "grow")}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                if (isInput) return
                                setIsInput(true)
                            }}
                        >
                            {isInput ?
                                <div onClick={(e) => e.stopPropagation()}>
                                    <Upload {...props}>
                                        {transitions(() => <animated.div
                                            // style={style}
                                            className='flex flex-row gap-1.5 items-center justify-start shrink-0 relative'
                                        >
                                            <Tooltip overlayInnerStyle={{
                                                color: "#7d7e7e", font: "400 12px/150% 'Roboto', sans-serif", borderRadius: 8,
                                                paddingLeft: 16, paddingRight: 16, paddingTop: 12, paddingBottom: 12,
                                                lineHeight: "150%"
                                            }}
                                                color="white" title="Kéo ảnh vào đây hoặc tải ảnh lên" placement="bottomLeft">
                                                <div
                                                    className="text-[#F9471B] text-left relative flex items-center justify-start"
                                                    style={{ font: "300 20px 'Font Awesome 6 Pro', sans-serif" }}
                                                >
                                                    
                                                </div>
                                            </Tooltip>
                                            {/* <div
                                            className="text-[#F9471B] text-left relative flex items-center justify-start"
                                            style={{ font: "400 16px 'Roboto', sans-serif" }}
                                        >
                                            Tìm kiếm bằng hình ảnh
                                        </div> */}
                                        </animated.div>)}
                                    </Upload>
                                </div>
                                :
                                hide((style) =>
                                    <animated.div
                                        style={style}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setIsInput(true)
                                        }}
                                        className='z-50 flex flex-row gap-1.5 items-center justify-start shrink-0 relative  h-[44px] grow'>
                                        <Tooltip overlayInnerStyle={{
                                            color: "#7d7e7e", font: "400 12px/150% 'Roboto', sans-serif", borderRadius: 8,
                                            paddingLeft: 16, paddingRight: 16, paddingTop: 12, paddingBottom: 12,
                                            lineHeight: "150%"
                                        }}
                                            color="white" title="Gõ gì đó hoặc dán ảnh vào đây" placement="bottomRight">

                                            <div className='flex flex-row gap-1.5 items-center justify-end shrink-0 relative  h-[44px] grow'>
                                                <div
                                                    className="text-left relative flex items-center justify-start"
                                                    style={{ font: "300 16px 'Font Awesome 6 Pro', sans-serif" }}
                                                >
                                                    
                                                </div>

                                                <div

                                                    className=" text-left relative flex items-center justify-start"
                                                    style={{ font: "400 16px 'Roboto'" }}
                                                >
                                                    Tìm kiếm bằng từ khoá
                                                </div>
                                            </div>
                                        </Tooltip>

                                    </animated.div>
                                )}
                        </div>
                    </div>
                </Dragger>
                <div
                    onClick={onSearch}
                    className="rounded-tr-md rounded-br-md flex flex-row gap-0 items-center justify-center shrink-0 w-[132px] h-[46px] relative cursor-pointer"
                    style={{
                        background:
                            "linear-gradient(90deg, rgba(255, 126, 62, 1.00) 0%,rgba(255, 64, 0, 1.00) 100%)",
                    }}
                >
                    <div
                        className="text-[#ffffff] text-center relative flex items-center justify-center"
                        style={{ font: "500 17.75px/21.56px 'Roboto', sans-serif" }}
                    >
                        Tìm kiếm
                    </div>
                </div>
            </div>
        </>
    )
}
