import React, { useEffect, useRef } from "react"

interface CheckingComponentInViewPortWrapperProps {
    inViewCallback?: ()=> void;
    outViewCallback?:() => void;
    componentTitle?: any
    children?: any
}


function CheckingComponentInViewPortWrapper({ children, inViewCallback, outViewCallback, componentTitle }: CheckingComponentInViewPortWrapperProps) {
    const elementRef = useRef(null)

    // Hàm callback được gọi khi component hiện ra hoặc ra khỏi viewport
    const handleIntersection = (entries: any) => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                console.log(`Component ${componentTitle} is in the viewport`)
                inViewCallback && inViewCallback()
                // Thực hiện logic của bạn ở đây khi component hiện ra
            } else {
                // Component ra khỏi viewport
                console.log(`Component ${componentTitle} is out of the viewport`)
                outViewCallback && outViewCallback()
                // Thực hiện logic của bạn ở đây khi component ra khỏi viewport
            }
        })
    }

    useEffect(() => {
        const options = {
            root: null, // Sử dụng viewport làm container
            rootMargin: "0px", // Kích thước khoảng cách từ viewport
            threshold: 0.5, // Ngưỡng: 0-1, 0.5 có nghĩa component hiện ra ít nhất 50% trong viewport
        }

        // Tạo một IntersectionObserver với hàm callback
        const observer = new IntersectionObserver(handleIntersection, options)

        // Theo dõi phần tử DOM của component bằng cách sử dụng ref
        if (elementRef.current) {
            observer.observe(elementRef.current)
        }

        // Ngừng theo dõi khi component unmount
        return () => {
            observer.disconnect()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div ref={elementRef}>{children}</div>
}

export default CheckingComponentInViewPortWrapper;