import CardLayout from "@component/Layout/Card"
import React, { useEffect } from "react"
import { TopInfo } from "./components/TopInfo"
import { ItemMerchant } from "./components/ItemMerchant"
import { IMerchant } from "@domain/Cart"
import { BottomInfo } from "./components/BottomInfo"

import { useAppSelector } from "@hook/useAppSelector"
import { useNavigate } from "react-router-dom"
import localStore from "@util/LocalStore"
import appConfig from "@config/app"
import { Skeleton } from "antd"
const Cart = () => {

    const [merchants, setMerchants] = React.useState<IMerchant[]>([])
    const cart = useAppSelector(state => state.cart)
    const loading = cart.loading
    const navigate = useNavigate()

    useEffect(() => {
        const loggedUser = localStore.getJson("loginSession");
        if (!loggedUser) {
            window.location.href = appConfig.loginUrl ?? "";
            localStore.setItem("urlRedirectLogin", "/cart")
        }
        setMerchants(JSON.parse(JSON.stringify(cart.merchants)))

    }, [cart.merchants])




    const onUpdateSelect = ({ merchantId, skuId, productId, isCheck, isAll }: { merchantId?: string, skuId?: string, productId?: string, isCheck: boolean, isAll?: boolean }) => {
        const newMerchants = [...merchants]
        for (let index = 0; index < newMerchants.length; index++) {
            const merchant = newMerchants[index];
            for (let index = 0; index < merchant.products.length; index++) {
                const product = merchant.products[index];
                for (let index = 0; index < product.skus.length; index++) {
                    if (isAll) {
                        product.skus[index].checked = isCheck

                    } else if (merchantId && merchantId === merchant.id) {
                        product.skus[index].checked = isCheck

                    } else if (productId && productId === product.id) {
                        product.skus[index].checked = isCheck

                    } else if (skuId === product.skus[index].id) {
                        product.skus[index].checked = isCheck
                    }
                }
            }
        }
        setMerchants(newMerchants)
    }
    let totalSkuSelect = 0
    let totalPrice = 0
    let totalSkuQuantitySelect = 0
    let totalMerchantSelect = 0
    for (let index = 0; index < merchants.length; index++) {
        let isSelectMerchant = false
        const merchant = merchants[index];
        for (let index = 0; index < merchant.products.length; index++) {
            const product = merchant.products[index];
            for (let index = 0; index < product.skus.length; index++) {
                const sku = product.skus[index]
                if (sku.checked) {
                    isSelectMerchant = true
                    totalSkuSelect = totalSkuSelect + 1
                    totalPrice = totalPrice + (sku.price * sku.quantity)
                    totalSkuQuantitySelect = totalSkuQuantitySelect + (sku.quantity)
                }
            }
        }
        if (isSelectMerchant) {
            totalMerchantSelect = totalMerchantSelect + 1
        }
    }
    const onNextPage = () => {
        if (totalSkuSelect > 0) {
            let removeMerchant = merchants.filter(p => p.products.filter(e => e.skus.filter(s => s.checked).length > 0).length > 0)
            removeMerchant = removeMerchant.map(m => {
                return {
                    ...m,
                    products: m.products.filter(p => p.skus.filter(s => s.checked).length > 0)
                }
            })
            removeMerchant = removeMerchant.map(m => {
                return {
                    ...m,
                    products: m.products.map(p => {
                        return { ...p, skus: p.skus.filter(s => s.checked) }
                    })
                }
            })
            localStorage.setItem("merchants", JSON.stringify(removeMerchant))
            navigate("/cart-confirm");

        }

    }


    return (
        <CardLayout>
            <Skeleton className="w-[1200px]  mx-auto" paragraph={{ rows: 4 }} round loading={cart.loading}>

                <TopInfo totalSkuSelect={totalSkuSelect} isCheckAll={totalSkuSelect === merchants[0]?.totalSku} onUpdateSelect={onUpdateSelect} totalSku={merchants?.[0]?.totalSku ?? 0} />
                <div className="w-[1200px]  mx-auto  flex flex-col gap-4 mt-4 flex-1 relative">

                    {merchants.length === 0 && !loading && (
                        <div className=" flex flex-col  gap-8 m-auto ">
                            <div
                                className="text-text-color-text-disable text-left relative flex items-center justify-start"
                                style={{ font: "100 80px 'Font Awesome 6 Pro', sans-serif" }}>
                                {" "}
                            </div>
                            <div className=" text-xs text-secondary ml-[9px]">Giỏ hàng trống</div>
                        </div>
                    )}

                    {merchants.map((e) => {
                        return (
                            <ItemMerchant
                                onUpdateSelect={onUpdateSelect}
                                merchant={e}
                                key={e.supplier}
                            />
                        )
                    })}
                    <div className=" h-[150px] " />

                </div>
                <BottomInfo onSubmit={onNextPage} totalPrice={totalPrice} onUpdateSelect={onUpdateSelect} isCheckAll={totalSkuSelect === merchants[0]?.totalSku} totalMerchant={merchants.length} totalSkuSelect={totalSkuSelect} totalSkuQuantitySelect={totalSkuQuantitySelect} totalMerchantSelect={totalMerchantSelect} />
            </Skeleton>


        </CardLayout>
    )
}
export default Cart
