import React, { useEffect, useRef } from 'react'

export const InfinityScroll = ({ fetchData, loading, hasMode = true, }: { fetchData: () => void, loading: boolean, hasMode: boolean }) => {
    const observerTarget = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    if (!loading) fetchData();
                }
            },
            {
                threshold: 1,
            }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [loading, fetchData]);
    return hasMode ? (
        <div>
            {loading && <p>Loading...</p>}
            <div ref={observerTarget}></div>
        </div>
    ) : <div></div>;
}