import React, { useMemo } from 'react'
import { Filter } from './components/Filter';
import { ItemSearch } from './components/Item';
import { InfinityScroll } from '@component/InfiniteScroll';
import DefaultLayout from '@component/Layout/Default';
import { useSearchParams } from 'react-router-dom';
import { useSearchTextQuery } from '@hook/query/search.hook.query';
import { ISearchItem } from '@domain/Search';
// import { randomIntFromInterval } from '@util/Common';

export const SearchPage = () => {
    const [searchParams] = useSearchParams()
    const searchQuery = useMemo(
        () => ({
            ...Object.fromEntries(searchParams.entries()),
            // type: randomIntFromInterval(1, 4).toString(),
            sort: "createdAt:desc",
        }),
        [searchParams]
    )
    const { data, isFetching, isLoading, fetchNextPage, hasNextPage = true } = useSearchTextQuery(searchQuery)
    const products = data?.pages.reduce((acc, page) => [...acc, ...page.data], [] as any[]) || []

    return <DefaultLayout loading={false}>
        {products.length > 0 ? <div className='bg-[#F4F4F4] flex justify-center pb-10'>
            <div className='2xl:w-[1390px] xl:w-[1190px] lg:w-[990px] md:w-[720px] w-[480px] '>
                <div className='h-4'></div>
                <Filter />
                <div className='h-4'></div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5'>
                    {products?.map((item: ISearchItem, index: number) => {
                        return <ItemSearch data={item} key={index} />
                    })}
                </div>
                <InfinityScroll loading={isFetching} fetchData={fetchNextPage} hasMode={hasNextPage} />

            </div>
        </div> : !isFetching && !isLoading && <div className='bg-[#F4F4F4] flex justify-center h-full'>
            <div className="flex flex-row gap-10 items-center justify-start relative">
                <div
                    className="text-text-color-text-disable text-left relative flex items-center justify-start"
                    style={{ font: "100 80px 'Font Awesome 6 Pro', sans-serif" }}
                >
                    {" "}
                </div>
                <div className="flex flex-col gap-3 items-start justify-start shrink-0 relative">
                    <div
                        className="text-text-color-text-secondary text-right relative flex items-center justify-end"
                        style={{ font: "400 16px/15.43px 'Roboto', sans-serif" }}
                    >
                        Không tìm thấy sản phẩm liên quan{" "}
                    </div>
                    <div
                        className="text-text-color-text-primary text-left relative flex items-center justify-start"
                        style={{ font: "500 12px/22px 'Roboto', sans-serif" }}
                    >
                        Bạn có thể:{" "}
                    </div>
                    <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/22px 'Roboto', sans-serif" }}
                        >
                            1. Rút ngắn hoặc sửa đổi cụm từ tìm kiếm của bạn và tìm kiếm lại{" "}
                        </div>
                        <div
                            className="text-text-color-text-secondary-2 text-left relative flex items-center justify-start"
                            style={{ font: "400 12px/22px 'Roboto', sans-serif" }}
                        >
                            2. Viết ra các yêu cầu mua sắm của bạn và nhanh chóng nhận được báo
                            giá từ nhiều nhà cung cấp
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }

    </DefaultLayout>
} 