import { IProduct } from "@domain/Product"
import { numberFormatter } from "@util/Common"
import { Rate } from "antd"
import React from "react"
import VariantsBlock from "./VariantsBlock"


interface ProductInfoProps {
    product: IProduct | undefined
}

function ProductInfo(props: ProductInfoProps) {
    const { product } = props


    
    return (
        <React.Fragment>
            <div className="product-info">
                <div className="flex break-words text-[18px] font-bold text-left"> 
                        {/* <img
                            className="mr-1"
                            style={{ width: 48, height: 16 }}
                            src={Icon1688}
                        />
                     */}
                    {product?.name}
                </div>
                <div className="flex items-center">
                    <span>
                        <Rate
                            value={product?.invoicePrice}
                            disabled
                            className="rate-preview"
                            allowHalf
                        />
                    </span>
                    <span className="flex gap-2 text-[#B1B1B1]">
                        <span className="text-[#FF7044] font-semibold">+{product?.totalRates}</span>
                        <span>Đánh giá </span>
                    </span>
                    <span className="ml-2 text-[#B1B1B1]">
                        <span className="ml-1 mr-2">|</span>
                        Bán hơn <span className="text-[#FF7044] font-semibold">{product?.totalSold}+</span> sản phẩm trong 90 ngày
                    </span>
                </div>
                <div className="flex flex-col gap-5">
                    <div className="prices text-[#B1B1B1] px-4 py-2 flex flex-col gap-2 rounded-md bg-[#F9F9FA]">
                        <div className="grid grid-cols-4 items-center">
                            <span className="col-span-1">Giá </span>
                            {product?.pricePolicies &&
                                product.pricePolicies.map((ele) => {
                                    return (
                                        <span
                                            className="col-span-1 text-xl text-[#F9471B]"
                                            key={ele.id}>
                                            <span>
                                                {ele.salePrice > 0 ? numberFormatter(ele.salePrice).replace(/,/g, ".") : ele.salePrice.replace(/./g, ",")}đ
                                            </span>
                                        </span>
                                    )
                                })}
                        </div>
                        <div className="grid grid-cols-4 items-center">
                            <span className="col-span-1">Số lượng tối thiểu </span>
                            {product?.pricePolicies &&
                                product.pricePolicies.map((ele, index, array) => {
                                    return (
                                        <span
                                            className="col-span-1 text-sm text-black"
                                            key={ele.id}>
                                            {!ele.maxQuantity && index < array.length - 1 && (
                                                <span>Tối thiểu {numberFormatter(ele.minQuantity).replace(/,/g, ".")}</span>
                                            )}
                                            {ele.maxQuantity && ele.minQuantity && index < array.length - 1 && (
                                                <span className="flex items-center">
                                                    <span>Từ {ele.minQuantity}</span>
                                                    <span className="mx-1">-</span>
                                                    <span>
                                                        {ele.maxQuantity > 999999 ? (
                                                            <i className="fa-regular fa-infinity"></i>
                                                        ) : (
                                                            numberFormatter(ele.maxQuantity).replace(/,/g, ".")
                                                        )}{" "}
                                                        
                                                    </span>
                                                </span>
                                            )}
                                            {index === array.length - 1 && ele.minQuantity && (
                                                <span>
                                                    <i className="fa-regular fa-greater-than-equal mr-1"></i>
                                                    {numberFormatter(ele.minQuantity).replace(/,/g, ".")} 
                                                </span>
                                            )}
                                        </span>
                                    )
                                })}
                        </div>
                    </div>
                    <div className="services px-4">
                        <div className="grid grid-cols-9 gap-3 items-start">
                            <span className="col-span-1 text-[#B1B1B1]">Dịch vụ </span>
                            <span className="grid grid-cols-6 col-span-8 gap-1">
                                <span className="col-span-2 text-sm text-[#F9471B]">&bull; Hoàn tiền nhanh chóng</span>
                                <span className="col-span-2 text-sm text-black">&bull; Đổi trả trong 7 ngày </span>
                                <span className="col-span-2 text-sm text-black">&bull; Đảm bảo vận chuyển </span>
                            </span>
                        </div>
                    </div>
                    <VariantsBlock product={product} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProductInfo
